import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-geosearch/assets/css/leaflet.css';
import React, { createRef, useEffect, useLayoutEffect, useRef } from 'react';
import { GeoJSON, MapContainer, TileLayer } from 'react-leaflet';
import { useMap } from 'react-leaflet/hooks';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import 'wicket';
import Wkt from 'wicket';


import './CSSCustom/MapComponent.css';

const mapRef = createRef(null);

function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

function fitFieldBounds(map, fieldname) {
    map.eachLayer(function(layer){
        if(layer.feature){
            let feature = layer.feature;
            if(feature.properties){
                if(feature.properties.name === fieldname){
                    map.fitBounds(layer.getBounds());
                    layer.openPopup();
                }
            }
        }
    });
}

/*function getFieldBounds(map, fieldname) {
    let bounds_found = undefined;
    map.eachLayer(function(layer){
        if(layer.feature){
            let feature = layer.feature;
            if(feature.properties){
                if(feature.properties.name == fieldname){
                    bounds_found = layer.getBounds();
                    return bounds_found;
                }
            }
        }
    });
    return bounds_found;
}*/

/*function getFieldLayer(map, fieldname){
    let layer_found = undefined;
    map.eachLayer(function(layer){
        if(layer.feature){
            let feature = layer.feature;
            if(feature.properties){
                if(feature.properties.name === fieldname){
                    layer_found = layer
                    return layer_found;
                }
            }
        }
    });
    return layer_found;
}*/

const MapData =  ({ onRowClickFieldName, setClickedField, fieldIdForDelete, fieldsData}) =>{
    const map = useMap()
    const [data, setData] = React.useState({ type: "FeatureCollection", features: [] });
    
    useLayoutEffect(() => {
        let features_array = [];
        let feature_col_all = [];
        fieldsData?.map((inner_data) => {
            let wkt = new Wkt.Wkt();
            if (inner_data['geom'].includes(';')) {
            inner_data['geom'] = inner_data['geom'].split(';')[1];
            }
            wkt.read(inner_data['geom']);
            let name = inner_data["name"];
            if (name === 'undefined') {
            name = inner_data["namegr"];
            }
            var feature = { "type": "Feature", 'properties': { "name": name, "id": inner_data['id'],"colour": getRandomColor()}, "geometry": wkt.toJson() };
            let layer = L.geoJSON(feature, {});
            features_array.push(layer);
            return null;
        });
        let boundsArray = []
        for(let layer of features_array){
            boundsArray.push(layer.getBounds());
        }
        /*let overallBounds = boundsArray.reduce((totalBounds, featureBounds) => totalBounds.extend(featureBounds), L.latLngBounds());
        try {
            map.fitBounds(overallBounds);
        }catch(ex){}*/
        if(boundsArray?.length){
            map.fitBounds(boundsArray[0]);
        }
        let features_all = [];
        for(let feature_col of features_array){
            features_all=features_all.concat(feature_col.toGeoJSON().features);
        }
        feature_col_all = {"type":"FeatureCollection","features":features_all};
        setData(feature_col_all);
    }, [fieldsData]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (data && map) {  
            fitFieldBounds(map, onRowClickFieldName);
        }
    }, [onRowClickFieldName]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (fieldIdForDelete && map) {
            // Remove the deleted field from the map
            setData(prevData => ({
                type: "FeatureCollection",
                features: prevData.features.filter(
                    (feature) => feature.properties.id !== fieldIdForDelete
                ),
            }));
        }
    }, [fieldIdForDelete, map]);

    if (data && map) {          
        return data.features.map((feature,index) => {
            const jsonStr = feature.properties.name;
            const contentWidth = jsonStr.length * 7;
            const contentHeight = jsonStr.split('<br>').length * 20;
            return (
                <GeoJSON 
                    data={feature} 
                    key={feature.properties.name} 
                    style={function (x) {
                        return { 
                            fillColor: feature.properties.colour, 
                            weight: 1, 
                            opacity: 1, 
                            color: 'black', 
                            fillOpacity: 0.6 
                        };
                    }}
                    onEachFeature={function (feature, layer) {
                        if (feature.properties && feature.properties.name) {
                            layer.bindPopup(
                                jsonStr,
                                {
                                maxWidth: contentWidth,
                                maxHeight: contentHeight
                                }
                            );
                            layer.on('click', function(e){
                                map.fitBounds(e.target.getBounds());
                                setClickedField(feature.properties.id);
                            });
                            L.marker(layer.getBounds().getCenter(), {
                                icon: L.divIcon({
                                    className: 'label',
                                    html: '<span style="width:100%;text-align:center;color:white;font-weight:bold;display:block;">'+feature.properties.name+'</span>',
                                    iconSize: [120, 40]
                                })
                            }).addTo(map);
                        }
                    }} 
                />);
        });
    } else {
        return null;
    }

}

function SearchBar() {
    const map = useMap(); // Access the map instance
    const searchControlRef = useRef(null); // Ref to store the search control instance
    const markerRef = useRef(null); // Ref to store the search result marker
  
    useEffect(() => {
      const provider = new OpenStreetMapProvider();
  
      // Function to clear the previous marker
      const clearPreviousMarker = () => {
        if (markerRef.current) {
          map.removeLayer(markerRef.current); // Remove the marker if it exists
          markerRef.current = null;
        }
      };
  
      // Set up the GeoSearchControl
      searchControlRef.current = new GeoSearchControl({
        provider: provider,
        showMarker: false, // Disable default markers
        showPopup: false,
        autoClose: true,
        retainZoomLevel: false,
        animateZoom: true,
        keepResult: false,
        updateMap: true,
      });
  
      // Add the search control to the map
      map.addControl(searchControlRef.current);
  
      // Listen for the result event to place a custom marker
      map.on('geosearch/showlocation', (result) => {
        clearPreviousMarker(); // Clear any existing marker
  
        // Define a custom DivIcon
        const customIcon = L.divIcon({
          className: 'custom-marker', // Class for styling
          html: `<div style="width: 20px; height: 20px; background-color: red; border-radius: 50%; border: 2px solid black;"></div>`,
          iconSize: [20, 20], // Set the size of your marker
        });
  
        // Add the custom marker to the map
        markerRef.current = L.marker([result.location.y, result.location.x], {
          icon: customIcon,
        }).addTo(map);
      });
  
      return () => {
        // Clean up on component unmount
        clearPreviousMarker(); // Remove any marker
        if (searchControlRef.current) {
          map.removeControl(searchControlRef.current); // Remove the search control
        }
      };
    }, [map]);
  
    return null;
}

function MapComponent({selectedFieldName, setClickedField, fieldIdForDelete, fieldsData}) {    
    return (
        <MapContainer
          center={[37.95, 23.79]} // Set your initial map center
          zoom={6} // Set your initial zoom level
          style={{ height: '100%'}}
          ref={mapRef}
        >
          <TileLayer
            attribution="Google Maps"
            url="https://www.google.cn/maps/vt?lyrs=s,h&gl=cn&x={x}&y={y}&z={z}"
          />
          <SearchBar />
            <MapData onRowClickFieldName={selectedFieldName} setClickedField={setClickedField} fieldIdForDelete={fieldIdForDelete} fieldsData={fieldsData}/>
        </MapContainer>
      );
}

export default MapComponent;