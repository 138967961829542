import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Buffer } from "buffer";
import $ from "jquery";
import React, { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import getServerURL from "./Configuration.js";
import './CSSCustom/LoginPage.css';
import logoImageWhite from './img/logowhite.png';
import { usePageTitle } from './JS_Context/ContextTitle';


import i18next from "i18next";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function LoginPage( {setUsername, username} ) {
  const [password, setPassword] = useState('');

  const navigate = useNavigate();
  const setCookie = useCookies(['csrftoken', 'auth', 'username'])[1];
  const [showPassword, setShowPassword] = useState(false);

  const serverUrl = getServerURL();
  const { t } = useTranslation();
  const { setPageTitle, setPageTitleIcon } = usePageTitle();

  useEffect(() => {
    if (localStorage.getItem("i18nextLng")?.length > 2) {
        i18next.changeLanguage("en");
    }
  }, []);

  useEffect(() =>{
    setPageTitle("page_login.title");
    setPageTitleIcon("");
  },[])

  const loginformRef = useRef();

  // hook to listen for key events
  useEffect(() => {
    const handleKeyPress = (event) => {
      // Check if the pressed key is "Enter" (key code 13)
      if (event.key === 'Enter') {
        // Trigger the click event on the submit button
        loginformRef.current.querySelector('.button_login').click();
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    // Remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
    }, []); // Empty dependency array means this effect runs once when the component mounts


  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleLogin = () => {
    $.ajax({
        url: serverUrl+"/osiris/api_csrf/",
        type: 'get',
        xhrFields: {
          withCredentials: true,
          xsrfCookieName: 'csrftoken',
          xsrfHeaderName: 'X-CSRFToken'
        },
        crossDomain: true,
        success: function (data, textStatus, request) {
          setCookie('csrftoken',request.getResponseHeader('X-Csrftoken'))
          let csrf = request.getResponseHeader('X-Csrftoken');
          $.ajax({
              url: serverUrl+"/osiris/api_login/",
              type: 'post',
              headers: {"X-CSRFToken": csrf},
              xhrFields: {
                withCredentials: true,
                xsrfCookieName: 'csrftoken',
                xsrfHeaderName: 'X-CSRFToken'
              },
              crossDomain: true,
              contentType: "application/json; charset=utf-8",
              dataType: "json",
              data: JSON.stringify({
                username: username,
                password: password
              }),
              success: function (data, textStatus, request) {
                  setCookie('csrftoken',request.getResponseHeader('X-Csrftoken'))
                  const auth = Buffer.from(`${username}:${password}`).toString("base64");
                  setCookie('auth',`Basic ${auth}`)
                  setCookie('username', username, { path: '/' });
                  navigate('/fields');
              },
              error: function (request, textStatus, errorThrown) {
                  console.error(errorThrown);
                  if (request.status === 403) {
                    // Forbidden error (status code 403)
                    toast.error(
                      t("page_login.error.credentials")
                    );
                  } else {
                    // Other errors
                    toast.error(`${t("common.error.general")}: ${errorThrown}`);
                  }
              }
          });
        },
        error: function (request, textStatus, errorThrown) {
            console.error(errorThrown);
            toast.error(t("common.error.connection"));
        }
    });
  };

  const handleSignUp = () => {
    navigate('/signup');
  }

  return (
    <div className="login-page">
      <div className="right-background"></div>
      <div className="login-form" ref={loginformRef}>
        <div className="logo adjust-logo-top">
         <img src={logoImageWhite} alt="LogoWhite" className="logo" />
        </div>
        <h1 className="login-title">{t("page_login.title")}</h1>
        <div className="form-group">
          <label>{t("page_login.form.username")}</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>{t("page_login.form.password")}</label>
          <span className="password-input-container-login">
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <FontAwesomeIcon
              icon={showPassword ? faEye : faEyeSlash}
              className="toggle-password-icon-login"
              onClick={() => togglePasswordVisibility()}
            />
          </span>
        </div>
          <button className='button_login' onClick={handleLogin}>{t("page_login.form.submit")}</button>
        <p className="forgot-password">{t("page_login.form.forgotten")}</p>
        <button className='button_login_register' onClick={handleSignUp}>{t("page_login.form.register")}</button>
      </div>
        {/* ToastContainer for displaying notifications */}
        <ToastContainer
          position="bottom-right"
          autoClose={7000} // Close the notification after 7 seconds
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
    </div>
  );
}

export default LoginPage;
