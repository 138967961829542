import React, { useState, useEffect, useLayoutEffect} from 'react';
import MapCreateComponent from './MapCreateComponent';
import { useCookies } from 'react-cookie';
import $ from "jquery"; 

import 'leaflet/dist/leaflet.css';
import './CSSCustom/viewCultivations.css';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loadSwaggerEnum } from './Utils.js';

import { useTranslation } from "react-i18next";
import { usePageTitle } from './JS_Context/ContextTitle';
import getServerURL from "./Configuration.js";

const serverUrl = getServerURL();

function ViewLeafAnalysis() {
  const [leafAnalysisID, setLeafAnalysisID] = useState();
  const [cookies] = useCookies(['csrftoken']);
  const { t, i18n } = useTranslation();
  const { setPageTitle, setPageTitleIcon } = usePageTitle();
  const [selectedCultivation,setSelectedCultivation] = useState();
  const [parameterOptions, setParameterOptions] = useState([]);
  const [parameterLabelOptions, setParameterLabelOptions] = useState([]);

  // Initialize form
  const initForm = () => {
      $.ajax({
          url: serverUrl+"/leaf-analysis/"+leafAnalysisID+"/",
          type: 'get',
          headers: {
              "Accept-Language": i18n.language || 'el',
              "X-CSRFToken": cookies.csrftoken,
              "Authorization": cookies.auth
          },
          xhrFields: {
              withCredentials: true,
              xsrfCookieName: 'csrftoken',
              xsrfHeaderName: 'X-CSRFToken'
          },
          crossDomain: true,
          success: function (data) {
            if(data){
              setSelectedCultivation(data.cultivation);
              let input_created_field = document.querySelector('#creation');
              let parsedDate = new Date(data.created);
              let formattedDate = parsedDate.toLocaleDateString("en-US", {
                year: "2-digit",
                month: "numeric",
                day: "numeric",
              });
              let formattedHour = parsedDate.toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "numeric",
                hour12: false,
              });
              let formattedDateTime = `${formattedDate} ${formattedHour}`;
              input_created_field.value = formattedDateTime;
              let input_modified_field = document.querySelector('#modification');
              parsedDate = new Date(data.modified);
              formattedDate = parsedDate.toLocaleDateString("en-US", {
                year: "2-digit",
                month: "numeric",
                day: "numeric",
              });
              formattedHour = parsedDate.toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "numeric",
                hour12: false,
              });
              formattedDateTime = `${formattedDate} ${formattedHour}`;
              input_modified_field.value = formattedDateTime;
              if(data.parameters && data.parameters.length > 0){
                data.parameters.map((param) => {
                    let input_param_field = $("[id='param_"+param.type+"']");
                    if(input_param_field){
                      if(param.value_number != null){
                        input_param_field.val(Math.round(param.value_number * 100) / 100);
                      }else{
                        input_param_field.val(param.value_text);
                      }
                    }
                  return null;
                });
              }
            }
          },
          error: function (error) {
            console.error('Error fetching cultivation details:', error);
          }
      });
  };

  function loadParameterTypeOptions(){
    return loadSwaggerEnum({endpoint:'/leaf-analysis/',attribute:'parameters.items.properties.type',cookies});
  };

  function loadParameterLabelOptions(){
    return loadSwaggerEnum({endpoint:'/leaf-analysis/',attribute:'parameters.items.properties.label',cookies});
  };

  useLayoutEffect(() => {
    loadParameterTypeOptions().then((result) => {
      setParameterOptions(result);
    });
    loadParameterLabelOptions().then((result) => {
      setParameterLabelOptions(result);
    });
    const params = new URLSearchParams(window.location.search);
    setLeafAnalysisID(params.get('id'));
  }, [i18n.language]); // eslint-disable-line react-hooks/exhaustive-deps

  //Initialize page
  useEffect(() =>{
    if(leafAnalysisID && parameterOptions.length > 0 && parameterLabelOptions.length > 0){
      initForm();
    }
  },[leafAnalysisID,parameterOptions,parameterLabelOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() =>{
    setPageTitle("analysis.leaf.view.title");
    setPageTitleIcon("/img/ICON_DATA_GREEN.png");
  },[])
    

  return (
    <div className="content page1_content">
      <div className="create-field-map" >
          <MapCreateComponent selectedCultivation={selectedCultivation} readOnly={true}/>
      </div>
      <div className="form-background">
        <form>
          <div className="form-container"
            style={{height: "70vh",overflowY: "auto", overflowX: "hidden", paddingRight: "20px"}}>            
            <div className="form-row">
              <label htmlFor="creation" className='label-create'>{t('analysis.leaf.form.create')}</label>
              <input type="text" id="creation" name="creation" className='create-input'  
                placeholder={t('analysis.leaf.form.placeholder.create')} readOnly />
            </div>
            <div className="form-row">
              <label htmlFor="modification" className='label-create'>{t('analysis.leaf.form.edit')}</label>
              <input type="text" id="modification" name="modification"  className='create-input'
                placeholder={t('analysis.leaf.form..placeholder.edit')} readOnly />
            </div>
            {parameterOptions.map((option, index) => (
              <div className="form-row" style={{flexDirection:"row",borderBottom: "1px dotted black", marginRight: "15px"}}>
                <label htmlFor={option.value} className='label-create'>{parameterLabelOptions[index]?.value}</label>
                <input type="number" id={"param_"+option.value} name={option.value} validate_key="value_number" django-label={parameterLabelOptions[index]?.value}
                  style={{width:"49%",marginTop:"auto",marginBottom:"10px"}}
                  className='create-input leaf-parameter-input' readOnly
                />
              </div>
            ))}
          </div>
        </form>
      </div>
       {/* ToastContainer for displaying notifications */}
      <ToastContainer
        position="bottom-right"
        autoClose={7000} // Close the notification after 7 seconds
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
    
  );
};

export default ViewLeafAnalysis;