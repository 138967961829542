import getServerURL from "./Configuration.js";
import $ from "jquery";
import moment from "moment";
import i18n from 'i18next';

const serverUrl = getServerURL();

/*export const loadSwaggerEnum = ({endpoint,attribute, cookies}) => {

    return new Promise((resolve, reject) => {$.ajax({
        url: serverUrl+"/api/swagger.json",
        type: 'get',
        headers: {
          "Accept-Language": i18n.language || 'el',
          'X-CSRFToken': cookies.csrftoken,
          'Content-Type': 'application/json',
          "Authorization": cookies.auth
        },
        xhrFields: {
        withCredentials: true,
        xsrfCookieName: 'csrftoken',
        xsrfHeaderName: 'X-CSRFToken'
        },
        crossDomain: true,
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        success: function (data, textStatus, request) {
            data = data['paths'][endpoint]['post']['parameters'][0]['schema']['properties'];
            let splits = attribute.split('.');
            for(let split of splits) { 
                data = data[split];
            }
            data = data['enum'];
            let options = [];
            for(let datum of data){
                options.push({
                    value: datum,
                    label: datum,
                    name: attribute
                });
            }
            resolve(options);
        },
        error: function (request, textStatus, errorThrown) {
            // Handle errors
            console.error('Unit get request failed:', errorThrown);
            reject(errorThrown);
        }
    })});
};*/

export const loadSwaggerEnum = ({endpoint,attribute, cookies}) => {

  return new Promise((resolve, reject) => {$.ajax({
      url: serverUrl+"/get_swagger_options/?endpoint="+endpoint+"&attribute="+attribute,
      type: 'get',
      headers: {
        "Accept-Language": i18n.language || 'el',
        'X-CSRFToken': cookies.csrftoken,
        'Content-Type': 'application/json',
        "Authorization": cookies.auth
      },
      xhrFields: {
      withCredentials: true,
      xsrfCookieName: 'csrftoken',
      xsrfHeaderName: 'X-CSRFToken'
      },
      crossDomain: true,
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      success: function (data, textStatus, request) {
          resolve(data);
      },
      error: function (request, textStatus, errorThrown) {
          // Handle errors
          console.error('Unit get request failed:', errorThrown);
          reject(errorThrown);
      }
  })});
};

export const loadModelEnum = ({endpoint,value,label,attribute,cookies}) => {
    return new Promise((resolve, reject) => {$.ajax({
        url: serverUrl+endpoint,
        type: 'get',
        headers: {
          "Accept-Language": i18n.language || 'el',
          'X-CSRFToken': cookies.csrftoken,
          'Content-Type': 'application/json',
          "Authorization": cookies.auth
        },
        xhrFields: {
          withCredentials: true,
          xsrfCookieName: 'csrftoken',
          xsrfHeaderName: 'X-CSRFToken'
        },
        crossDomain: true,
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        success: function (data, textStatus, request) {
          let options = [];
          for(let datum of data){
              options.push({
                  value: datum[value],
                  label: datum[label],
                  name: attribute
              });
          }
          resolve(options);
        },
        error: function (request, textStatus, errorThrown) {
            // Handle errors
            console.error(attribute+' get request failed:', errorThrown);
            reject(errorThrown);
        }
      })});
};

export const parseDateWithFallback = (dateString) => {
    const commonFormats = ['M/D/YYYY HH:mm','M/D/YYYY H:mm', 'YYYY-MM-DD', 'MM/DD/YYYY', 'DD-MM-YYYY', 'YYYY/MM/DD'];
  
    let parsedDate = moment(dateString, moment.ISO_8601, true); // First try ISO 8601
    
    if (!parsedDate.isValid()) {
      // Try fallback formats
      parsedDate = moment(dateString, commonFormats, true);
    }
  
    if (parsedDate.isValid()) {
      return parsedDate.toDate();
    } else {
      throw new Error('Invalid date format: '+dateString);
    }
};