import React, { useState, useEffect, useRef} from 'react';
import MapCreateComponent from './MapCreateComponent';
import { Link } from 'react-router-dom';

import { useCookies } from 'react-cookie';
import $ from "jquery";
import getServerURL from "./Configuration.js";
import { usePageTitle } from './JS_Context/ContextTitle';
import i18n from 'i18next';

import 'leaflet/dist/leaflet.css';
import './CSSCustom/fieldForms.css';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import originaLDelete from './img/ICON_PINAKAS_DELETE_GREEN.png';
import hoverDelete from './img/ICON_PINAKAS_DELETE_ORANGE.png';
import originalEdit from './img/ICON_PINAKAS_EDIT_GREEN.png';
import hoverEdit from './img/ICON_PINAKAS_EDIT_ORANGE.png';
import originalAdd from './img/ICON_PINAKAS_PROSTHIKI_GREEN.png';
import hoverAdd from './img/ICON_PINAKAS_PROSTHIKI_ORANGE.png';
import originalView from './img/ICON_PINAKAS_VIEW_GREEN.png';
import hoverView from './img/ICON_PINAKAS_VIEW_ORANGE.png';
import checkedIcon from './img/checked.png';

import { useTranslation } from 'react-i18next';

const serverUrl = getServerURL();

function ViewFields() {
  const setGeoFieldVal = useState()[1];
  const [isClearPressed, setisClearPressed] = useState(false);
  const [fieldID, setFieldID] = useState();
  const [cookies] = useCookies(['csrftoken']);
  const [isHoveredDelete, setIsHoveredDelete] = useState([]);
  const [isHoveredAdd, setIsHoveredAdd] = useState([]);
  const [isHoveredEdit, setIsHoveredEdit] = useState([]);
  const [isHoveredView, setIsHoveredView] = useState([]);
  const [isRedrawPressed, setisRedrawPressed] = useState(false);
  const { t } = useTranslation();
  const { setPageTitle, setPageTitleIcon } = usePageTitle();
  const geoFieldRef = useRef();
  const [isCultivationsPopupVisible, setIsCultivationsPopupVisible] = useState(false);
  const [isActionsPopupVisible, setIsActionsPopupVisible] = useState(false);
  const [isAnalysisPopupVisible, setIsAnalysisPopupVisible] = useState(false);
  const [cultivationList, setCultivationList] = useState([]);
  const [actionList, setActionList] = useState([]);
  const [analysisList, setAnalysisList] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [actionToDelete, setActionToDelete] = useState();
  const [cultivationToDelete, setCultivationToDelete] = useState();
  const [soilAnalysisToDelete, setSoilAnalysisToDelete] = useState();
  const [cultivationDetails, setCultivationDetails] = useState({});


  const toggleCultivationsPopup = () => {
    setIsActionsPopupVisible(false);
    setIsAnalysisPopupVisible(false);
    setIsCultivationsPopupVisible(!isCultivationsPopupVisible);
  };

  const toggleActionsPopup = () => {
    setIsCultivationsPopupVisible(false);
    setIsAnalysisPopupVisible(false);
    setIsActionsPopupVisible(!isActionsPopupVisible);
  };

  const toggleAnalysisPopup = () => {
    setIsCultivationsPopupVisible(false);
    setIsActionsPopupVisible(false);
    setIsAnalysisPopupVisible(!isAnalysisPopupVisible);
  };

  // Set GeoGieldVal based on geoFieldReference
  const handleGeomChange = () => {
    setGeoFieldVal(geoFieldRef.current.value);
  };

  // Delete icon hover handle
  const handleMouseEnterDelete = (index) => {
    const newHoverState = [...isHoveredDelete];
    newHoverState[index] = true;
    setIsHoveredDelete(newHoverState);
  };

  const handleMouseLeaveDelete = (index) => {
    const newHoverState = [...isHoveredDelete];
    newHoverState[index] = false;
    setIsHoveredDelete(newHoverState);
  };

  const handleDeleteActionClick = (action) => {
    setActionToDelete(action);
    setShowDeleteConfirmation(true);
  };

  const handleDeleteCultivationClick = (cultivation) => {
    setCultivationToDelete(cultivation);
    setShowDeleteConfirmation(true);
  };

  const handleDeleteSoilAnalysisClick = (action) => {
    setSoilAnalysisToDelete(action);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete = () => {
    let deleteUrl = undefined;
    let itemID = undefined;
    if (!actionToDelete && !cultivationToDelete && !soilAnalysisToDelete) {
      return;
    }
    if(cultivationToDelete){
      deleteUrl = "/cultivations/";
      itemID = cultivationToDelete.id;
      setActionToDelete(undefined);
      setSoilAnalysisToDelete(undefined);
    }else if(actionToDelete){
      setActionToDelete(undefined);
      setCultivationToDelete(undefined);
      setSoilAnalysisToDelete(undefined);
      setShowDeleteConfirmation(false);
      toast.error(t("common.error.unsupported"));
      return;
    }else if(soilAnalysisToDelete){
      deleteUrl = "/soil-analysis/";
      itemID = soilAnalysisToDelete.id;
      setActionToDelete(undefined);
      setCultivationToDelete(undefined);
    }
    if(itemID === undefined){
      toast.error(`${t("common.error.general")}: ${"itemID == undefined"}`);
      return;
    }

    $.ajax({
      url: serverUrl+deleteUrl+itemID+"/",
      type: 'delete',
      headers: {
          "Accept-Language": i18n.language || 'el',
          "X-CSRFToken": cookies.csrftoken,
          "Authorization": cookies.auth
      },
      xhrFields: {
          withCredentials: true,
          xsrfCookieName: 'csrftoken',
          xsrfHeaderName: 'X-CSRFToken'
      },
      crossDomain: true,
      dataType: 'json',
      success: function (data) {
          // Step 2: Remove the item from local state
          if(deleteUrl === "/cultivations/"){
            const updatedCultivationList = cultivationList.filter((cultivation) => cultivation.id !== itemID);
            setCultivationList(updatedCultivationList);
            setCultivationToDelete(undefined);
            setisRedrawPressed(!isRedrawPressed);
            toast.success(t("fields.form.delete_cultivation_success")
              .replace("\{\{cultivation_name\}\}", cultivationToDelete.name));
          }else if(deleteUrl === "/soil-analysis/"){
            const updatedAnalysisList = analysisList.filter((analysis) => analysis.id !== itemID);
            setAnalysisList(updatedAnalysisList);
            setSoilAnalysisToDelete(undefined);
            toast.success(t("fields.form.delete_analysis_success")
              .replace("\{\{analysis_id\}\}", soilAnalysisToDelete.id));
          }
          /*else if(deleteUrl === "/action/"){
            const updatedActionList = actionList.filter((action) => action.id !== itemID);
            setActionList(updatedActionList);
            setActionToDelete(undefined);
            toast.success(t("fields.form.delete_action_success")
              .replace("\{\{action_id\}\}", actionToDelete.id));
          }*/
          initForm();
      },
      error: function (error) {
        if(deleteUrl === "/cultivations/"){
          console.error(`${t("fields.form.delete_cultivation_fail")
              .replace("\{\{cultivation_name\}\}", cultivationToDelete.name)} ${error}`);
          toast.error(`${t("fields.form.delete_cultivation_fail")
              .replace("\{\{cultivation_name\}\}", cultivationToDelete.name)} ${error}`);
        }else if(deleteUrl === "/soil-analysis/"){ 
          console.error(`${t("fields.form.delete_analysis_fail")
            .replace("\{\{analysis_id\}\}", soilAnalysisToDelete.id)} ${error}`);
          toast.error(`${t("fields.form.delete_analysis_fail")
            .replace("\{\{analysis_id\}\}", soilAnalysisToDelete.id)} ${error}`);
        }
        /*else if(deleteUrl === "/action/"){
          console.error(`${t("fields.form.delete_action_fail")
            .replace("\{\{action_id\}\}", actionToDelete.id)} ${error}`);
          toast.error(`${t("fields.form.delete_action_fail")
            .replace("\{\{action_id\}\}", actionToDelete.id)} ${error}`);
        }*/
      }
    });
    setShowDeleteConfirmation(false);
  };

  const DeleteConfirmationModal = ({ onCancel, onConfirm }) => {
    let label;
    let name;
    if(cultivationToDelete){
      label="fields.form.delete_cultivation_confirmation";
      name=cultivationToDelete.name;
    }else if(actionToDelete){
      label="fields.form.delete_action_confirmation";
      name=actionToDelete.id;
    } else if(soilAnalysisToDelete){
      label="fields.form.delete_analysis_confirmation";
      name=soilAnalysisToDelete.id;
    }
    return (
      <div className='delete-confirmation-overlay'>
        <div className="deleteModal-background">
          <div className="delete-confirmation-modal">
            <p>
              {t(label)}
              <span className="field-name">{name}</span>
              {t("common.punctuation.question")}
            </p>
            <button onClick={onConfirm}>{t("common.form.confirm")}</button>
            <button onClick={onCancel}>{t("common.form.cancel")}</button>
          </div>
        </div>
      </div>
    );
  };

  // Add icon hover handle
  const handleMouseEnterAdd = (index) => {
    const newHoverState = [...isHoveredAdd];
    newHoverState[index] = true;
    setIsHoveredAdd(newHoverState);
  };

  const handleMouseLeaveAdd = (index) => {
    const newHoverState = [...isHoveredAdd];
    newHoverState[index] = false;
    setIsHoveredAdd(newHoverState);
  };

  // Edit icon hover handle
  const handleMouseEnterEdit = (index) => {
    const newHoverState = [...isHoveredEdit];
    newHoverState[index] = true;
    setIsHoveredEdit(newHoverState);
  };
  const handleMouseLeaveEdit = (index) => {
    const newHoverState = [...isHoveredEdit];
    newHoverState[index] = false;
    setIsHoveredEdit(newHoverState);
  };

  // View icon hover handle
  const handleMouseEnterView = (index) => {
    const newHoverState = [...isHoveredView];
    newHoverState[index] = true;
    setIsHoveredView(newHoverState);
  };

  const handleMouseLeaveView = (index) => {
    const newHoverState = [...isHoveredView];
    newHoverState[index] = false;
    setIsHoveredView(newHoverState);
  };

  const handleViewClick = (index) => {
    console.log(`Clicked on the "View" image in row with id: ${index}`);}


    const CultivationTableModal = ({ cultivationDetails }) => {
      // Helper function to determine color based on date
      const getColorByDate = (date) => {
        const currentDate = new Date();
        const suggestionDate = new Date(date);
      
        suggestionDate.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0
      
        const daysDifference = Math.floor((suggestionDate - currentDate) / (24 * 60 * 60 * 1000));
        let textColor = '';
        let text = '';
        let warningColor = '#EA840A';
        let errorColor = '#dc3545';
        let normalColor ='1E1E1E';     

        if (daysDifference < -1) {
          textColor = errorColor;
          text = t("common.suggestion_modal.days_past")
                .replace("\{\{days\}\}", Math.abs(daysDifference));
        } else if (daysDifference === -1) {
          textColor = errorColor;
          text = t("common.suggestion_modal.day_past")
                .replace("\{\{days\}\}", Math.abs(daysDifference));
        } else if (daysDifference === 0) {
          textColor = errorColor;
          text = t("common.suggestion_modal.today");
        } else if (daysDifference === 1) {
          textColor = errorColor;
          text = t("common.suggestion_modal.day_future")
                .replace("\{\{days\}\}", Math.abs(daysDifference));
        } else if (daysDifference <= 2) {
          textColor = errorColor;
          text = t("common.suggestion_modal.days_future")
                .replace("\{\{days\}\}", Math.abs(daysDifference));
        } else if (daysDifference <= 4) {
          textColor = warningColor;
          text = t("common.suggestion_modal.days_future")
                .replace("\{\{days\}\}", Math.abs(daysDifference));
        } else {
          textColor = normalColor;
          text = t("common.suggestion_modal.days_future")
                .replace("\{\{days\}\}", Math.abs(daysDifference));
        }
  
        return { textColor, text };
      };
    
      return (
        // 
        <div className='field-suggestion-table'>
          <h2 style={{textAlign: "center"}}>
            {t("common.suggestion_modal.active")}
          </h2>     
          <table>
            <thead>
              <tr>
                <th>{t("common.suggestion_modal.cultivation")}</th>
                <th>{t("common.suggestion_modal.άρδευση")}</th>
                <th>{t("common.suggestion_modal.λίπανση")}</th>
                <th>{t("common.suggestion_modal.φροντίδα_καλλιέργειας")}</th>
              </tr>
            </thead>
            <tbody>
            {Object.keys(cultivationDetails).map((cultivationID) => {
              const { name, suggestions } = cultivationDetails[cultivationID];
              let wateringDate = '';
              if(suggestions){
                if(suggestions['άρδευση']){
                  if(suggestions['άρδευση'][0]){
                    if(Object.hasOwn(suggestions['άρδευση'][0], 'date')){
                      wateringDate = suggestions['άρδευση'][0]['date'];
                    }
                  }
                }
              }

              if (!wateringDate && suggestions['λίπανση'].length === 0) {
                return null;
              }

              let getWateringColorAndText = {};
              if (wateringDate) {
                getWateringColorAndText = getColorByDate(wateringDate);
              }

              return (
                <tr key={cultivationID}>
                  <td>{name}</td>
                  <td style={{ color: getWateringColorAndText.color }}>
                    {wateringDate && <div>{wateringDate}</div>}
                    {getWateringColorAndText.text && <div>{getWateringColorAndText.text}</div>}
                    {!wateringDate && suggestions['λίπανση'].length > 0 && <img src={checkedIcon} alt="Checked" className='Checked'/>}
                  </td>



                  { (    
                <td>
                  { suggestions['λίπανση'].length > 0 && (
                    // suggestionTableData
                    // .filter(row => row.suggestionType === 'λίπανση')
                    // .map((row, index) => (
                      // <div  key={index} style={{ color: row.color, textAlign: "left" }}>
                      <div style={{textAlign: "left" }}>  
                        <div >
                           {/* {row.AllRatings.slice(0, 3).map((number) => */}
                           {/* {row.AllRatings.map((number) =>
                           <li>{number}</li>)}                */}
                        </div>                        
                        <div>                 
                        <Link 
                          style={{color: "#1E1E1E"}} 
                          className='links' 
                          to={
                            "/view-fertilization-suggestion/?id="+
                            suggestions['λίπανση'][0]['id']
                          }>
                          {t("common.suggestion_modal.details")}
                        </Link>
                      </div>
                      </div>
                    // )
                    // )
                  )}
                </td>
              )}

              {(    
                <td>
                  { suggestions['φροντίδα καλλιέργειας'].length > 0 && (
                    // suggestionTableData
                    // .filter(row => row.suggestionType === 'φροντίδα καλλιέργειας')
                    // .map((row, index) => (
                      // <div  key={index} style={{ color: row.color, textAlign: "left" }}>
                      <div style={{textAlign: "left" }}>  
                        <div >
                           {/* {row.AllRatings.slice(0, 3).map((number) => */}
                           {/* {row.AllRatings.map((number) =>
                           <li>{number}</li>)}                */}
                        </div>                        
                        <div>                 
                        <Link 
                          style={{color: "#1E1E1E"}} 
                          className='links' 
                          to={
                            "/view-leaf-suggestion/?id="+
                            suggestions['φροντίδα καλλιέργειας'][0]['id']
                          }>
                          {t("common.suggestion_modal.details")}
                        </Link>
                      </div>
                      </div>
                    // )
                    // )
                  )}
                </td>
              )}
                </tr>
              );
            })}
            </tbody>
          </table>
        </div>
      );
    };

    const showTable =
      Object.keys(cultivationDetails).some((cultivationID) => {
        const suggestions = cultivationDetails[cultivationID]?.suggestions;
        return (
          suggestions &&
          (suggestions['άρδευση']?.length > 0 || suggestions['λίπανση']?.length > 0)
        );
      });

  // Initialize form
  const initForm = () => {
    $.ajax({
        url: serverUrl+"/fields/"+fieldID+"/",
        type: 'get',
        headers: {
            "Accept-Language": i18n.language || 'el',
            "X-CSRFToken": cookies.csrftoken,
            "Authorization": cookies.auth
        },
        xhrFields: {
            withCredentials: true,
            xsrfCookieName: 'csrftoken',
            xsrfHeaderName: 'X-CSRFToken'
        },
        crossDomain: true,
        success: function (data) {
          const inputFields = document.querySelectorAll('.create-input');
          let newFormData = {};
          inputFields.forEach((input) => {
            let val = data[input.name];
            if(val){
              if((val+'').includes(";")){
                val = val.split(';')[1];
              }
              input.value = val;
              if ((input.name) === 'area'){
                input.value = input.value? parseFloat(input.value).toFixed(1) + ' m²' : 'NoValue';
              }
              newFormData[input.name] = val; 
              if(input.name === 'geom'){
                handleGeomChange();
              }
            }
          });
          const temp_cultivation_list = data['cultivations'] || [];
          const temp_action_list = data['actions'] || [];
          const temp_analysis_list = data['soil_analysis'] || [];
          
          const cleanactionlist = [];
          // Iterate through each item in actionlist
          temp_action_list.forEach((actionItem, index) => {
            // Iterate through each key in the actionItem
            Object.keys(actionItem).forEach((key) => {
              // Check if the value is an array and not empty
              if (Array.isArray(actionItem[key]) && actionItem[key].length > 0) {
                // Iterate through each dictionary inside the array
                actionItem[key].forEach((innerAction) => {
                  // Convert date format
                  const originalDate = new Date(innerAction.date);
                  const formattedDate = originalDate.toLocaleString();
                  // Create a new action
                  const newAction = {
                    type: key,
                    date: formattedDate,
                    cultivation_id: innerAction.cultivation_id,
                  };
                  // Add the new action to cleanactionlist
                  cleanactionlist.push(newAction);
                });
              }
            });
          });
          const cleananalysislist = [];
          // Iterate through each item in analysislist
          temp_analysis_list.forEach((analysisItem, index) => {
            // Convert date format
            const originalDate = new Date(analysisItem.created);
            const formattedDate = originalDate.toLocaleString();
            // Create a new action
            const newAnalysis = {
              date: formattedDate,
              id: analysisItem.id,
            };
            // Add the new action to cleanactionlist
            cleananalysislist.push(newAnalysis);
          });
          const finalCleanActionList = cleanactionlist.map((action) => {
            const correspondingCultivation = temp_cultivation_list.find(
              (cultivation) => cultivation.id === action.cultivation_id
            );
            // Add cultivation_name to the action if a match is found
            return correspondingCultivation
              ? { ...action, cultivation_name: correspondingCultivation.name }
              : action;
          });
          
          setCultivationList(temp_cultivation_list);
          setActionList(finalCleanActionList);
          setAnalysisList(cleananalysislist);
          //setFormData(newFormData);
        },
        error: function (error) {
          toast.error(`${t("fields.error.field_details")} ${error.message}`);
          console.error(t("fields.error.field_details"), error);
        }
    });
  };

  //Initialize page
  useEffect(() =>{
    const params = new URLSearchParams(window.location.search);
    setFieldID(params.get('id'));
    if(fieldID){initForm();}
  },[fieldID]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() =>{
    setPageTitle("fields.view.title");
    setPageTitleIcon("/img/ICON_AGRO_GREEN.png");
  },[])

  useEffect(() => {
    // Function to fetch details for a cultivation
    const fetchCultivationDetails = async (cultivationID) => {
      try {
        const response = await $.ajax({
          url: serverUrl + "/cultivations/" + cultivationID + "/",
          type: 'get',
          headers: {
            "Accept-Language": i18n.language || 'el',
            "X-CSRFToken": cookies.csrftoken,
            "Authorization": cookies.auth
          },
          xhrFields: {
            withCredentials: true,
            xsrfCookieName: 'csrftoken',
            xsrfHeaderName: 'X-CSRFToken'
          },
          crossDomain: true,
        });
        const { name, suggestions } = response;
        // Update state with cultivation details
        setCultivationDetails(prevDetails => ({
          ...prevDetails,
          [cultivationID]: { name, suggestions }
        }));
      } catch (error) {
        toast.error(`${t("fields.error.cultivation_details")} ${error.message}`);
        console.error(t("fields.error.cultivation_details"), error);
      }
    };

    // Fetch details for each cultivation in cultivationList
    cultivationList.forEach(cultivation => {
      fetchCultivationDetails(cultivation.id);
    });

  }, [cultivationList]); // eslint-disable-line react-hooks/exhaustive-deps
  
  return (
    <div className="content page1_content">

      <div className="create-field-map" >
          <MapCreateComponent 
              readOnly={true} 
              selectedField={fieldID} 
              isClearPressed={isClearPressed} 
              setisClearPressed={setisClearPressed}
              isRedrawPressed={isRedrawPressed}
          />
      </div>
      <div className="form-background">
        <form>
          <div className="form-container">  
            <div className="form-row">
              <label htmlFor="name" className='label-create'>{t("fields.form.name")}</label>
              <input type="text" id="name" name="name" readOnly
                className='create-input'  placeholder={t("fields.form.placeholder.name")}
              />
            </div>
            <div className="form-row">
              <label htmlFor="geom" className='label-create'>{t("fields.form.geometry")}</label>
              <input type="text" id="geom" name="geom" readOnly
                placeholder="MULTIPOLYGON (((22.167664 39.302247, 22.223969 39.307562, 
                  22.241821 39.222479, 22.101746 39.242695, 22.167664 39.302247)))"
                ref={geoFieldRef}  
                className='create-input'
              />
            </div>
            <div className="form-row">
              <label htmlFor="community" className='label-create'>{t("fields.form.community")}</label>
              <input type="text" id="community" name="community" placeholder={t("fields.form.placeholder.cummunity")}
                 className='create-input' readOnly
              />
            </div>
            <div className="form-row">
              <label htmlFor="chartography_bg" className='label-create'>{t("fields.form.cartography")}</label>
              <input type="text" id="chartography_bg" name="chartography_bg"  placeholder="1245780965321"
                 className='create-input' readOnly
              />
            </div>
            <div className="form-row">
              <label htmlFor="area" className='label-create'>{t("fields.form.area")}</label>
              <input type="text" id="area" name="area"  placeholder="1245780965321"
                 className='create-input' readOnly
              />
            </div>
            <div className="form-row clickable row-flex" onClick={()=>toggleCultivationsPopup()}>
              <label className='label-create'>{t("fields.form.cultivations")}</label>
            <div className="popup-arrow"></div>
           </div>
           <div className="form-row clickable row-flex" onClick={()=>toggleActionsPopup()}>
              <label className='label-create'>{t("fields.form.actions")}</label>
            <div className="popup-arrow"></div>
           </div>
           <div className="form-row clickable row-flex" onClick={()=>toggleAnalysisPopup()}>
              <label className='label-create'>{t("fields.form.soil_analysis")}</label>
            <div className="popup-arrow"></div>
           </div>
          </div>
        </form>
        {/* Cultivations Popup Table */}
        {isCultivationsPopupVisible && (
        <div className={`${cultivationList.length === 0 ? 'cultivations-popup-no-cultivations' : "cultivations-popup"}`}>
            {cultivationList.length === 0 ? (
            <p>{t("fields.form.no_cultivations")}</p>
            ) :(
            <table className="cultivations-table">
              <thead>
                <tr>
                  <th></th>
                  <th>{t("fields.form.name")}</th>
                  <th>{t("fields.form.variety")}</th>
                </tr>
              </thead>
              <tbody>
                {cultivationList.map((cultivation, index) => (
                  <tr                  
                    key={cultivation.id}
                    id={`row-${cultivation.id}`}
                    className={"cultivation_row"}>
                    <td>
                        <Link style={{textDecoration:"none"}} className='links' to={"/add-to-cultivation/"+cultivation.id}>
                          <img
                            onMouseEnter={() => handleMouseEnterAdd(index)}
                            onMouseLeave={() => handleMouseLeaveAdd(index)}
                            src={isHoveredAdd[index] ? hoverAdd : originalAdd}
                            alt="Add"
                            className={`tiny-button ${isHoveredAdd[index] ? 'hovered' : ''}`}
                          />
                        </Link>
                        <Link style={{textDecoration:"none"}} className='links' to={"/edit-cultivation/?id="+cultivation.id}>
                          <img
                            onMouseEnter={() => handleMouseEnterEdit(index)}
                            onMouseLeave={() => handleMouseLeaveEdit(index)}
                            src={isHoveredEdit[index] ? hoverEdit : originalEdit}
                            alt="Edit"
                            className={`tiny-button ${isHoveredEdit[index] ? 'hovered' : ''}`}
                          />
                        </Link>
                        <Link style={{textDecoration:"none"}} className='links' to={"/view-cultivation/?id="+cultivation.id}>
                          <img
                            onMouseEnter={() => handleMouseEnterView(index)}
                            onMouseLeave={() => handleMouseLeaveView(index)}
                            src={isHoveredView[index] ? hoverView : originalView}
                            alt="View"
                            className={`tiny-button ${isHoveredView[index] ? 'hovered' : ''}`}
                        />
                        </Link>
                        <img
                          onMouseEnter={() => handleMouseEnterDelete(index)}
                          onMouseLeave={() => handleMouseLeaveDelete(index)}
                          src={isHoveredDelete[index] ? hoverDelete : originaLDelete}
                          alt="Delete"
                          className="tiny-button"
                          onClick={() => handleDeleteCultivationClick(cultivation)} 
                        />
                    </td>
                    <td>{cultivation.name}</td>
                    <td>{cultivation.cultivar.name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          </div>
        )}
        {/* Actions Popup Table */}
        {isActionsPopupVisible && (
        <div className={`${actionList.length === 0 ? 'actions-popup-no-actions' : "actions-popup"}`}>
            {actionList.length === 0 ? (
            <p>{t("fields.form.no_actions")}</p>
            ) :(
            <table className="actions-table">
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th>{t("fields.form.date")}</th>
                  <th>{t("fields.form.cultivation")}</th>
                </tr>
              </thead>
              <tbody>
                {actionList.map((action, index) => (
                  <tr                  
                    key={action.cultivation_id} 
                    id={`row-${action.cultivation_id}`}
                    className={"action_row"}
                  >
                    <td>
                      <img
                        onMouseEnter={() => handleMouseEnterEdit(index)}
                        onMouseLeave={() => handleMouseLeaveEdit(index)}
                        src={isHoveredEdit[index] ? hoverEdit : originalEdit}
                        alt="Edit"
                        className={`tiny-button ${isHoveredEdit[index] ? 'hovered' : ''}`}
                      />
                      <img
                        onMouseEnter={() => handleMouseEnterView(index)}
                        onMouseLeave={() => handleMouseLeaveView(index)}
                        src={isHoveredView[index] ? hoverView : originalView}
                        alt="View"
                        className={`tiny-button ${isHoveredView[index] ? 'hovered' : ''}`}
                        onClick={() => handleViewClick(index)} 
                      /> 
                      <img
                        onMouseEnter={() => handleMouseEnterDelete(index)}
                        onMouseLeave={() => handleMouseLeaveDelete(index)}
                        src={isHoveredDelete[index] ? hoverDelete : originaLDelete}
                        alt="Delete"
                        className="tiny-button"
                        onClick={() => handleDeleteActionClick(action)} 
                      />
                    </td>
                    <td>{action.type}</td>
                    <td>{action.date}</td>
                    <td>{action.cultivation_name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        )}
        {/* Analysis Popup Table */}
        {isAnalysisPopupVisible && (
        <div className={`${analysisList.length === 0 ? 'analysis-popup-no-analysis' : "analysis-popup"}`}>
            {analysisList.length === 0 ? (
            <p>{t("fields.form.no_soil_analysis")}</p>
            ) :(
            <table className="analysis-table">
              <thead>
                <tr>
                  <th></th>
                  <th>{t("fields.form.id")}</th>
                  <th>{t("fields.form.date")}</th>
                </tr>
              </thead>
              <tbody>
                {analysisList.map((analysis, index) => (
                  <tr                  
                    key={analysis.id}
                    id={`row-${analysis.id}`}
                    className={"analysis_row"}>
                    <td>
                      <Link style={{textDecoration:"none"}} className='links' to={"/edit-soil-analysis/?id="+analysis.id}>
                        <img
                          onMouseEnter={() => handleMouseEnterEdit(index)}
                          onMouseLeave={() => handleMouseLeaveEdit(index)}
                          src={isHoveredEdit[index] ? hoverEdit : originalEdit}
                          alt="Edit"
                          className={`tiny-button ${isHoveredEdit[index] ? 'hovered' : ''}`}
                        />
                      </Link> 
                      <Link style={{textDecoration:"none"}} className='links' to={"/view-soil-analysis/?id="+analysis.id}>
                        <img
                          onMouseEnter={() => handleMouseEnterView(index)}
                          onMouseLeave={() => handleMouseLeaveView(index)}
                          src={isHoveredView[index] ? hoverView : originalView}
                          alt="View"
                          className={`tiny-button ${isHoveredView[index] ? 'hovered' : ''}`}
                      />
                      </Link> 
                      <img
                        onMouseEnter={() => handleMouseEnterDelete(index)}
                        onMouseLeave={() => handleMouseLeaveDelete(index)}
                        src={isHoveredDelete[index] ? hoverDelete : originaLDelete}
                        alt="Delete"
                        className="tiny-button"
                        onClick={() => handleDeleteSoilAnalysisClick(analysis)} 
                      />
                    </td>
                    <td>{analysis.id}</td>
                    <td>{analysis.date}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          </div>
        )}
      </div>
       {/* ToastContainer for displaying notifications */}
      <ToastContainer
        position="bottom-right"
        autoClose={7000} // Close the notification after 7 seconds
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {showDeleteConfirmation && (
        <DeleteConfirmationModal
          onCancel={() => setShowDeleteConfirmation(false)}
          onConfirm={() => handleConfirmDelete()}
        />
      )}
      {showTable && <CultivationTableModal cultivationDetails={cultivationDetails} />}
    </div>
  );
};

export default ViewFields;
