import React from 'react';
import './CSSCustom/Footer.css';

function Footer() {
  return (
    <div className="footer">
      {/* Footer content goes here */}
      <div className="copyright-wrapper">
        <div className="copyright">© 2023 Tomato the smartway,  Inc. All rights reserved.</div>
      </div>
    </div>
  );
}

export default Footer;
