import React, { useState, useEffect, useRef} from 'react';
import MapCreateComponent from './MapCreateComponent';
import { useCookies } from 'react-cookie';
import $ from "jquery"; 
import { Link } from 'react-router-dom';
import i18n from 'i18next';

import 'leaflet/dist/leaflet.css';
import './CSSCustom/viewCultivations.css';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import originaLDelete from './img/ICON_PINAKAS_DELETE_GREEN.png';
import hoverDelete from './img/ICON_PINAKAS_DELETE_ORANGE.png';
import originalEdit from './img/ICON_PINAKAS_EDIT_GREEN.png';
import hoverEdit from './img/ICON_PINAKAS_EDIT_ORANGE.png';
import originalView from './img/ICON_PINAKAS_VIEW_GREEN.png';
import hoverView from './img/ICON_PINAKAS_VIEW_ORANGE.png';
import getServerURL from "./Configuration.js";
import { useTranslation } from 'react-i18next';
import { usePageTitle } from './JS_Context/ContextTitle';

const serverUrl = getServerURL();

function ViewCultivations() {
  const [isClearPressed, setisClearPressed] = useState(false);
  const [cultivationID, setCultivationID] = useState();
  const [cookies] = useCookies(['csrftoken']);
  const [selectedCultivation,setSelectedCultivation] = useState();
  const [isActionsPopupVisible, setIsActionsPopupVisible] = useState(false);
  const [isAnalysisPopupVisible, setIsAnalysisPopupVisible] = useState(false);
  const [actionList, setActionList] = useState([]);
  const [analysisList, setAnalysisList] = useState([]);
  const [isHoveredDelete, setIsHoveredDelete] = useState([]);
  const [isHoveredEdit, setIsHoveredEdit] = useState([]);
  const [isHoveredView, setIsHoveredView] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionTableData, setSuggestionTableData] = useState([]);
  const [leafAnalysisToDelete, setLeafAnalysisToDelete] = useState();
  const [actionToDelete, setActionToDelete] = useState();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const geoFieldRef = useRef();
  const cultivarSelectFieldRef = useRef();
  const phaseSelectFieldRef = useRef();
  const { t } = useTranslation();
  const { setPageTitle, setPageTitleIcon } = usePageTitle();

  const handleMouseEnterDelete = (index) => {
    const newHoverState = [...isHoveredDelete];
    newHoverState[index] = true;
    setIsHoveredDelete(newHoverState);
  };

  const handleMouseEnterEdit = (index) => {
    const newHoverState = [...isHoveredEdit];
    newHoverState[index] = true;
    setIsHoveredEdit(newHoverState);
  };

  const handleMouseEnterView = (index) => {
    const newHoverState = [...isHoveredView];
    newHoverState[index] = true;
    setIsHoveredView(newHoverState);
  };

  const handleMouseLeaveDelete = (index) => {
    const newHoverState = [...isHoveredDelete];
    newHoverState[index] = false;
    setIsHoveredDelete(newHoverState);
  };

  const handleMouseLeaveEdit = (index) => {
    const newHoverState = [...isHoveredEdit];
    newHoverState[index] = false;
    setIsHoveredEdit(newHoverState);
  };

  const handleMouseLeaveView = (index) => {
    const newHoverState = [...isHoveredView];
    newHoverState[index] = false;
    setIsHoveredView(newHoverState);
  };

  const handleConfirmDelete = () => {
    let deleteUrl = undefined;
    let itemID = undefined;
    if (!actionToDelete && !leafAnalysisToDelete) {
      return;
    }
    if(actionToDelete){
      setActionToDelete(undefined);
      setLeafAnalysisToDelete(undefined);
      setShowDeleteConfirmation(false);
      toast.error(t("common.error.unsupported"));
      return;
    }else if(leafAnalysisToDelete){
      deleteUrl = "/leaf-analysis/";
      itemID = leafAnalysisToDelete.id;
      setActionToDelete(undefined);
    }
    if(itemID === undefined){
      toast.error(`${t("common.error.general")}: ${"itemID == undefined"}`);
      return;
    }

    $.ajax({
      url: serverUrl+deleteUrl+itemID+"/",
      type: 'delete',
      headers: {
          "Accept-Language": i18n.language || 'el',
          "X-CSRFToken": cookies.csrftoken,
          "Authorization": cookies.auth
      },
      xhrFields: {
          withCredentials: true,
          xsrfCookieName: 'csrftoken',
          xsrfHeaderName: 'X-CSRFToken'
      },
      crossDomain: true,
      dataType: 'json',
      success: function (data) {
          // Step 2: Remove the item from local state
          if(deleteUrl === "/leaf-analysis/"){
            const updatedAnalysisList = analysisList.filter((analysis) => analysis.id !== itemID);
            setAnalysisList(updatedAnalysisList);
            setLeafAnalysisToDelete(undefined);
            toast.success(t("fields.form.delete_analysis_success")
              .replace("{{analysis_id}}", leafAnalysisToDelete.id));
          }
          /*else if(deleteUrl === "/action/"){
            const updatedActionList = actionList.filter((action) => action.id !== itemID);
            setActionList(updatedActionList);
            setActionToDelete(undefined);
            toast.success(t("fields.form.delete_action_success")
              .replace("\{\{action_id\}\}", actionToDelete.id));
          }*/
          initForm();
      },
      error: function (error) {
        if(deleteUrl === "/leaf-analysis/"){ 
          console.error(`${t("fields.form.delete_analysis_fail")
            .replace("{{analysis_id}}", leafAnalysisToDelete.id)} ${error}`);
          toast.error(`${t("fields.form.delete_analysis_fail")
            .replace("{{analysis_id}}", leafAnalysisToDelete.id)} ${error}`);
        }
        /*else if(deleteUrl === "/action/"){
          console.error(`${t("fields.form.delete_action_fail")
            .replace("\{\{action_id\}\}", actionToDelete.id)} ${error}`);
          toast.error(`${t("fields.form.delete_action_fail")
            .replace("\{\{action_id\}\}", actionToDelete.id)} ${error}`);
        }*/
      }
    });
    setShowDeleteConfirmation(false);
  };

  const DeleteConfirmationModal = ({ onCancel, onConfirm }) => {
    let label;
    let name;
    if(actionToDelete){
      label="fields.form.delete_action_confirmation";
      name=actionToDelete.id;
    } else if(leafAnalysisToDelete){
      label="fields.form.delete_analysis_confirmation";
      name=leafAnalysisToDelete.id;
    }
    return (
      <div className='delete-confirmation-overlay'>
        <div className="deleteModal-background">
          <div className="delete-confirmation-modal">
            <p>
              {t(label)}
              <span className="field-name">{name}</span>
              {t("common.punctuation.question")}
            </p>
            <button onClick={onConfirm}>{t("common.form.confirm")}</button>
            <button onClick={onCancel}>{t("common.form.cancel")}</button>
          </div>
        </div>
      </div>
    );
  };

  const handleEditClick = (index) => {
    console.log(`Clicked on the "Edit" image in row with id: ${index}`);
  }

  const handleViewClick = (index) => {
    console.log(`Clicked on the "View" image in row with id: ${index}`);
  }

  const handleDeleteClick = (index) => {
    console.log("Click delete with index", index)
  };

  const handleDeleteLeafAnalysisClick = (action) => {
    setLeafAnalysisToDelete(action);
    setShowDeleteConfirmation(true);
  };

  // Initialize form
  const initForm = () => {
      $.ajax({
          url: serverUrl+"/cultivations/"+cultivationID+"/",
          type: 'get',
          headers: {
              "Accept-Language": i18n.language || 'el',
              "X-CSRFToken": cookies.csrftoken,
              "Authorization": cookies.auth
          },
          xhrFields: {
              withCredentials: true,
              xsrfCookieName: 'csrftoken',
              xsrfHeaderName: 'X-CSRFToken'
          },
          crossDomain: true,
          success: function (data) {
            const inputFields = document.querySelectorAll('.create-input');
            inputFields.forEach((input) => {
              let val = data[input.name];
              if(val){
                if((val+'').includes(";")){
                  val = val.split(';')[1];
                }
                if(input.type === 'datetime-local'){
                  val = val.slice(0, -1);
                }
                if(val?.id){
                  val = val.id;
                }
                input.value = val;
              }
            });
            setSuggestions(data.suggestions || {})
            const actionlist = data['actions'] || [];
            const cleanactionlist = [];
            // Iterate through each item in actionlist
            actionlist.forEach((actionItem, index) => {
              // Iterate through each key in the actionItem
              Object.keys(actionItem).forEach((key) => {
                // Check if the value is an array and not empty
                if (Array.isArray(actionItem[key]) && actionItem[key].length > 0) {
                  // Iterate through each dictionary inside the array
                  actionItem[key].forEach((innerAction) => {
                    // Convert date format
                    const originalDate = new Date(innerAction.date);
                    const formattedDate = originalDate.toLocaleString();
                    // Create a new action
                    const newAction = {
                      type: key,
                      date: formattedDate,
                      cultivation_id: innerAction.cultivation_id,
                    };
                    // Add the new action to cleanactionlist
                    cleanactionlist.push(newAction);
                  });
                }
              });
            });            
            setActionList(cleanactionlist);
            const temp_analysis_list = data['leaf_analysis'] || [];
            const cleananalysislist = [];
            // Iterate through each item in actionlist
            temp_analysis_list.forEach((analysisItem, index) => {
              // Convert date format
              const originalDate = new Date(analysisItem.created);
              const formattedDate = originalDate.toLocaleString();
              // Create a new action
              const newAnalysis = {
                date: formattedDate,
                id: analysisItem.id,
              };
              // Add the new action to cleanactionlist
              cleananalysislist.push(newAnalysis);
            });          
            setActionList(cleanactionlist);
            setAnalysisList(cleananalysislist);
            setSelectedCultivation(data['id']);
            cultivarSelectFieldRef.current.value=data['cultivar']["name"];
            phaseSelectFieldRef.current.value=data['phase'];
          },
          error: function (error) {
            console.error('Error fetching cultivation details:', error);
            toast.error(`${t("cultivations.error.cultivation_details")} ${error.message}`);
            console.error(t("cultivations.error.cultivation_details"), error);
          }
      });
  };

  //Initialize page
  useEffect(() =>{
    const params = new URLSearchParams(window.location.search);
    setCultivationID(params.get('id'));
    if(cultivationID){initForm();}
  },[cultivationID]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() =>{
    setPageTitle("cultivations.view.title");
    setPageTitleIcon("/img/ICON_AGRO_GREEN.png");
  },[])

  useEffect(() => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0
  
    const suggestionRows = [];
    Object.keys(suggestions).forEach((suggestionType) => {
      const suggestionObjects = suggestions[suggestionType];
  
      suggestionObjects.forEach((suggestionObject) => {
        const suggestionDate = suggestionObject['date'];
        if(suggestionDate){
          const date = new Date(suggestionObject['date']);
          date.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0
    
          const daysDifference = Math.floor((date - currentDate) / (24 * 60 * 60 * 1000));
          let id = suggestionObject['id'];
          let textColor = '';
          let textRef = '';
          let text = '';
          let warningColor = '#EA840A';
          let errorColor = '#dc3545';
          let normalColor ='1E1E1E';
          if (daysDifference < -1) {
            textColor = errorColor;
            textRef ="common.suggestion_modal.days_past"; 
            text = t("common.suggestion_modal.days_past")
                  .replace("{{days}}", Math.abs(daysDifference));
          } else if (daysDifference === -1) {
            textColor = errorColor;
            textRef ="common.suggestion_modal.day_past"; 
            text = t("common.suggestion_modal.day_past")
                  .replace("{{days}}", Math.abs(daysDifference));
          } else if (daysDifference === 0) {
            textColor = errorColor;
            textRef ="common.suggestion_modal.today"; 
            text = t("common.suggestion_modal.today");
          } else if (daysDifference === 1) {
            textColor = errorColor;
            textRef ="common.suggestion_modal.day_future"; 
            text = t("common.suggestion_modal.day_future")
                  .replace("{{days}}", Math.abs(daysDifference));
          } else if (daysDifference <= 2) {
            textColor = errorColor;
            textRef ="common.suggestion_modal.days_future"; 
            text = t("common.suggestion_modal.days_future")
                  .replace("{{days}}", Math.abs(daysDifference));
          } else if (daysDifference <= 4) {
            textColor = warningColor;
            text = t("common.suggestion_modal.days_future")
                  .replace("{{days}}", Math.abs(daysDifference));
          } else {
            textColor = normalColor;
            text = t("common.suggestion_modal.days_future")
                  .replace("{{days}}", Math.abs(daysDifference));
          }
    
          suggestionRows.push({
            id,
            suggestionType,
            date,
            textColor,
            text,
            textRef,
            daysDifference
          });
        }else{
          let id = suggestionObject['id'];
          let date = suggestionObject['date'];
          let color = '#EA840A';
          let text = t("common.suggestion_modal.active");
          let textRef = "common.suggestion_modal.active";
          let daysDifference = 0;
          // let text = `Λεπτομέρειες`;
          let status = suggestionObject['status'];
          let RatingOne= [];
          let RatingTwo= [];     
          let RatingThree = [];
          let AllRatings = [];
          suggestionObject.parameters.sort((a, b) => a.id > b.id ? 1 : -1).map((param) => {       
            if(param.rating === 3){
                RatingThree.push(param.type);
              }
            if(param.rating === 2){
                RatingTwo.push(param.type);
              }   
            if(param.rating === 1){
                RatingOne.push(param.type);
              }      
            return null;                          
          });   
         AllRatings = RatingThree.concat(RatingTwo);
         //
          suggestionRows.push({
            id,
            suggestionType,
            date,
            color,
            text,
            status,
            RatingThree,
            RatingTwo,
            AllRatings,
            textRef,
            daysDifference
          });
        }
      });
    });
  
    setSuggestionTableData(suggestionRows);
  }, [suggestions]);

  return (
    <div className="content page1_content">

      <div className="create-field-map" >
          <MapCreateComponent 
            geoFieldRef={geoFieldRef} 
            isClearPressed={isClearPressed} 
            setisClearPressed={setisClearPressed} 
            selectedCultivation={selectedCultivation}/>
      </div>
      <div className="form-background">
        <form>
          <div className="form-container">            
            <div className="form-row">
              <label htmlFor="name" className='label-create'>{t('cultivations.form.name')}</label>
              <input type="text" id="name" name="name" className='create-input'  
                placeholder={t('cultivations.form.placeholder.name')} readOnly />
            </div>
            <div className="form-row">
              <label htmlFor="geom" className='label-create'>{t('cultivations.form.geometry')}</label>
              <input type="text" id="geom" name="geom" 
                placeholder="MULTIPOLYGON (((22.167664 39.302247, 22.223969 39.307562, 
                  22.241821 39.222479, 22.101746 39.242695, 22.167664 39.302247)))"
                ref={geoFieldRef} 
                readOnly
                className='create-input'
              />
            </div>
            <div className="form-row">
              <label htmlFor="cultivar" className='label-create'>{t('cultivations.form.variety')}</label>
              <input 
                className='create-input' 
                id="cultivar" 
                type="text"
                readOnly
                name="cultivar" 
                placeholder={t('cultivations.form.placeholder.variety')}
                ref={cultivarSelectFieldRef} 
              />
            </div>
            <div className="form-row">
              <label htmlFor="phase" className='label-create'>{t('cultivations.form.phase')}</label>
              <input  
                className='create-input' 
                id="phase" 
                type="text"
                readOnly
                name="phase"  
                placeholder={t('cultivations.form.placeholder.phase')}
                ref={phaseSelectFieldRef} 
              />
            </div>
            <div className="form-row">
              <label htmlFor="planting_date" className='label-create'>{t('cultivations.form.seed_date')}</label>
              <input type="datetime-local" id="planting_date" name="planting_date" 
                  className='create-input' readOnly
              />
            </div>
            <div className="form-row">
              <label className='label-create'>{t('cultivations.form.actions')}</label>
              <div 
                className="actions-arrow" 
                onClick={() => {setIsAnalysisPopupVisible(false);setIsActionsPopupVisible(!isActionsPopupVisible);}}
              />
            </div>
            <div className="form-row">
              <label className='label-create'>{t('cultivations.form.leaf_analysis')}</label>
              <div 
                className="actions-arrow" 
                onClick={() => {setIsActionsPopupVisible(false);setIsAnalysisPopupVisible(!isAnalysisPopupVisible);}}
              />
            </div>
          </div>
        </form>
        {/* Actions Popup Table */}
        {isActionsPopupVisible && (
        <div className={`${actionList.length === 0 ? 'actions-popup-no-actions' : "actions-popup"}`}>
            {actionList.length === 0 ? (
            <p>{t('cultivations.form.no_actions')}</p>
            ) :(
            <table className="actions-table">
              <thead>
                <tr>
                  <th></th>
                  <th>{t('cultivations.form.action_type')}</th>
                  <th>{t('cultivations.form.date')}</th>
                </tr>
              </thead>
              <tbody>
                {actionList.map((action, index) => (
                  <tr                  
                    key={action.cultivation_id} 
                    id={`row-${action.cultivation_id}`}
                    className={"action_row"}
                  >
                    <td>
                        <img
                          onMouseEnter={() => handleMouseEnterEdit(index)}
                          onMouseLeave={() => handleMouseLeaveEdit(index)}
                          src={isHoveredEdit[index] ? hoverEdit : originalEdit}
                          alt="Edit"
                          className={`tiny-button ${isHoveredEdit[index] ? 'hovered' : ''}`}
                          onClick={() => handleEditClick(index)} 
                        />
                        <img
                          onMouseEnter={() => handleMouseEnterView(index)}
                          onMouseLeave={() => handleMouseLeaveView(index)}
                          src={isHoveredView[index] ? hoverView : originalView}
                          alt="View"
                          className={`tiny-button ${isHoveredView[index] ? 'hovered' : ''}`}
                          onClick={() => handleViewClick(index)} 
                        /> 
                        <img
                          onMouseEnter={() => handleMouseEnterDelete(index)}
                          onMouseLeave={() => handleMouseLeaveDelete(index)}
                          src={isHoveredDelete[index] ? hoverDelete : originaLDelete}
                          alt="Delete"
                          className="tiny-button"
                          onClick={() => handleDeleteClick(index)} 
                        />
                    </td>
                    <td>{action.type}</td>
                    <td>{action.date}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        )}
        {/* Analysis Popup Table */}
        {isAnalysisPopupVisible && (
        <div className={`${analysisList.length === 0 ? 'analysis-popup-no-analysis' : "analysis-popup"}`}>
            {analysisList.length === 0 ? (
            <p>{t('cultivations.form.no_leaf_analysis')}</p>
            ) :(
            <table className="analysis-table">
              <thead>
                <tr>
                  <th></th>
                  <th>{t('cultivations.form.id')}</th>
                  <th>{t('cultivations.form.date')}</th>
                </tr>
              </thead>
              <tbody>
                {analysisList.map((analysis, index) => (
                  <tr                  
                    key={analysis.id}
                    id={`row-${analysis.id}`}
                    className={"analysis_row"}>
                    <td>
                      <Link style={{textDecoration:"none"}} className='links' to={"/edit-leaf-analysis/?id="+analysis.id}>
                        <img
                          onMouseEnter={() => handleMouseEnterEdit(index)}
                          onMouseLeave={() => handleMouseLeaveEdit(index)}
                          src={isHoveredEdit[index] ? hoverEdit : originalEdit}
                          alt="Edit"
                          className={`tiny-button ${isHoveredEdit[index] ? 'hovered' : ''}`}
                        />
                      </Link> 
                      <Link style={{textDecoration:"none"}} className='links' to={"/view-leaf-analysis/?id="+analysis.id}>
                        <img
                          onMouseEnter={() => handleMouseEnterView(index)}
                          onMouseLeave={() => handleMouseLeaveView(index)}
                          src={isHoveredView[index] ? hoverView : originalView}
                          alt="View"
                          className={`tiny-button ${isHoveredView[index] ? 'hovered' : ''}`}
                      />
                      </Link> 
                      <img
                        onMouseEnter={() => handleMouseEnterDelete(index)}
                        onMouseLeave={() => handleMouseLeaveDelete(index)}
                        src={isHoveredDelete[index] ? hoverDelete : originaLDelete}
                        alt="Delete"
                        className="tiny-button"
                        onClick={() => handleDeleteLeafAnalysisClick(analysis)} 
                      />
                    </td>
                    <td>{analysis.id}</td>
                    <td>{analysis.date}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          </div>
        )}
      </div>
      {/* Suggestion Table */}
      {suggestionTableData.length > 0 && (
      <div className={suggestionTableData.some(row => row.suggestionType === 'άρδευση') && (suggestionTableData.some(row => row.suggestionType === 'λίπανση') || suggestionTableData.some(row => row.suggestionType === 'φροντίδα καλλιέργειας')) ? 'suggestion-table-full' : 'suggestion-table'}>
        <h2 style={{textAlign: "center"}}>
          {t("common.suggestion_modal.active")}
        </h2>
        <table>
          <thead>
            <tr>
              {/* <td>{suggestionTableData.some(row => row.suggestionType === 'άρδευση') && <th>Αρδευση</th>}</td>
              <td>{suggestionTableData.some(row => row.suggestionType === 'λίπανση') && <th>Λίπανση</th>}</td>   */}
              <td>{<th style={{textAlign: "center"}}>{t("common.suggestion_modal.άρδευση")}</th>}</td>
              <td>{<th style={{textAlign: "center"}}>{t("common.suggestion_modal.λίπανση")}</th>}</td>
              <td>{<th style={{textAlign: "center"}}>{t("common.suggestion_modal.φροντίδα_καλλιέργειας")}</th>}</td>   
            </tr>
            {/* <tr>
            </tr>             */}
          </thead>
          <tbody>
            <tr>
              {/* {suggestionTableData.some(row => row.suggestionType === 'άρδευση') && ( */}
              { (  
                <td>
                  {suggestionTableData.some(row => row.suggestionType === 'άρδευση') && suggestionTableData
                    .filter(row => row.suggestionType === 'άρδευση').sort((a,b) => b.date - a.date).slice(0,1)
                    .map((row, index) => (
                      <div key={index} style={{ color: row.color, textAlign: "left" }}>
                        <div >
                        {`${row.date.toLocaleDateString()}`}
                        </div>
                        <div>
                        {`${t(row.textRef).replace("{{days}}", Math.abs(row.daysDifference))}`}
                      </div>
                      </div>
                    ))}
                </td>
              )}
              {/* {suggestionTableData.some(row => row.suggestionType === 'λίπανση') && ( */}
              { (    
                <td>
                  {suggestionTableData.some(row => row.suggestionType === 'λίπανση') && suggestionTableData
                    .filter(row => row.suggestionType === 'λίπανση')
                    .map((row, index) => (
                      <div  key={index} style={{ color: row.color, textAlign: "left" }}>
                        <div >
                           {row.AllRatings.slice(0, Math.min(3,row.RatingThree.length)).map((parameter) =>
                           <li style={{ color: '#dc3545' }}>{parameter}</li>)}      
                           {row.AllRatings.slice(Math.min(3,row.RatingThree.length), Math.min(3,row.AllRatings.length)).map((parameter) =>
                           <li style={{ color: '#EA840A' }}>{parameter}</li>)}           
                        </div>                        
                        <div>                 
                         {actionList.slice(0, 1).map((action, index) =>                                                  
                        <Link style={{color: "#1E1E1E"}} className='links' to={"/view-fertilization-suggestion/?id="+row.id}>{t("common.suggestion_modal.details")}</Link>
                         )}
                      </div>
                      </div>
                    ))}
                </td>
              )}
              { (    
                <td>
                  {suggestionTableData.some(row => row.suggestionType === 'φροντίδα καλλιέργειας') && suggestionTableData
                    .filter(row => row.suggestionType === 'φροντίδα καλλιέργειας')
                    .map((row, index) => (
                      <div  key={index} style={{ color: row.color, textAlign: "left" }}>
                        <div >
                           {row.AllRatings.slice(0, Math.min(3,row.RatingThree.length)).map((parameter) =>
                           <li style={{ color: '#dc3545' }}>{parameter}</li>)}      
                           {row.AllRatings.slice(Math.min(3,row.RatingThree.length), 3).map((parameter) =>
                           <li style={{ color: '#EA840A' }}>{parameter}</li>)}               
                        </div>                        
                        <div>                 
                         {actionList.slice(0, 1).map((action, index) =>                                                  
                        <Link style={{color: "#1E1E1E"}} className='links' to={"/view-leaf-suggestion/?id="+row.id}>{t("common.suggestion_modal.details")}</Link>
                         )}
                      </div>
                      </div>
                    ))}
                </td>
              )}
            </tr>
          </tbody>
        </table>
      </div>
      )}
       {/* ToastContainer for displaying notifications */}
      <ToastContainer
        position="bottom-right"
        autoClose={7000} // Close the notification after 7 seconds
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {showDeleteConfirmation && (
        <DeleteConfirmationModal
          onCancel={() => setShowDeleteConfirmation(false)}
          onConfirm={() => handleConfirmDelete()}
        />
      )}
    </div>
    
  );
};

export default ViewCultivations;
