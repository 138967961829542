import React, { useEffect,useRef,useState } from 'react';
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, FeatureGroup, ZoomControl, GeoJSON } from 'react-leaflet';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import {EditControl} from 'react-leaflet-draw';
import { useMap } from 'react-leaflet/hooks';
import WKT from 'terraformer-wkt-parser';
import Wkt from 'wicket';
import L from 'leaflet';
import $ from "jquery"; 
import { useCookies } from 'react-cookie';
import getServerURL from "./Configuration.js";
import i18n from 'i18next';

const serverUrl = getServerURL();

const clearDrawnLayers = (layergroup) => {
        layergroup.eachLayer(layer => {
            layergroup.removeLayer(layer);
        });
}

function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

function createFeature(geom,colour,label,id){
    let wkt = new Wkt.Wkt();
    if (geom.includes(';')) {
        geom = geom.split(';')[1];
    }
    wkt.read(geom);
    return { "type": "Feature", 'properties': { "name": label, "id": id,"colour": colour}, "geometry": wkt.toJson() };
}

const PredrawnLayer = ({selectedField, selectedCultivation, isRedrawPressed}) =>{
    const [predrawnGeom,setPredrawnGeom] = useState();
    const [cookies] = useCookies(['csrftoken']);
    const map = useMap();

    useEffect(()=>{
        if(selectedField && (!selectedCultivation)){
            $.ajax({
                url: serverUrl+"/fields/" + selectedField + '/',
                type: 'get',
                headers: {
                    "Accept-Language": i18n.language || 'el',
                    "X-CSRFToken": cookies.csrftoken,
                    "Authorization": cookies.auth
                },
                xhrFields: {
                    withCredentials: true,
                    xsrfCookieName: 'csrftoken',
                    xsrfHeaderName: 'X-CSRFToken'
                },
                crossDomain: true,
                dataType: 'json',
                success: function (field_data) {
                    let name = field_data["name"];
                    if (name === 'undefined') {name = field_data["namegr"];}
                    if (field_data['cultivations']?.length > 0){
                        name = "";
                    }
                    let feature = createFeature(field_data['geom'],"#128068",name,field_data['id']);
                    let predrawnItems = new L.FeatureGroup();
                    let layer = L.geoJSON(feature, {});
                    predrawnItems.addLayer(layer);
                    for(let cult of field_data['cultivations']){
                        let cult_feature = createFeature(cult['geom'],getRandomColor(),cult['name'],cult['id']);
                        predrawnItems.addLayer(L.geoJSON(cult_feature, {}));
                    }
                    setPredrawnGeom(predrawnItems.toGeoJSON());
                    for(let mapLayerIndex in map._layers){
                        let mapLayer = map._layers[mapLayerIndex];
                        if(mapLayer?.options?.icon?.options?.className === "label"){
                            map.removeLayer(mapLayer);
                        }
                    };
                    map.invalidateSize();
                    map.fitBounds(predrawnItems.getBounds());
                },
                error: function (error) {
                    console.error(error);
                }
            });
        }
    },[selectedField,isRedrawPressed]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        if(selectedCultivation && (!selectedField)){
            $.ajax({
                url: serverUrl+"/cultivations/" + selectedCultivation + '/',
                type: 'get',
                headers: {
                    "Accept-Language": i18n.language || 'el',
                    "X-CSRFToken": cookies.csrftoken,
                    "Authorization": cookies.auth
                },
                xhrFields: {
                    withCredentials: true,
                    xsrfCookieName: 'csrftoken',
                    xsrfHeaderName: 'X-CSRFToken'
                },
                crossDomain: true,
                dataType: 'json',
                success: function (field_data) {
                    let name = field_data["name"];
                    if (name === 'undefined') {name = field_data["namegr"];}
                    if (field_data['cultivations']?.length > 0){
                        name = "";
                    }
                    let feature = createFeature(field_data['geom'],"#128068",name,field_data['id']);
                    let predrawnItems = new L.FeatureGroup();
                    let layer = L.geoJSON(feature, {});
                    predrawnItems.addLayer(layer);
                    setPredrawnGeom(predrawnItems.toGeoJSON());
                    for(let mapLayerIndex in map._layers){
                        let mapLayer = map._layers[mapLayerIndex];
                        if(mapLayer?.options?.icon?.options?.className === "label"){
                            map.removeLayer(mapLayer);
                        }
                    };
                    map.invalidateSize();
                    map.fitBounds(predrawnItems.getBounds());
                },
                error: function (error) {
                    console.error(error);
                }
            });
        }
    },[selectedCultivation,isRedrawPressed]); // eslint-disable-line react-hooks/exhaustive-deps

    if(predrawnGeom){
        return (
        <GeoJSON 
            data={predrawnGeom} 
            key={getRandomColor()}
            style={function (x) {
                return { 
                    fillColor: x.properties.colour, 
                    weight: 1, 
                    opacity: 1, 
                    color: 'black', 
                    fillOpacity: 0.6 
                };
            }}
            onEachFeature={function (feature, layer) {
                L.marker(layer.getBounds().getCenter(), {
                    icon: L.divIcon({
                        className: 'label',
                        html: '<span style="width:100%;text-align:center;color:white;font-weight:bold;display:block;">'+feature.properties.name+'</span>',
                        iconSize: [120, 40]
                    })
                }).addTo(map);
            }} />);
    }
    else{
        return null;
    }
};

const MapData =  ({geoFieldRef, isClearPressed, setisClearPressed, readOnly}) =>{
    const map = useMap();
    const drawnLayersRef = useRef();

    const fitBoundsToDrawnLayers = () => {
        if (drawnLayersRef.current.getLayers().length > 0) {
          const bounds = drawnLayersRef.current.getBounds();
          map.fitBounds(bounds);
        }
      };

    const _onCreate = (e) => { 
        if(!readOnly){       
            let layer = e.layer;
            drawnLayersRef.current.addLayer(layer);
            let geoms = [];
            drawnLayersRef.current.eachLayer(layer => {
                let geoJson = layer.toGeoJSON()["geometry"];
                if(geoJson.type.toLowerCase() === "polygon"){
                    geoms.push(geoJson["coordinates"]);
                }
            })
            let geojson = {"type":"MultiPolygon","coordinates":geoms};
            let wkt = WKT.convert(geojson);
            if(wkt === "MULTIPOLYGON EMPTY"){
                wkt = "";
            }
            geoFieldRef.current.value = wkt;
            geoFieldRef.current.dispatchEvent(new Event('change'));
            fitBoundsToDrawnLayers();
        }else{
            console.error("Tried to change readonly map!");
        }
    };

    const _onDeleted = () => {
        if(!readOnly){ 
            let geoms = [];
            drawnLayersRef.current.eachLayer(layer => {
                let geoJson = layer.toGeoJSON()["geometry"];
                if(geoJson.type.toLowerCase() === "polygon"){
                    geoms.push(geoJson["coordinates"]);
                }
            })
            let geojson = {"type":"MultiPolygon","coordinates":geoms};
            let wkt = WKT.convert(geojson);
            if(wkt === "MULTIPOLYGON EMPTY"){
                wkt = "";
            }
            geoFieldRef.current.value = wkt;
            geoFieldRef.current.dispatchEvent(new Event('change'));
        }else{
            console.error("Tried to change readonly map!");
        }
    }

    const _onEdit = () => {
        if(!readOnly){
            let geoms = [];
            drawnLayersRef.current.eachLayer(layer => {
                let geoJson = layer.toGeoJSON()["geometry"];
                if(geoJson.type.toLowerCase() === "polygon"){
                    geoms.push(geoJson["coordinates"]);
                }
            })
            let geojson = {"type":"MultiPolygon","coordinates":geoms};
            let wkt = WKT.convert(geojson);
            if(wkt === "MULTIPOLYGON EMPTY"){
                wkt = "";
            }
            geoFieldRef.current.value = wkt;
            geoFieldRef.current.dispatchEvent(new Event('change'));
        }else{
            console.error("Tried to change readonly map!");
        }
    }

    useEffect(() => {
        if(map && geoFieldRef?.current?.value){
            clearDrawnLayers(drawnLayersRef.current);
            try{
                let geoJson = WKT.parse(geoFieldRef.current?.value);
                let polygons = [];
                if(geoJson.type.toLowerCase() === "multipolygon"){
                    geoJson.coordinates.forEach(function(coords){
                        let feat={'type':'Polygon','coordinates':coords};
                        polygons.push(feat);
                    });
                }else if(geoJson.type.toLowerCase() === "polygon"){
                    polygons.push(geoJson);
                }
                polygons.forEach(function(feat){
                    var feature = { "type": "Feature", "geometry": feat };
                    let newFeatureGroup = L.geoJSON(feature);
                    newFeatureGroup.eachLayer(layer => {
                        drawnLayersRef.current.addLayer(layer);
                    });
                });
            }catch(ex){}
            fitBoundsToDrawnLayers();
        } else if (map && !geoFieldRef?.current?.value){
            clearDrawnLayers(drawnLayersRef.current);
        }
    }, [geoFieldRef?.current?.value]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isClearPressed){
            clearDrawnLayers(drawnLayersRef.current);
            setisClearPressed(false)
            }
    }, [isClearPressed]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <FeatureGroup ref={drawnLayersRef} >
            <EditControl 
                position='topright'
                onEdited={_onEdit}
                onCreated={_onCreate}
                onDeleted={_onDeleted}
                draw={{rectangle: false}}
                edit={{}} >
            </EditControl>
        </FeatureGroup>
    );
}

function SearchBar() {
    const map = useMap(); // Access the map instance
    const searchControlRef = useRef(null); // Ref to store the search control instance
    const markerRef = useRef(null); // Ref to store the search result marker
  
    useEffect(() => {
      const provider = new OpenStreetMapProvider();
  
      // Function to clear the previous marker
      const clearPreviousMarker = () => {
        if (markerRef.current) {
          map.removeLayer(markerRef.current); // Remove the marker if it exists
          markerRef.current = null;
        }
      };
  
      // Set up the GeoSearchControl
      searchControlRef.current = new GeoSearchControl({
        provider: provider,
        showMarker: false, // Disable default markers
        showPopup: false,
        autoClose: true,
        retainZoomLevel: false,
        animateZoom: true,
        keepResult: false,
        updateMap: true,
        position: 'bottomright',
      });
  
      // Add the search control to the map
      map.addControl(searchControlRef.current);
  
      // Listen for the result event to place a custom marker
      map.on('geosearch/showlocation', (result) => {
        clearPreviousMarker(); // Clear any existing marker
  
        // Define a custom DivIcon
        const customIcon = L.divIcon({
          className: 'custom-marker', // Class for styling
          html: `<div style="width: 20px; height: 20px; background-color: red; border-radius: 50%; border: 2px solid black;"></div>`,
          iconSize: [20, 20], // Set the size of your marker
        });
  
        // Add the custom marker to the map
        markerRef.current = L.marker([result.location.y, result.location.x], {
          icon: customIcon,
        }).addTo(map);
      });
  
      return () => {
        // Clean up on component unmount
        clearPreviousMarker(); // Remove any marker
        if (searchControlRef.current) {
          map.removeControl(searchControlRef.current); // Remove the search control
        }
      };
    }, [map]);
  
    return null;
}

function MapCreateComponent({geoFieldRef, isClearPressed, setisClearPressed, selectedField, selectedCultivation, readOnly, isRedrawPressed}) {

    return (
        <MapContainer
          center={[37.95, 23.79]} // Set your initial map center
          zoom={6} // Set your initial zoom level
          style={{ height: '100%'}}
          zoomControl={false}
        >
          <TileLayer
            attribution="Google Maps"
            url="https://www.google.cn/maps/vt?lyrs=s,h&gl=cn&x={x}&y={y}&z={z}"
          />
          <PredrawnLayer selectedField={selectedField} selectedCultivation={selectedCultivation} isRedrawPressed={isRedrawPressed} />
          <ZoomControl position='bottomright' />
          <SearchBar />
          <MapData 
            geoFieldRef={geoFieldRef} 
            isClearPressed={isClearPressed} 
            setisClearPressed={setisClearPressed}
            readOnly={readOnly}
          />
        </MapContainer>
      );
}

export default MapCreateComponent;