// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Footer Styles */
.footer {
    background-color: #fff;
    position: flex; /* Change to absolute */
    bottom: 0;
    z-index: 1;
    padding: 20px 20px;
    /* Adjust the following properties for responsiveness */
    display: flex;
    justify-content: space-between;
    align-items: center;
    font: 1em ;
    width: 100%;
    flex-shrink: 0;
    justify-content: space-between;
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide part of text if overflow */
    text-overflow: ellipsis; /* Add an ellipsis for overflow text */
    font-size: 0.7rem; /* Adjust font size as needed */
    transition: all 0.3s; /* Add a smooth transition effect */
  }

  .copyright-wrapper {
    position: relative; /* Create a positioning context for the copyright text */
  }

  .copyright {
    font-family : Manrope;
    font-size : 0.7rem;
    color : #1E1E1E;
    /* color : rgb(244, 242, 242); */
    opacity : 0.81; /* Adjust the opacity as needed (0.3 is almost invisible) */
    filter: alpha(opacity=81) progid:DXImageTransform.Microsoft.Alpha(opacity=81) ;

    position: absolute; /* Position relative to the copyright-wrapper */
    left: 18.5vw;
    /* right: 10px; */
    top: 50%;
    transform: translateY(-50%); /* Center vertically */
  }
`, "",{"version":3,"sources":["webpack://./src/CSSCustom/Footer.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;IACI,sBAAsB;IACtB,cAAc,EAAE,uBAAuB;IACvC,SAAS;IACT,UAAU;IACV,kBAAkB;IAClB,uDAAuD;IACvD,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,UAAU;IACV,WAAW;IACX,cAAc;IACd,8BAA8B;IAC9B,mBAAmB,EAAE,+BAA+B;IACpD,gBAAgB,EAAE,kCAAkC;IACpD,uBAAuB,EAAE,sCAAsC;IAC/D,iBAAiB,EAAE,+BAA+B;IAClD,oBAAoB,EAAE,mCAAmC;EAC3D;;EAEA;IACE,kBAAkB,EAAE,wDAAwD;EAC9E;;EAEA;IACE,qBAAqB;IACrB,kBAAkB;IAClB,eAAe;IACf,gCAAgC;IAChC,cAAc,EAAE,2DAA2D;IAC3E,8EAA8E;;IAE9E,kBAAkB,EAAE,+CAA+C;IACnE,YAAY;IACZ,iBAAiB;IACjB,QAAQ;IACR,2BAA2B,EAAE,sBAAsB;EACrD","sourcesContent":["/* Footer Styles */\n.footer {\n    background-color: #fff;\n    position: flex; /* Change to absolute */\n    bottom: 0;\n    z-index: 1;\n    padding: 20px 20px;\n    /* Adjust the following properties for responsiveness */\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    font: 1em ;\n    width: 100%;\n    flex-shrink: 0;\n    justify-content: space-between;\n    white-space: nowrap; /* Prevent text from wrapping */\n    overflow: hidden; /* Hide part of text if overflow */\n    text-overflow: ellipsis; /* Add an ellipsis for overflow text */\n    font-size: 0.7rem; /* Adjust font size as needed */\n    transition: all 0.3s; /* Add a smooth transition effect */\n  }\n\n  .copyright-wrapper {\n    position: relative; /* Create a positioning context for the copyright text */\n  }\n\n  .copyright {\n    font-family : Manrope;\n    font-size : 0.7rem;\n    color : #1E1E1E;\n    /* color : rgb(244, 242, 242); */\n    opacity : 0.81; /* Adjust the opacity as needed (0.3 is almost invisible) */\n    filter: alpha(opacity=81) progid:DXImageTransform.Microsoft.Alpha(opacity=81) ;\n\n    position: absolute; /* Position relative to the copyright-wrapper */\n    left: 18.5vw;\n    /* right: 10px; */\n    top: 50%;\n    transform: translateY(-50%); /* Center vertically */\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
