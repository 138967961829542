import './App.css';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import { useState } from 'react';
import {BrowserRouter, Navigate , Route , Routes } from "react-router-dom"
import { useCookies } from 'react-cookie';

import Footer  from "./Footer";
import Header from "./Header";
import LoginPage from './LoginPage';
import SignUpPage from './SignUpPage';
import Sidebar from "./Sidebar";
import FieldsTable from './FieldsTable';
import CreateFields from './CreateFields';
import EditCultivations from './EditCultivations';
import ViewCultivations from './ViewCultivations';
import ViewSoilAnalysis from './ViewSoilAnalysis';
import ViewLeafAnalysis from './ViewLeafAnalysis';
import EditSoilAnalysis from './EditSoilAnalysis';
import EditLeafAnalysis from './EditLeafAnalysis';
import ViewFertilizationSuggestions from './ViewFertilizationSuggestions';
import ViewLeafSuggestions from './ViewLeafSuggestions';
import CreateCultivationData from './CreateCultivationData';
import EditFields from './EditFields';
import ViewFields from './ViewFields';
import CreateFieldData from './CreateFieldData';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import TableAgroCalendar from './ViewAgroCalendar';
import FieldsData from './FieldsData';
import {PageTitleProvider} from './JS_Context/ContextTitle'

library.add(faEye, faEyeSlash);

function App() {
  const [username, setUsername] = useState('');
  const [cookies] = useCookies(['auth']); 

  return (
    <div className="App">
      <PageTitleProvider>
        <BrowserRouter>
          {cookies.auth && <Header setUsername={setUsername}/>}
          {cookies.auth && <Sidebar />}
          <Routes>
            <Route path="/"
                element={!cookies.auth ?<Navigate to="/login" /> : <Navigate to="/fields" />}>
            </Route>
            <Route path="/login" 
              element={!cookies.auth ? <LoginPage setUsername={setUsername} username={username}/> : null}>
            </Route>
            <Route exact path = "/signup" 
              element={!cookies.auth ? <SignUpPage setUsername={setUsername} username={username}/> : null }>
            </Route>
            <Route exact path = "/fields" 
              element={cookies.auth ? <FieldsTable /> :<Navigate to="/login" /> }>
            </Route>
            <Route exact path = "/create-fields" 
              element={cookies.auth ? <CreateFields /> :<Navigate to="/login" /> }>
            </Route>
            <Route exact path = "/edit-field" 
              element={cookies.auth ? <EditFields /> :<Navigate to="/login" /> }>
            </Route>
            <Route exact path = "/view-field" 
              element={cookies.auth ? <ViewFields /> :<Navigate to="/login" /> }>
            </Route>
            <Route path = "/fields-data" 
              element={cookies.auth ? <FieldsData /> : <Navigate to="/login" /> }>
            </Route>
            <Route path = "/agrocalendar" 
              element={cookies.auth ? <TableAgroCalendar /> : <Navigate to="/login" /> }>
            </Route>
            <Route exact path = "/add-to-field/:selectedField" 
              element={cookies.auth ? <CreateFieldData /> :<Navigate to="/login" /> }>
            </Route>
            <Route exact path = "/edit-cultivation" 
              element={cookies.auth ? <EditCultivations /> :<Navigate to="/login" /> }>
            </Route>
            <Route exact path = "/view-cultivation" 
              element={cookies.auth ? <ViewCultivations /> :<Navigate to="/login" /> }>
            </Route>
            <Route exact path = "/add-to-cultivation/:selectedCultivation" 
              element={cookies.auth ? <CreateCultivationData /> :<Navigate to="/login" /> }>
            </Route>
            <Route exact path = "/view-soil-analysis" 
              element={cookies.auth ? <ViewSoilAnalysis /> :<Navigate to="/login" /> }>
            </Route>
            <Route exact path = "/edit-soil-analysis" 
              element={cookies.auth ? <EditSoilAnalysis /> :<Navigate to="/login" /> }>
            </Route>
            <Route exact path = "/view-leaf-analysis" 
              element={cookies.auth ? <ViewLeafAnalysis /> :<Navigate to="/login" /> }>
            </Route>
            <Route exact path = "/edit-leaf-analysis" 
              element={cookies.auth ? <EditLeafAnalysis /> :<Navigate to="/login" /> }>
            </Route>
            <Route exact path = "/view-fertilization-suggestion" 
              element={cookies.auth ? <ViewFertilizationSuggestions /> :<Navigate to="/login" /> }>
            </Route>
            <Route exact path = "/view-leaf-suggestion" 
              element={cookies.auth ? <ViewLeafSuggestions /> :<Navigate to="/login" /> }>
            </Route>

          </Routes>
          {cookies.auth &&<Footer/>}
        </BrowserRouter>
      </PageTitleProvider>
    </div>);
}

export default App;
