import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import Tooltip from 'react-tooltip';
import * as yup from 'yup';
// TODO: Fix validation and fix submit to correct JSON format for parameter array.
import MapCreateComponent from './MapCreateComponent';
import { usePageTitle } from './JS_Context/ContextTitle';
import { loadSwaggerEnum, loadModelEnum } from './Utils.js';

import $ from "jquery"; 
import { useCookies } from 'react-cookie';
import getServerURL from "./Configuration.js";
import originalAccept from './img/ICON_ACCEPT_BLACK.png';
import hoverAccept from './img/ICON_ACCEPT_ORANGE.png';
import originalDecline from './img/ICON_DECLINE_BLACK.png';
import hoverDecline from './img/ICON_DECLINE_ORANGE.png';
import originalEdit from './img/ICON_EDIT_LEAF_BLACK.png';
import hoverEdit from './img/ICON_EDIT_LEAF_ORANGE.png';
//import originalFileImport from './img/ICON_IMPORT_BLACK.png';
//import hoverFileImport from './img/ICON_IMPORT_ORANGE.png';
import originalPolygon from './img/ICON_POLUGON.png';
import hoverPolygon from './img/ICON_POLUGON_ORANGE.png';
import originalTrash from './img/ICON_TRASH_BLACK.png';
import hoverTrash from './img/ICON_TRASH_ORANGE.png';
import Wkt from 'wicket';

import 'leaflet/dist/leaflet.css';
import './CSSCustom/fieldForms.css';

import { toast, ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';

const serverUrl = getServerURL();

function CreateFieldData() {
  const [isHoveredPolygon, setIsHoveredPolygon] = useState(false);
  const [isHoveredEdit, setIsHoveredEdit] = useState(false);
  const [isHoveredTrash, setIsHoveredTrash] = useState(false);
  //const [isHoveredFileImport, setIsHoveredFileImport] = useState(false);
  const [isHoveredAccept, setIsHoveredAccept] = useState(false);
  const [isHoveredDecline, setIsHoveredDecline] = useState(false);
  const [isClearPressed, setisClearPressed] = useState(false);
  const [isRedrawPressed, setisRedrawPressed] = useState(false);
  const [actionForm, setActionForm] = useState();
  const { t,i18n } = useTranslation();
  const { setPageTitle, setPageTitleIcon } = usePageTitle();
  const [cookies] = useCookies(['csrftoken']);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [parameterOptions, setParameterOptions] = useState([]);
  const [parameterLabelOptions, setParameterLabelOptions] = useState([]);
  const {selectedField} = useParams();
  const geoFieldRef = useRef();
  const [actionOptions, setActionOptions] = useState([
    { value: "cultivations", label: t('cultivations.table.title') },
    { value: "soil-analysis", label: t('analysis.soil.table.title') }
  ]);
  const [actionSelected, setActionSelected] = useState(actionOptions[0]);
  //const lang = localStorage.getItem("i18nextLng");
  //const [langOld, setLangOld] = useState(lang);
  //TODO: Fix that to use reactive logic
  //useEffect(() => {
  //  if(lang != langOld){
  //    setLangOld(lang);
  //    window.location.reload(); 
  //  }
  //}, [lang]);

  // Polygon icon hover handle
  const handleMouseEnterPolygon = () => {
    setIsHoveredPolygon(true);
  };

  const handleMouseLeavePolygon = () => {
    setIsHoveredPolygon(false);
    // Tooltip.hide() //to work need to remove ReactStrictMode from App.js
  };

  const handlePolygonClick = () => {
    let e = document.createEvent('Event');
    e.initEvent('click', true, true);
    let cb = document.getElementsByClassName('leaflet-draw-draw-polygon');
    return !cb[0].dispatchEvent(e);
  };

  // Edit icon hover handle
  const handleMouseEnterEdit = () => {
    setIsHoveredEdit(true);
  };

  const handleMouseLeaveEdit = () => {
    setIsHoveredEdit(false);
  };

  const handleEditClick = () => {
    let e = document.createEvent('Event');
    e.initEvent('click', true, true);
    let cb = document.getElementsByClassName('leaflet-draw-edit-edit');
    return !cb[0].dispatchEvent(e);
  };

  // File Import icon hover handle
  /*const handleMouseEnterFileImport = () => {
    setIsHoveredFileImport(true);
  };

  const handleMouseLeaveFileImport = () => {
    setIsHoveredFileImport(false);
  };

  const handleFileImportClick = () => {
  };*/

  // Trash icon hover handle
  const handleMouseEnterTrash = () => {
    setIsHoveredTrash(true);
  };

  const handleMouseLeaveTrash = () => {
    setIsHoveredTrash(false);
  };

  const handleTrashClick = () => {
    let e = document.createEvent('Event');
    e.initEvent('click', true, true);
    let cb = document.getElementsByClassName('leaflet-draw-edit-remove');
    return !cb[0].dispatchEvent(e);
  };

  // Accept icon hover handle
  const handleMouseEnterAccept = () => {
    setIsHoveredAccept(true);
  };

  const handleMouseLeaveAccept = () => {
    setIsHoveredAccept(false);
  };
  
  // Decline icon hover handle
  const handleMouseEnterDecline = () => {
    setIsHoveredDecline(true);
  };

  const handleMouseLeaveDecline = () => {
    setIsHoveredDecline(false);
  };

  useEffect(() =>{
    let trans_file = actionSelected.value;
    if(trans_file.includes("-")){
      let splits = trans_file.split("-");
      trans_file = splits[1]+"."+splits[0];
    }
    setPageTitle(trans_file+".create.title");
    setPageTitleIcon("/img/ICON_AGRO_GREEN.png");
  },[actionSelected])

  useEffect(() => {
    const updatedOptions = [
      { value: "cultivations", label: t('cultivations.table.title') },
      { value: "soil-analysis", label: t('analysis.soil.table.title') }
    ];
    setActionOptions(updatedOptions);
    const currentActionOption = updatedOptions.find(actionOption => actionOption.value === actionSelected.value);
    if (currentActionOption) {
      handleActionTypeChange(currentActionOption);
    }
  }, [i18n.language, actionSelected.value]);

  //Set geo value of the Form based on current geo Reference
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
      if (geoFieldRef.current) {
        setFormData((prevFormData) => ({
            ...prevFormData,
            geom: geoFieldRef.current.value,
        }));
    }
  }, [geoFieldRef.current?.value]);

  useEffect(() => {
    setActionForm(CultivationForm);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function loadParameterTypeOptions(){
    return loadSwaggerEnum({endpoint:'/soil-analysis/',attribute:'parameters.items.properties.type',cookies});
  };

  function loadParameterLabelOptions(){
    return loadSwaggerEnum({endpoint:'/soil-analysis/',attribute:'parameters.items.properties.label',cookies});
  };

  function loadPhaseOptions(){
    return loadSwaggerEnum({endpoint:'/cultivations/',attribute:'phase',cookies});
  };

  function loadCultivarOptions(){
    return loadModelEnum({endpoint:'/cultivars/',value:'id',label:'name',attribute:'cultivar',cookies});
  };

  useLayoutEffect(() => {
    loadParameterTypeOptions().then((result) => {
      setParameterOptions(result);
    });
    loadParameterLabelOptions().then((result) => {
      setParameterLabelOptions(result);
    });
  }, [i18n.language]); // eslint-disable-line react-hooks/exhaustive-deps

  // Validate geom field when geoFieldRef.current.value changes
  useEffect(() => {
    if (geoFieldRef.current?.value !== undefined) {
      validationSchema
        .validateAt('geom', { geom: geoFieldRef.current?.value })
        .then(() => {
          // No validation errors for geom field, clear the error for geom field
          setErrors((prevErrors) => ({ ...prevErrors, geom: undefined }));
        })
        .catch((error) => {
          // Validation error occurred, set the error for geom field
          setErrors((prevErrors) => ({ ...prevErrors, geom: error.message }));
        });
        geoFieldRef.current.dispatchEvent(new Event('input'));
    }
  }, [geoFieldRef.current?.value]); // eslint-disable-line react-hooks/exhaustive-deps
  

  // Form Data
  const validateGeom = (value) => {
    const wkt = new Wkt.Wkt();

    try {
      wkt.read(value);
      const geometryType = wkt.type;

      if (!(geometryType === 'polygon' || geometryType === 'multipolygon')) {
        throw new Error(`${t('cultivations.form.validation.geometry_type')} ${geometryType}`);
      }

      return true; // Validation passed
    } catch (error) {
      console.error('WKT Validation Error:', error.message);
      return false; 
    }
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required(t('cultivations.form.validation.required'))
  .max(50, t('cultivations.form.validation.name_length')),
  geom: yup.string()
  .required(t('cultivations.form.validation.required'))
  .test('custom-validation', t('cultivations.form.validation.geometry'), 
  validateGeom),
    cultivar: yup.string(),
    value_number: yup.number().min(0, t('analysis.form.validation.value_number')),
    value_text: yup.string(),
    phase: yup.string(),
    planting_date: yup.date()
});

const clearEmptyData = (data) => {
  Object.keys(data).forEach(key => {
    if (data[key] === '' || data[key] == null) {
      delete data[key];
    }
  });
  return data;
}

const handleSubmit = async (event) => {
  event.preventDefault();
  try {
    // Validate the entire form before submitting
    let tempFormData = formData;
    tempFormData['field'] = selectedField;
    if(geoFieldRef?.current?.value){
      tempFormData['geom'] = geoFieldRef.current.value;
    }
    for (let key in tempFormData) {
      if (tempFormData.hasOwnProperty(key)) {
          let datum_obj = $('#'+key);
          let datum_val = tempFormData[key];
          let datum_name = datum_obj.id;
          if(datum_obj.attr("validate_key")){
            datum_name = datum_obj.attr("validate_key");
          }
          validationSchema
            .validateAt(datum_name, { [datum_name]: datum_val })
            .then(() => {
              // No validation errors for this field, clear the error for this field
              setErrors((prevErrors) => ({ ...prevErrors, [datum_obj.id]: undefined }));
            })
            .catch((error) => {
              // Validation error occurred, set the error for this field
              setErrors((prevErrors) => ({ ...prevErrors, [datum_obj.id]: error.message }));
          });
      }
    }
    setFormData(tempFormData);
    setFormData(clearEmptyData(formData));

    if(actionSelected.value === "soil-analysis"){
      let tempFormData = formData;
      tempFormData["parameters"] = [];
      for (let key in tempFormData) {
        if (tempFormData.hasOwnProperty(key)) {
          if(key.startsWith("param_")){
            let datum_obj = $("[id='"+key+"']");
            let datum_label = datum_obj.attr("django-label");
            if(isNaN(tempFormData[key])){
              tempFormData["parameters"].push(
                {
                  "type":key.substring(6),
                  "label":datum_label,
                  "value_text":tempFormData[key]
                }
              );
            }else{
              tempFormData["parameters"].push(
                {
                  "type":key.substring(6),
                  "label":datum_label,
                  "value_number":tempFormData[key]
                }
              );
            }
            delete tempFormData[key];
          }
        }
      }
      setFormData(tempFormData);
    }

    // If validation passes, proceed to the server request
    await $.ajax({
        url: serverUrl+"/"+actionSelected.value+"/",
        type: 'post',
        headers: {
          "Accept-Language": i18n.language || 'el',
          'X-CSRFToken': cookies.csrftoken,
          'Content-Type': 'application/json',
          "Authorization": cookies.auth
        },
        xhrFields: {
          withCredentials: true,
          xsrfCookieName: 'csrftoken',
          xsrfHeaderName: 'X-CSRFToken'
        },
        crossDomain: true,
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        data: JSON.stringify(formData),
        success: function (data, textStatus, request) {
          // Handle the response, e.g. success message
          let trans_file = actionSelected.value;
          if(trans_file.includes("-")){
            let splits = trans_file.split("-");
            trans_file = splits[1]+"."+splits[0];
          }
          toast.success(t(trans_file+'.create.success'));
          handleReset();
          handleClear();
          setisRedrawPressed(!isRedrawPressed);
        },
        error: function (request, textStatus, errorThrown) {
            let trans_file = actionSelected.value;
            if(trans_file.includes("-")){
              let splits = trans_file.split("-");
              trans_file = splits[1]+"."+splits[0];
            }
            // Handle errors
            console.error('POST request failed:', errorThrown);
            if (errorThrown.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.error('Server responded with:', request.response.data);
              console.error('Status code:', textStatus);
              toast.error(`${t(trans_file+'create.fail_2xx')} ${textStatus}.`);
            } else if (request) {
              // The request was made but no response was received
              console.error('No response received from the server');
              toast.error(t(trans_file+'create.fail_unreachable'));
            } else {
              // Something happened in setting up the request that triggered an Error
              console.error('Error setting up the request:', errorThrown);
              toast.error(t(trans_file+'create.fail_unknown'));
            }
            //toast.error(`An error occurred: ${errorThrown}`);
        }
    });
    return;
  } catch (validationError) {
    if(validationError.responseJSON){
      for (const [key, value] of Object.entries(validationError.responseJSON)) {
        toast.error(`${key}: ${value}`);
      }
    }
    else if(validationError.responseText){
      toast.error(`${t("common.error.general")}: ${validationError.responseText}`);
    }else{
      // Handle validation errors
      const validationErrors = {};
      if(validationError.inner){
        validationError.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        setErrors(validationErrors);
      }
  
      // Optionally, display an error message to the user
      console.error('Validation failed:', validationError);
      //toast.error('Validation failed:', validationError);
    }
  }
};

const handleAcceptClick = (event) => {
  handleSubmit(event);
};

const handleChange = (event) => {
  let datum = event;
  if(event?.target){
    datum = event.target;
    datum.name = datum.id;
    if(datum.getAttribute("validate_key")){
      datum.name = datum.getAttribute("validate_key");
    }else{
      datum.name = datum.id;
    }
  }
  if(!datum.id){datum.id = datum.name;}
  const { name, value, id } = datum;

  // Validate the field on each change
  validationSchema
    .validateAt(name, { [name]: value })
    .then(() => {
      // No validation errors for this field, clear the error for this field
      setErrors((prevErrors) => ({ ...prevErrors, [id]: undefined }));
    })
    .catch((error) => {
      // Validation error occurred, set the error for this field
      setErrors((prevErrors) => ({ ...prevErrors, [id]: error.message }));
    });

  let temp_data = formData;
  temp_data[id] = value;
  setFormData(temp_data);
};

const handleReset = () => {
  // Reset the form data state to empty values
  setFormData({});
};

const handleClear = () => {
  // Clear the input fields
  const inputFields = document.querySelectorAll('.create-input');
  inputFields.forEach((input) => {
    input.value = '';
  });
  if(geoFieldRef?.current){
    geoFieldRef.current.dispatchEvent(new Event('input', { bubbles: true}));
  }
}

const handleDeclineClick = () => {
  // Clear the form data
  handleReset();
  handleClear();
  setisClearPressed(true)
};

const CultivationForm = () => {
  return (
    <span>
      <div className="form-row">
        <label htmlFor="name" className='label-create'>{t('cultivations.form.name')}</label>
        <input type="text" id="name" name="name" 
          onChange={handleChange} className='create-input' placeholder={t('cultivations.form.placeholder.name')}
        />
        {errors.name && <span className="error-message">{errors.name}</span>}
      </div>
      <div className="form-row">
        <label htmlFor="geom" className='label-create'>{t('cultivations.form.geometry')}</label>
        <input type="text" id="geom" name="geom" 
          placeholder="MULTIPOLYGON (((22.167664 39.302247, 22.223969 39.307562, 
            22.241821 39.222479, 22.101746 39.242695, 22.167664 39.302247)))"
          ref={geoFieldRef} 
          onChange={handleChange} 
          onKeyDown={handleChange}  
          className='create-input'
        />
        {errors.geom && <span className="error-message">{errors.geom}</span>}
      </div>
      <div className="form-row">
        <label htmlFor="cultivar" className='label-create'>{t('cultivations.form.variety')}</label>
        <AsyncSelect 
          key={"cultivar_select_"+i18n.language}
          className='create-input' 
          id="cultivar" 
          onChange={handleChange} 
          name="cultivar"
          cacheOptions 
          defaultOptions 
          loadOptions={loadCultivarOptions}>
            <option disabled selected value>{t('cultivations.form.placeholder.name')}</option>
        </AsyncSelect>
        {errors.cultivar && <span className="error-message">{errors.cultivar}</span>}
      </div>
      <div className="form-row">
        <label htmlFor="phase" className='label-create'>{t('cultivations.form.phase')}</label>
        <AsyncSelect  
          key={"phase_select_"+i18n.language}
          className='create-input' 
          id="phase" 
          onChange={handleChange}
          name="phase" 
          cacheOptions 
          defaultOptions 
          loadOptions={loadPhaseOptions}>
            <option disabled selected value>{t('cultivations.form.placeholder.phase')}</option>
        </AsyncSelect>
        {errors.phase && <span className="error-message">{errors.phase}</span>}
      </div>
      <div className="form-row">
        <label htmlFor="planting_date" className='label-create'>{t('cultivations.form.seed_date')}</label>
        <input type="datetime-local" id="planting_date" name="planting_date" 
          onChange={handleChange} className='create-input'
        />
        {errors.planting_date && <span className="error-message">{errors.planting_date}</span>}
      </div>
    </span>
  );
};

const SoilAnalysisForm = () => {
  return (
    <span>
        {parameterOptions.slice(0,1).map((option, index) => (
          <div className="form-row" style={{flexDirection:"row",borderBottom: "1px dotted black", marginRight: "15px"}}>
            <label htmlFor={option.value} className='label-create'>{parameterLabelOptions[index].value}</label>
            <select name={option.value} id={"param_"+option.value} validate_key="value_text" django-label={parameterLabelOptions[index].value}
              style={{width:"49%",marginTop:"auto",marginBottom:"10px"}} onChange={handleChange} className='create-input soil-parameter-input'>
              <option value="S">{t('analysis.soil.form.sandy')}</option>
              <option value="LS">{t('analysis.soil.form.loam_sand')}</option>
              <option value="SL">{t('analysis.soil.form.sandy_loam')}</option>
              <option value="L">{t('analysis.soil.form.loam')}</option>
              <option value="SiL">{t('analysis.soil.form.silty_loam')}</option>
              <option value="Si">{t('analysis.soil.form.silt')}</option>
              <option value="SCL">{t('analysis.soil.form.sand_clay_loam')}</option>
              <option value="CL">{t('analysis.soil.form.clay_loam')}</option>
              <option value="SiCL">{t('analysis.soil.form.silt_clay_loam')}</option>
              <option value="SC">{t('analysis.soil.form.sand_clay')}</option>
              <option value="SiC">{t('analysis.soil.form.silt_clay')}</option>
              <option value="C">{t('analysis.soil.form.clay')}</option>
            </select>
            {errors.name && <span className="error-message">{errors.name}</span>}
          </div>
        ))}
        {parameterOptions.slice(1).map((option, index) => (
        <div className="form-row" style={{flexDirection:"row",borderBottom: "1px dotted black", marginRight: "15px"}}>
          <label htmlFor={option.value} className='label-create'>{parameterLabelOptions[index+1].value}</label>
          <input type="number" id={"param_"+option.value} name={option.value} validate_key="value_number" django-label={parameterLabelOptions[index+1].value}
            style={{width:"49%",marginTop:"auto",marginBottom:"10px"}}
            onChange={handleChange} className='create-input soil-parameter-input'
          />
          {errors.name && <span className="error-message">{errors.name}</span>}
        </div>
        ))}
    </span>
  );
};

  const handleActionTypeChange = (e) => {
    setActionSelected(e);
    setFormData({});
    if(e.value === "cultivations"){
      setActionForm(CultivationForm);
    }else if(e.value === "soil-analysis"){
      setActionForm(SoilAnalysisForm);
    }else{
      setActionForm(null);
    } 
  }

  return (
    <div className="content page1_content">

      <div className="create-field-map" >
          <MapCreateComponent 
            geoFieldRef={geoFieldRef} 
            isClearPressed={isClearPressed} 
            setisClearPressed={setisClearPressed} 
            isRedrawPressed={isRedrawPressed}
            selectedField={selectedField}/>
      </div>
      <div className="form-background">
        <form onSubmit={handleSubmit}>
          <div className="form-container" 
            style={{height: "70vh",overflowY: "auto", overflowX: "hidden", paddingRight: "20px"}}>
            <div className="first-button-row">
              <>
                <img
                    onMouseEnter={() => handleMouseEnterPolygon()}
                    onMouseLeave={() => handleMouseLeavePolygon()}
                    src={isHoveredPolygon ? hoverPolygon : originalPolygon}
                    alt="CreatePolygon"
                    data-tip={t("common.map.create")}
                    data-place="top"
                    className={`leaflet-button-create1 ${isHoveredPolygon ? 'hovered' : ''}`}
                    onClick={() => handlePolygonClick()} 
                />
                <Tooltip effect="solid"  hideEvent="mouseout"/>
              </>
              <>        
                <img
                    onMouseEnter={() => handleMouseEnterEdit()}
                    onMouseLeave={() => handleMouseLeaveEdit()}
                    src={isHoveredEdit ? hoverEdit : originalEdit}
                    alt="Edit"
                    data-tip={t("common.map.edit")}
                    data-place="top"
                    className={`leaflet-button-create3 ${isHoveredEdit ? 'hovered' : ''}`}
                    onClick={() => handleEditClick()} 
                />
                <Tooltip effect="solid"  hideEvent="mouseout"/>
              </>
              {/*<>  
                <img
                    onMouseEnter={() => handleMouseEnterFileImport()}
                    onMouseLeave={() => handleMouseLeaveFileImport()}
                    src={isHoveredFileImport ? hoverFileImport : originalFileImport}
                    alt="FileImport"
                    data-tip={t("common.map.file")}
                    data-place="top"
                    className={`leaflet-button-create4 ${isHoveredFileImport ? 'hovered' : ''}`}
                    onClick={() => handleFileImportClick()} 
                />
                <Tooltip effect="solid"  hideEvent="mouseout"/>
              </>*/}
              <>
                <img
                    onMouseEnter={() => handleMouseEnterTrash()}
                    onMouseLeave={() => handleMouseLeaveTrash()}
                    src={isHoveredTrash ? hoverTrash : originalTrash}
                    alt="Trash"
                    data-tip={t("common.map.delete")} 
                    data-place="top"
                    className={`leaflet-button-create5 ${isHoveredTrash ? 'hovered' : ''}`}
                    onClick={() => handleTrashClick()} 
                />
                <Tooltip effect="solid"  hideEvent="mouseout"/>
            </>
            </div>
            <div className="second-button-row">
                <img
                  onMouseEnter={() => handleMouseEnterAccept()}
                  onMouseLeave={() => handleMouseLeaveAccept()}
                  src={isHoveredAccept ? hoverAccept : originalAccept}
                  alt={t("common.map.accept")}
                  className={`accept-decline ${isHoveredAccept ? 'hovered' : ''}`}
                  onClick={(event) => handleAcceptClick(event)} 
                />
                <img
                  onMouseEnter={() => handleMouseEnterDecline()}
                  onMouseLeave={() => handleMouseLeaveDecline()}
                  src={isHoveredDecline ? hoverDecline : originalDecline}
                  alt={t("common.map.cancel")}
                  className={`accept-decline ${isHoveredDecline ? 'hovered' : ''}`}
                  onClick={() => handleDeclineClick()} 
                />
                <Select 
                  styles={{
                      control: (baseStyles) => ({
                          ...baseStyles,
                          width: '22ch',
                      }),
                  }}
                  name="action_type" 
                  id="action_type_select" 
                  onChange={handleActionTypeChange}
                  value={actionSelected}
                  options={actionOptions} 
                />
            </div>
            {actionForm}
          </div>
        </form>
      </div>
       {/* ToastContainer for displaying notifications */}
      <ToastContainer
        position="bottom-right"
        autoClose={7000} // Close the notification after 7 seconds
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
    
  );
};

export default CreateFieldData;