import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import './CSSCustom/Sidebar.css';
import hoverLand from './img/ICON_AGRO_ORANGE.png';
import originalLand from './img/ICON_AGRO_WHITE.png';
import calendarIcon from './img/ICON_CALENDAR_GREEN.png';
import hoverCalendar from './img/ICON_CALENDAR_ORANGE.png'; // Image for hover (change color)
import originalCalendar from './img/ICON_CALENDAR_WHITE.png'; //Original Image
import clockIcon from './img/ICON_CLOCK_GREEN.png';
import hoverReport from './img/ICON_DATA_ORANGE.png';
import originalReport from './img/ICON_DATA_WHITE.png';
import cloudImage from './img/ICON_WEATHER_CLOUD_GREEN.png';
import cloudSunImage from './img/ICON_WEATHER_CLOUD_SUN_GREEN.png';
import dropsImage from './img/ICON_WEATHER_DROPS_GREEN.png';
import rainImage from './img/ICON_WEATHER_RAIN_GREEN.png';
import snowImage from './img/ICON_WEATHER_SNOW_GREEN.png';
import sunImage from './img/ICON_WEATHER_SUN_GREEN.png';
import weatherTodayIcon from './img/ICON_WEATHER_TODAY_GREEN.png';
import logoImageWhite from './img/logowhite.png';

function Sidebar() {
  const location = useLocation();
  const { t } = useTranslation();

  const [cookies] = useCookies(['sidebarWeather']);
  const [isHoveredLand, setHoveredLand] = useState(false);
  const [isHoveredReport, setHoveredReport] = useState(false);
  const [isHoveredCalendar, setHoveredCalendar] = useState(false);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [testweather, setTestWeather] = useState([
    {
        "tavg": 14.0,
        "tmin": 11.7,
        "tmax": 17.5,
    },
    {
        "tavg": 14.2,
        "tmin": 10.3,
        "tmax": 18.2,
    },
    {
        "tavg": 14.5,
        "tmin": 13.4,
        "tmax": 16.4,
    },
    {
        "tavg": 15.0,
        "tmin": 13.0,
        "tmax": 18.1,
    },
    {
        "tavg": 15.7,
        "tmin": 13.9,
        "tmax": 19.1,
    },
    {
        "tavg": 16.2,
        "tmin": 13.6,
        "tmax": 19.9,
    },
    {
        "tavg": 16.8,
        "tmin": 15.1,
        "tmax": 19.6,
    },
    {
        "tavg": 15.7,
        "tmin": 14.1,
        "tmax": 18.3,
    }
]);

  // Land icon hover handle
  const handleMouseEnterLand = () => {
    setHoveredLand(true);
  };

  const handleMouseLeaveLand = () => {
    setHoveredLand(false);
  };

  // Report icon hover handle
  const handleMouseEnterReport = () => {
    setHoveredReport(true);
  };

  const handleMouseLeaveReport = () => {
    setHoveredReport(false);
  };

  // Calendar icon hover handle
  const handleMouseEnterCalendar = () => {
    setHoveredCalendar(true);
  };

  const handleMouseLeaveCalendar = () => {
    setHoveredCalendar(false);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000); // Update every 1000 milliseconds (1 second)

    return () => {
      clearInterval(intervalId); // Cleanup the interval when the component is unmounted
    };
  }, []); // Empty dependency array ensures that the effect runs once when the component mounts


  useEffect(() => {
    const firstHalfContainer = document.querySelector('.first-half-container');
    if (firstHalfContainer) {
      // Set the width of the first-half-container based on the sidebar's width when open
      firstHalfContainer.style.width = '100%';
    }
  }, []);

  
  useEffect(() => {
    if (cookies.sidebarWeather){
      setTestWeather(cookies.sidebarWeather);
    }
  }, [cookies.sidebarWeather]);

  const formatTime = (timeString) => {
    const date = new Date(timeString);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };
  
  // Function to format date in DD/MM/YYYY format
  const formatDate = (timeString) => {
    const date = new Date(timeString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  return (
    <div className={`sidebar open`}>
      <div className="first-half-container"> 
        <div className="container-logo-sidebar">
          <img src={logoImageWhite} alt="Logo" className='logosidebar' />
        </div>
        <div className="top-button-container">
          <Link style={{textDecoration:"none"}} className='links' to="/fields">
            <button
              className={`button ${isHoveredLand || location.pathname === '/fields' ? 'hovered' : ''}`}
              onMouseEnter={handleMouseEnterLand}
              onMouseLeave={handleMouseLeaveLand}
            >
              <img
                src={isHoveredLand || location.pathname === '/fields' ? hoverLand : originalLand}
                alt="Land"
                className="button-image"
              />
              <span className='text-span'>{t("sidebar.menu.fields")}</span>
            </button>
          </Link>
          <Link style={{textDecoration:"none"}} className='links' to="/fields-data">
            <button
              className={`button ${isHoveredReport || location.pathname === '/fields-data' ? 'hovered' : ''}`}
              onMouseEnter={handleMouseEnterReport}
              onMouseLeave={handleMouseLeaveReport}
            >
              <img
                src={isHoveredReport || location.pathname === '/fields-data' ? hoverReport : originalReport}
                alt="Report"
                className="button-image"
              /> 
              <span className='text-span'>{t("sidebar.menu.data")}</span>
            </button>
          </Link>
          <Link style={{textDecoration:"none"}} className='links' to="/agrocalendar">
            <button
                className={`button ${isHoveredCalendar || location.pathname === '/agrocalendar' ? 'hovered' : ''}`}
                onMouseEnter={handleMouseEnterCalendar}
                onMouseLeave={handleMouseLeaveCalendar}
              >
                <img
                  src={isHoveredCalendar || location.pathname === '/agrocalendar' ? hoverCalendar : originalCalendar}
                  alt="Calendar"
                  className="button-image"
                /> 
              <span className='text-span'>{t("sidebar.menu.calendar")}</span>
              </button>
          </Link>
        </div>
      </div> 
      <div className="second-half-container">
        <div className="first-row">
          <div className="column1-1">
            <img src={calendarIcon} alt="Calendar" />
            <p>{formatDate(currentDateTime)}</p>
          </div>
          <div className="column1-2">
            <img src={weatherTodayIcon} alt="Cloud" />
            <p>{testweather?.[0]?.tavg !== null && testweather?.[0]?.tavg !== undefined ? `${testweather[0].tavg}°C` : "NA"}</p>
          </div>
          <div className="column1-2">
            <img src={clockIcon} alt="Clock" />
            <p>{formatTime(currentDateTime)}</p>
          </div>
        </div>
        <div className="weather-divider"></div>
        <div className="third-row">
          <div className="column3-1">
            <p>{`${formatDate(addDays(currentDateTime, 1))}`}</p>
            <img src={sunImage} alt="Sun" />
            <p className='temps'>
              {testweather[1] && testweather[1].tmin !== null && testweather[1].tmax !== null
              ? `${testweather[1].tmin}°C-${testweather[1].tmax}°C`
              : "Temperature not available"}
            </p>
          </div>
          <div className="column3-2">
          <p>{`${formatDate(addDays(currentDateTime, 2))}`}</p>
            <img src={cloudSunImage} alt="Cloud Sun" />
            <p className='temps'>
              {testweather[2] && testweather[2].tmin !== null && testweather[2].tmax !== null
              ? `${testweather[2].tmin}°C-${testweather[2].tmax}°C`
              : "Temperature not available"}
              </p>
          </div>
          <div className="column3-3">
            <p>{`${formatDate(addDays(currentDateTime, 3))}`}</p>
            <img src={rainImage} alt="Rainy Cloud" />
            <p className='temps'>
              {testweather[3] && testweather[3].tmin !== null && testweather[3].tmax !== null
              ? `${testweather[3].tmin}°C-${testweather[3].tmax}°C`
              : "Temperature not available"}
            </p>
          </div>
        </div>
        <div className="fourth-row">
          <div className="column4-1">
            <p>{`${formatDate(addDays(currentDateTime, 4))}`}</p>
            <img src={cloudImage} alt="Sun" />
            <p className='temps'>
              {testweather[4] && testweather[4].tmin !== null && testweather[4].tmax !== null
              ? `${testweather[4].tmin}°C-${testweather[4].tmax}°C`
              : "Temperature not available"}
            </p>
          </div>
          <div className="column4-2">
          <p>{`${formatDate(addDays(currentDateTime, 5))}`}</p>
            <img src={snowImage} alt="Cloud Sun" />
            <p className='temps'>
              {testweather[5] && testweather[5].tmin !== null && testweather[5].tmax !== null
              ? `${testweather[5].tmin}°C-${testweather[5].tmax}°C`
              : "Temperature not available"}
            </p>
          </div>
          <div className="column4-3">
            <p>{`${formatDate(addDays(currentDateTime, 6))}`}</p>
            <img src={dropsImage} alt="Rainy Cloud" />
            <p className='temps'>
              {testweather[6] && testweather[6].tmin !== null && testweather[6].tmax !== null
              ? `${testweather[6].tmin}°C-${testweather[6].tmax}°C`
              : "Temperature not available"}
            </p>
          </div>
        </div>
        <div className="fifth-row"></div>
      </div>
    </div>
  );
}

export default Sidebar;
