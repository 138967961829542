// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upper-right-image {
    position: absolute;
    width: 3%;
    /* height: 3,5%; */
    top: 2vh; 
    right: 4vh; 
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    z-index: 1000;

  }
  `, "",{"version":3,"sources":["webpack://./src/CSSCustom/MapComponent.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;IACT,kBAAkB;IAClB,QAAQ;IACR,UAAU;IACV,eAAe;IACf,sCAAsC;IACtC,aAAa;;EAEf","sourcesContent":[".upper-right-image {\n    position: absolute;\n    width: 3%;\n    /* height: 3,5%; */\n    top: 2vh; \n    right: 4vh; \n    cursor: pointer;\n    transition: transform 0.3s ease-in-out;\n    z-index: 1000;\n\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
