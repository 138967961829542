import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

import commonEN from './locales/en/common.json';
import headerEN from './locales/en/header.json';
import page_loginEN from './locales/en/page_login.json';
import page_signupEN from './locales/en/page_signup.json';
import fieldsEN from './locales/en/fields.json';
import sidebarEN from './locales/en/sidebar.json';
import cultivationsEN from './locales/en/cultivations.json';
import analysisEN from './locales/en/analysis.json';
import agroCalendarEN from './locales/en/agro_calendar.json';
import actionsEN from './locales/en/actions.json';
import suggestionEN from './locales/en/suggestion.json';
import dataEN from './locales/en/data.json';

import commonEL from './locales/el/common.json';
import headerEL from './locales/el/header.json';
import page_loginEL from './locales/el/page_login.json';
import page_signupEL from './locales/el/page_signup.json';
import fieldsEL from './locales/el/fields.json';
import sidebarEL from './locales/el/sidebar.json';
import cultivationsEL from './locales/el/cultivations.json';
import analysisEL from './locales/el/analysis.json'
import agroCalendarEL from './locales/el/agro_calendar.json'
import actionsEL from './locales/el/actions.json';
import suggestionEL from './locales/el/suggestion.json';
import dataEL from './locales/el/data.json';

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  //.use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    //backend: {
   //   loadPath: "/locales/{{lng}}/{{ns}}.json",
    //},
    resources: {
      en: {
        translation: {
          common: commonEN,
          header: headerEN,
          page_login: page_loginEN,
          page_signup: page_signupEN,
          fields: fieldsEN,
          sidebar: sidebarEN,
          cultivations: cultivationsEN,
          analysis: analysisEN,
          agro_calendar: agroCalendarEN,
          actions:actionsEN,
          suggestion:suggestionEN,
          data:dataEN
        }
      },
      el: {
        translation: {
          common: commonEL,
          header: headerEL,
          page_login: page_loginEL,
          page_signup: page_signupEL,
          fields: fieldsEL,
          sidebar: sidebarEL,
          cultivations: cultivationsEL,
          analysis: analysisEL,
          agro_calendar: agroCalendarEL,
          actions:actionsEL,
          suggestion:suggestionEL,
          data:dataEL
        }
      }
    },
    fallbackLng: 'el',
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false, // Optional: prevents the app from rendering before translations are loaded
    },
  });


export default i18n;