import React, { createContext, useState, useContext } from 'react';

// Create a Context for the page title
const PageTitleContext = createContext();

// Create a custom hook to use the PageTitleContext
export const usePageTitle = () => {
  return useContext(PageTitleContext);
};

// Create a provider component
export const PageTitleProvider = ({ children }) => {
  const [pageTitle, setPageTitle] = useState('');
  const [pageTitleIcon, setPageTitleIcon] = useState('');
  
  return (
    <PageTitleContext.Provider value={{ pageTitle, setPageTitle, pageTitleIcon, setPageTitleIcon }}>
      {children}
    </PageTitleContext.Provider>
  );
};
