import React, { useEffect, useRef, useState } from 'react';
import AsyncSelect from 'react-select/async';
import Tooltip from 'react-tooltip';
import * as yup from 'yup';

import MapCreateComponent from './MapCreateComponent';
import { usePageTitle } from './JS_Context/ContextTitle';
import Wkt from 'wicket';

import $ from "jquery";
import { useCookies } from 'react-cookie';
import originalAccept from './img/ICON_ACCEPT_BLACK.png';
import hoverAccept from './img/ICON_ACCEPT_ORANGE.png';
import originalDecline from './img/ICON_DECLINE_BLACK.png';
import hoverDecline from './img/ICON_DECLINE_ORANGE.png';
import originalEdit from './img/ICON_EDIT_LEAF_BLACK.png';
import hoverEdit from './img/ICON_EDIT_LEAF_ORANGE.png';
import originalFileImport from './img/ICON_IMPORT_BLACK.png';
import hoverFileImport from './img/ICON_IMPORT_ORANGE.png';
import originalPolygon from './img/ICON_POLUGON.png';
import hoverPolygon from './img/ICON_POLUGON_ORANGE.png';
import originalTrash from './img/ICON_TRASH_BLACK.png';
import hoverTrash from './img/ICON_TRASH_ORANGE.png';

import 'leaflet/dist/leaflet.css';
import './CSSCustom/fieldForms.css';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import getServerURL from "./Configuration.js";
import { useTranslation } from 'react-i18next';

const serverUrl = getServerURL();

function EditCultivations() {
  const [isHoveredPolygon, setIsHoveredPolygon] = useState(false);
  const [isHoveredEdit, setIsHoveredEdit] = useState(false);
  const [isHoveredTrash, setIsHoveredTrash] = useState(false);
  const [isHoveredFileImport, setIsHoveredFileImport] = useState(false);
  const [isHoveredAccept, setIsHoveredAccept] = useState(false);
  const [isHoveredDecline, setIsHoveredDecline] = useState(false);
  const [isClearPressed, setisClearPressed] = useState(false);
  const [cultivationID, setCultivationID] = useState();
  const [isRedrawPressed, setisRedrawPressed] = useState(false);
  const [cookies] = useCookies(['csrftoken']);
  const [formData, setFormData] = useState({
    name: '',
    geom: '',
    cultivar: '',
    phase: '',
    planting_date: ''
  });
  const [errors, setErrors] = useState({});
  const [selectedField,setSelectedField] = useState();
  const geoFieldRef = useRef();
  const cultivarSelectFieldRef = useRef();
  const phaseSelectFieldRef = useRef();
  const [phaseInitialized,setPhaseInitialized] = useState(false);
  const [cultivarsInitialized,setCultivarsInitialized] = useState(false);
  const { t, i18n } = useTranslation();
  const { setPageTitle, setPageTitleIcon } = usePageTitle();

  // Polygon icon hover handle
  const handleMouseEnterPolygon = () => {
    setIsHoveredPolygon(true);
  };

  const handleMouseLeavePolygon = () => {
    setIsHoveredPolygon(false);
    // Tooltip.hide() //to work need to remove ReactStrictMode from App.js
  };

  const handlePolygonClick = () => {
    let e = document.createEvent('Event');
    e.initEvent('click', true, true);
    let cb = document.getElementsByClassName('leaflet-draw-draw-polygon');
    return !cb[0].dispatchEvent(e);
  };

  // Edit icon hover handle
  const handleMouseEnterEdit = () => {
    setIsHoveredEdit(true);
  };

  const handleMouseLeaveEdit = () => {
    setIsHoveredEdit(false);
  };

  const handleEditClick = () => {
    let e = document.createEvent('Event');
    e.initEvent('click', true, true);
    let cb = document.getElementsByClassName('leaflet-draw-edit-edit');
    return !cb[0].dispatchEvent(e);
  };

  // File Import icon hover handle
  const handleMouseEnterFileImport = () => {
    setIsHoveredFileImport(true);
  };

  const handleMouseLeaveFileImport = () => {
    setIsHoveredFileImport(false);
  };

  const handleFileImportClick = () => {
    console.log("File Import here")
  };

  // Trash icon hover handle
  const handleMouseEnterTrash = () => {
    setIsHoveredTrash(true);
  };

  const handleMouseLeaveTrash = () => {
    setIsHoveredTrash(false);
  };

  const handleTrashClick = () => {
    let e = document.createEvent('Event');
    e.initEvent('click', true, true);
    let cb = document.getElementsByClassName('leaflet-draw-edit-remove');
    return !cb[0].dispatchEvent(e);
  };

  // Accept icon hover handle
  const handleMouseEnterAccept = () => {
    setIsHoveredAccept(true);
  };

  const handleMouseLeaveAccept = () => {
    setIsHoveredAccept(false);
  };
  
  // Decline icon hover handle
  const handleMouseEnterDecline = () => {
    setIsHoveredDecline(true);
  };

  const handleMouseLeaveDecline = () => {
    setIsHoveredDecline(false);
  };

  // Initialize form
  const initForm = () => {
      $.ajax({
          url: serverUrl+"/cultivations/"+cultivationID+"/",
          type: 'get',
          headers: {
              "Accept-Language": i18n.language || 'el',
              "X-CSRFToken": cookies.csrftoken,
              "Authorization": cookies.auth
          },
          xhrFields: {
              withCredentials: true,
              xsrfCookieName: 'csrftoken',
              xsrfHeaderName: 'X-CSRFToken'
          },
          crossDomain: true,
          success: function (data) {
            const inputFields = document.querySelectorAll('.create-input');
            let newFormData = formData;
            inputFields.forEach((input) => {
              let val = data[input.name];
              if(val){
                if((val+'').includes(";")){
                  val = val.split(';')[1];
                }
                if(input.type === 'datetime-local'){
                  val = val.slice(0, -1);
                }
                if(val?.id){
                  val = val.id;
                }
                input.value = val;
                newFormData[input.name] = val; 
              }
            });
            newFormData['cultivar'] = data['cultivar']['id'];
            newFormData['phase'] = data['phase'];
            setSelectedField(data['field']['id']);
            cultivarSelectFieldRef.current.selectOption(
              cultivarSelectFieldRef.current.props.options.find(
                function(e){if(e.value === data['cultivar']['id']){return e;}else{return null;}}
              )
            );
            phaseSelectFieldRef.current.selectOption(
              phaseSelectFieldRef.current.props.options.find(
                function(e){if(e.value === data['phase']){return e;}else{return null;}}
              )
            );
            setFormData(newFormData);
          },
          error: function (error) {
            console.error('Error fetching cultivation details:', error);
          }
      });
  };

  const readyToInit = phaseInitialized && cultivarsInitialized;

  //Initialize page
  useEffect(() =>{
    const params = new URLSearchParams(window.location.search);
    setCultivationID(params.get('id'));
    if(cultivationID && readyToInit){initForm();}
  },[cultivationID,readyToInit]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() =>{
    setPageTitle("cultivations.edit.title");
    setPageTitleIcon("/img/ICON_AGRO_GREEN.png");
  },[])

  //Set geo value of the Form based on current geo Reference
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
      if (geoFieldRef.current) {
        setFormData((prevFormData) => ({
            ...prevFormData,
            geom: geoFieldRef.current.value,
        }));
    }
  }, [geoFieldRef.current?.value]);

  function loadCultivarOptions(){
    return new Promise((resolve, reject) => {$.ajax({
          url: serverUrl+"/cultivars/",
          type: 'get',
          headers: {
            "Accept-Language": i18n.language || 'el',
            'X-CSRFToken': cookies.csrftoken,
            'Content-Type': 'application/json',
            "Authorization": cookies.auth
          },
          xhrFields: {
            withCredentials: true,
            xsrfCookieName: 'csrftoken',
            xsrfHeaderName: 'X-CSRFToken'
          },
          crossDomain: true,
          contentType: "application/json; charset=utf-8",
          dataType: "json",
          success: function (data, textStatus, request) {
            let options = [];
            for(let datum of data){
                options.push({
                    value: datum['id'],
                    label: datum['name'],
                    name: "cultivar"
                });
            }
            resolve(options);
            setCultivarsInitialized(true);
          },
          error: function (request, textStatus, errorThrown) {
              // Handle errors
              console.error('Cultivar get request failed:', errorThrown);
              reject(errorThrown);
          }
      })});
  };

  function loadPhaseOptions(){
    return new Promise((resolve, reject) => {$.ajax({
        url: serverUrl+"/api/swagger.json",
        type: 'get',
        headers: {
          "Accept-Language": i18n.language || 'el',
          'X-CSRFToken': cookies.csrftoken,
          'Content-Type': 'application/json',
          "Authorization": cookies.auth
        },
        xhrFields: {
          withCredentials: true,
          xsrfCookieName: 'csrftoken',
          xsrfHeaderName: 'X-CSRFToken'
        },
        crossDomain: true,
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        success: function (data, textStatus, request) {
            data = data['paths']['/cultivations/']['post']['parameters'][0]['schema']['properties']['phase']['enum'];
            let options = [];
            for(let datum of data){
              options.push({
                  value: datum,
                  label: datum,
                  name: "phase"
              });
            }
            resolve(options);
            setPhaseInitialized(true);
        },
        error: function (request, textStatus, errorThrown) {
            // Handle errors
            console.error('Phase get request failed:', errorThrown);
            reject(errorThrown);
        }
      })});
  };

  // Validate geom field when geoFieldRef.current.value changes
  useEffect(() => {
    if (geoFieldRef.current?.value !== undefined) {
      validationSchema
        .validateAt('geom', { geom: geoFieldRef.current?.value })
        .then(() => {
          // No validation errors for geom field, clear the error for geom field
          setErrors((prevErrors) => ({ ...prevErrors, geom: undefined }));
        })
        .catch((error) => {
          // Validation error occurred, set the error for geom field
          setErrors((prevErrors) => ({ ...prevErrors, geom: error.message }));
        });
    }
  }, [geoFieldRef.current?.value]); // eslint-disable-line react-hooks/exhaustive-deps
  

  // Form Data

  const validateGeom = (value) => {
    const wkt = new Wkt.Wkt();

    try {
      wkt.read(value);
      const geometryType = wkt.type;

      if (!(geometryType === 'polygon' || geometryType === 'multipolygon')) {
        throw new Error(`${t('cultivations.form.validation.geometry_type')} ${geometryType}`);
      }

      return true; // Validation passed
    } catch (error) {
      console.error('WKT Validation Error:', error.message);
      return false; 
    }
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required(t('cultivations.form.validation.required'))
  .max(50, t('cultivations.form.validation.name_length')),
  geom: yup.string()
  .required(t('cultivations.form.validation.required'))
  .test('custom-validation', t('cultivations.form.validation.geometry'), 
  validateGeom),
    cultivar: yup.string(),
    phase: yup.string(),
    planting_date: yup.date()
});

const clearEmptyData = (data) => {
  Object.keys(data).forEach(key => {
    if (data[key] === '' || data[key] == null) {
      delete data[key];
    }
  });
  return data;
}

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Validate the entire form before submitting
      await validationSchema.validate(formData, { abortEarly: false });
      setFormData(clearEmptyData(formData));
      let tempFormData = formData;
      tempFormData['field'] = selectedField;
      setFormData(tempFormData);

      // If validation passes, proceed to the server request
      await $.ajax({
          url: serverUrl+"/cultivations/"+cultivationID+"/",
          type: 'put',
          headers: {
            "Accept-Language": i18n.language || 'el',
            'X-CSRFToken': cookies.csrftoken,
            'Content-Type': 'application/json',
            "Authorization": cookies.auth
          },
          xhrFields: {
            withCredentials: true,
            xsrfCookieName: 'csrftoken',
            xsrfHeaderName: 'X-CSRFToken'
          },
          crossDomain: true,
          contentType: "application/json; charset=utf-8",
          dataType: "json",
          data: JSON.stringify(formData),
          success: function (data, textStatus, request) {
            // Handle the response, e.g. success message
            toast.success(t('cultivations.edit.success'));
            setisRedrawPressed(!isRedrawPressed);
          },
          error: function (request, textStatus, errorThrown) {
              // Handle errors
              console.error('PUT request failed:', errorThrown);
              if (errorThrown.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('Server responded with:', request.response.data);
                console.error('Status code:', textStatus);
                toast.error(`${t('cultivations.edit.fail_2xx')} ${textStatus}.`);
              } else if (request) {
                // The request was made but no response was received
                console.error('No response received from the server');
                toast.error(t('cultivations.edit.fail_unreachable'));
              } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error setting up the request:', errorThrown);
                toast.error(t('cultivations.edit.fail_unknown'));
              }
              toast.error(`${t("common.error.general")}: ${errorThrown}`);
          }
      });
      return;
    } catch (validationError) {
      if(validationError.responseJSON){
        for (const [key, value] of Object.entries(validationError.responseJSON)) {
          toast.error(`${key}: ${value}`);
        }
      }
      else if(validationError.responseText){
        toast.error(`${t("common.error.general")}: ${validationError.responseText}`);
      }else{
        // Handle validation errors
        const validationErrors = {};
        if(validationError.inner){
          validationError.inner.forEach((error) => {
            validationErrors[error.path] = error.message;
          });
          setErrors(validationErrors);
        }
    
        // Optionally, display an error message to the user
        console.error('Validation failed:', validationError);
        //toast.error('Validation failed:', validationError);
      }
    }
  };

  const handleAcceptClick = (event) => {
    handleSubmit(event);
  };

  const handleChange = (event) => {
    let datum = event;
    if(event?.target){
      datum = event.target;
    }
    const { name, value } = datum;

    // Validate the field on each change
    validationSchema
      .validateAt(name, { [name]: value })
      .then(() => {
        // No validation errors for this field, clear the error for this field
        setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
      })
      .catch((error) => {
        // Validation error occurred, set the error for this field
        setErrors((prevErrors) => ({ ...prevErrors, [name]: error.message }));
      });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleReset = () => {
    // Reset the form data state to empty values
    setFormData({
      name: '',
      geom: '',
      cultivar: '',
      phase: '',
      planting_date: '',
    });
  };

  const handleClear = () => {
    // Clear the input fields
    const inputFields = document.querySelectorAll('.create-input');
    inputFields.forEach((input) => {
      input.value = '';
    });
    geoFieldRef.current.dispatchEvent(new Event('input', { bubbles: true}));
  }

  const handleDeclineClick = () => {
    // Clear the form data
    handleReset();
    handleClear();
    setisClearPressed(true)
  };

  return (
    <div className="content page1_content">

      <div className="create-field-map" >
          <MapCreateComponent 
            geoFieldRef={geoFieldRef} 
            isClearPressed={isClearPressed} 
            setisClearPressed={setisClearPressed} 
            isRedrawPressed={isRedrawPressed}
            selectedField={selectedField}/>
      </div>
      <div className="form-background">
        <form onSubmit={handleSubmit}>
          <div className="form-container">
            <div className="first-button-row">
              <>
                <img
                    onMouseEnter={() => handleMouseEnterPolygon()}
                    onMouseLeave={() => handleMouseLeavePolygon()}
                    src={isHoveredPolygon ? hoverPolygon : originalPolygon}
                    alt="CreatePolygon"
                    data-tip={t("common.map.create")}
                    data-place="top"
                    className={`leaflet-button-create1 ${isHoveredPolygon ? 'hovered' : ''}`}
                    onClick={() => handlePolygonClick()} 
                />
                <Tooltip effect="solid"  hideEvent="mouseout"/>
              </>
              <>        
                <img
                    onMouseEnter={() => handleMouseEnterEdit()}
                    onMouseLeave={() => handleMouseLeaveEdit()}
                    src={isHoveredEdit ? hoverEdit : originalEdit}
                    alt="Edit"
                    data-tip={t("common.map.edit")}
                    data-place="top"
                    className={`leaflet-button-create3 ${isHoveredEdit ? 'hovered' : ''}`}
                    onClick={() => handleEditClick()} 
                />
                <Tooltip effect="solid"  hideEvent="mouseout"/>
              </>
              {/*<>  
                <img
                    onMouseEnter={() => handleMouseEnterFileImport()}
                    onMouseLeave={() => handleMouseLeaveFileImport()}
                    src={isHoveredFileImport ? hoverFileImport : originalFileImport}
                    alt="FileImport"
                    data-tip={t("common.map.file")}
                    data-place="top"
                    className={`leaflet-button-create4 ${isHoveredFileImport ? 'hovered' : ''}`}
                    onClick={() => handleFileImportClick()} 
                />
                <Tooltip effect="solid"  hideEvent="mouseout"/>
              </>*/}
              <>
                <img
                    onMouseEnter={() => handleMouseEnterTrash()}
                    onMouseLeave={() => handleMouseLeaveTrash()}
                    src={isHoveredTrash ? hoverTrash : originalTrash}
                    alt="Trash"
                    data-tip={t("common.map.delete")}   
                    data-place="top"
                    className={`leaflet-button-create5 ${isHoveredTrash ? 'hovered' : ''}`}
                    onClick={() => handleTrashClick()} 
                />
                <Tooltip effect="solid"  hideEvent="mouseout"/>
            </>
            </div>
            <div className="second-button-row">
            <img
                  onMouseEnter={() => handleMouseEnterAccept()}
                  onMouseLeave={() => handleMouseLeaveAccept()}
                  src={isHoveredAccept ? hoverAccept : originalAccept}
                  alt={t("common.map.accept")}
                  className={`accept-decline ${isHoveredAccept ? 'hovered' : ''}`}
                  onClick={(event) => handleAcceptClick(event)} 
                />
                <img
                  onMouseEnter={() => handleMouseEnterDecline()}
                  onMouseLeave={() => handleMouseLeaveDecline()}
                  src={isHoveredDecline ? hoverDecline : originalDecline}
                  alt={t("common.map.cancel")}
                  className={`accept-decline ${isHoveredDecline ? 'hovered' : ''}`}
                  onClick={() => handleDeclineClick()} 
                />
            </div>
            
            <div className="form-row">
              <label htmlFor="name" className='label-create'>{t('cultivations.form.name')}</label>
              <input type="text" id="name" name="name" 
                onChange={handleChange} className='create-input' placeholder={t('cultivations.form.placeholder.name')}
              />
              {errors.name && <span className="error-message">{errors.name}</span>}
            </div>
            <div className="form-row">
              <label htmlFor="geom" className='label-create'>{t('cultivations.form.geometry')}</label>
              <input type="text" id="geom" name="geom" 
                placeholder="MULTIPOLYGON (((22.167664 39.302247, 22.223969 39.307562, 
                  22.241821 39.222479, 22.101746 39.242695, 22.167664 39.302247)))"
                ref={geoFieldRef} 
                onChange={handleChange} 
                onKeyDown={handleChange}  
                className='create-input'
              />
              {errors.geom && <span className="error-message">{errors.geom}</span>}
            </div>
            <div className="form-row">
              <label htmlFor="cultivar" className='label-create'>{t('cultivations.form.variety')}</label>
              <AsyncSelect
                className='create-input' 
                id="cultivar" 
                onChange={handleChange} 
                name="cultivar"
                cacheOptions 
                defaultOptions 
                ref={cultivarSelectFieldRef} 
                loadOptions={loadCultivarOptions}>
                  <option disabled selected value>{t('cultivations.form.placeholder.variety')}</option>
                
              </AsyncSelect>
              {errors.cultivar && <span className="error-message">{errors.cultivar}</span>}
            </div>
            <div className="form-row">
              <label htmlFor="phase" className='label-create'>{t('cultivations.form.phase')}</label>
              <AsyncSelect  
                className='create-input' 
                id="phase" 
                onChange={handleChange}
                name="phase" 
                cacheOptions 
                defaultOptions 
                ref={phaseSelectFieldRef}
                loadOptions={loadPhaseOptions}>
                  <option disabled selected value>{t('cultivations.form.placeholder.phase')}</option>
              </AsyncSelect>
              {errors.phase && <span className="error-message">{errors.phase}</span>}
            </div>
            <div className="form-row">
              <label htmlFor="planting_date" className='label-create'>{t('cultivations.form.seed_date')}</label>
              <input type="datetime-local" id="planting_date" name="planting_date" 
                onChange={handleChange} className='create-input'
              />
              {errors.planting_date && <span className="error-message">{errors.planting_date}</span>}
            </div>
          </div>
        </form>
      </div>
       {/* ToastContainer for displaying notifications */}
      <ToastContainer
        position="bottom-right"
        autoClose={7000} // Close the notification after 7 seconds
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
    
  );
};

export default EditCultivations;
