// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .App {
  text-align: center;
} */

/* body{
  overflow-x:hidden;

} */

.leaflet-draw-draw-polygon {
  display: none !important;
}
.leaflet-draw-draw-marker {
  display: none !important;
}
.leaflet-draw-draw-circle {
  display: none !important;
}
.leaflet-draw-draw-polyline {
  display: none !important;
}
.leaflet-draw-draw-circlemarker {
  display: none !important;
}
.leaflet-draw-edit-edit {
  display: none !important;
}
.leaflet-draw-edit-remove {
  display: none !important;
}

.leaflet-control-geosearch input {
  color: black;
}

.leaflet-custom-marker {
  /* Add any additional CSS styling here */
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 50%;
  border: 2px solid black;
  position: relative;
  transform: translate(-50%, -50%);
}

.content{
  position: relative;
  margin-left: 18.5vw;
  margin-top: 5vh;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;GAEG;;AAEH;;;GAGG;;AAEH;EACE,wBAAwB;AAC1B;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,wBAAwB;AAC1B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,wCAAwC;EACxC,WAAW;EACX,YAAY;EACZ,qBAAqB;EACrB,kBAAkB;EAClB,uBAAuB;EACvB,kBAAkB;EAClB,gCAAgC;AAClC;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;AACjB","sourcesContent":["/* .App {\n  text-align: center;\n} */\n\n/* body{\n  overflow-x:hidden;\n\n} */\n\n.leaflet-draw-draw-polygon {\n  display: none !important;\n}\n.leaflet-draw-draw-marker {\n  display: none !important;\n}\n.leaflet-draw-draw-circle {\n  display: none !important;\n}\n.leaflet-draw-draw-polyline {\n  display: none !important;\n}\n.leaflet-draw-draw-circlemarker {\n  display: none !important;\n}\n.leaflet-draw-edit-edit {\n  display: none !important;\n}\n.leaflet-draw-edit-remove {\n  display: none !important;\n}\n\n.leaflet-control-geosearch input {\n  color: black;\n}\n\n.leaflet-custom-marker {\n  /* Add any additional CSS styling here */\n  width: 20px;\n  height: 20px;\n  background-color: red;\n  border-radius: 50%;\n  border: 2px solid black;\n  position: relative;\n  transform: translate(-50%, -50%);\n}\n\n.content{\n  position: relative;\n  margin-left: 18.5vw;\n  margin-top: 5vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
