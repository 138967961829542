import "bootstrap-icons/font/bootstrap-icons.css";
import {
  CategoryScale,
  Chart,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title, Tooltip
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import Annotation from 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';
import i18n from 'i18next';
import $ from "jquery";
import 'leaflet/dist/leaflet.css';
import React, { useEffect, useState } from 'react';
import { Line, Scatter } from 'react-chartjs-2';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import getServerURL from "./Configuration.js";
import './CSSCustom/fieldsData.css';
import { usePageTitle } from './JS_Context/ContextTitle';
import { parseDateWithFallback, createDataset } from './Utils.js';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import clockImage from './img/ICON_CLOCK.png';
import dropImage from './img/ICON_DROP.png';
import thermometerImage from './img/ICON_THERMOMETER.png';
import cloudSunImage from './img/ICON_WEATHER_CLOUD_SUN.png';
import windImage from './img/ICON_WEATHER_WIND.png';

import originaLDelete from './img/ICON_PINAKAS_DELETE_GREEN.png';
import hoverDelete from './img/ICON_PINAKAS_DELETE_ORANGE.png';
import originalEdit from './img/ICON_PINAKAS_EDIT_GREEN.png';
import hoverEdit from './img/ICON_PINAKAS_EDIT_ORANGE.png';
import originalView from './img/ICON_PINAKAS_VIEW_GREEN.png';
import hoverView from './img/ICON_PINAKAS_VIEW_ORANGE.png';


const serverUrl = getServerURL();
Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, zoomPlugin, Annotation);

function FieldsData() {
  const [cookies, setCookie] = useCookies(['csrftoken', 'sidebarWeather']);
  const { t } = useTranslation();
  const { setPageTitle, setPageTitleIcon } = usePageTitle();
  const [isHoveredDelete, setIsHoveredDelete] = useState([]);
  const [isHoveredEdit, setIsHoveredEdit] = useState([]);
  const [isHoveredView, setIsHoveredView] = useState([]);
  const [soilAnalysisToDelete, setSoilAnalysisToDelete] = useState();
  const [leafAnalysisToDelete, setLeafAnalysisToDelete] = useState();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedDataType, setSelectedDataType] = useState('Meteo Helix-Wind');
  const [currentHour, setCurrentHour] = useState('');
  const [currentTemperature, setCurrentTemperature] = useState('');
  const [currentPrecipitation, setCurrentPrecipitation] = useState('');
  const [currentWindSpeed, setCurrentWindSpeed] = useState('');
  const [currentWindSpeedBeaufort, setCurrentWindSpeedBeaufort] = useState('');
  const [currentWindDirection, setCurrentWindDirection] = useState('');
  const [cultivations, setCultivations] = useState([]);
  const [fields, setFields] = useState([]);
  const [hasCultivations, setHasCultivations] = useState(null);
  const [selectedCultivation, setSelectedCultivation] = useState('');
  const [meteoHelixData,setMeteoHelixData] = useState({"hasData":false});
  const [meteoWindData,setMeteoWindData] = useState({"hasData":false});
  const [meteoAgData,setMeteoAgData] = useState({"hasData":false});
  //chart
  const [showLineChart, setShowLineChart] = useState(null);
  const [lineChartData, setLineChartData] = useState({});
  const [lineChartOptions, setLineChartOptions] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isFieldDataReady, setIsFieldDataReady] = useState(false);
  //scatter chart
  const [showScatterChart, setShowScatterChart] = useState(null);
  const [ScatterChartData, setScatterChartData] = useState({});
  const [ScatterChartOptions, setScatterChartOptions] = useState({});
  const [isLoading2, setIsLoading2] = useState(true);
  const [isLoadingHelixWind, setIsLoadingHelixWind] = useState(true);
  //Ag chart
  const [showAgLineChart, setShowAgLineChart] = useState(null);
  const [lineChartDataAg, setLineChartDataAg] = useState({});
  const [lineChartOptionsAg, setLineChartOptionsAg] = useState({});
  const [isLoadingAg, setIsLoadingAg] = useState(true);
  //Callendar
  const [selectedDates, setSelectedDates] = useState([null, null]);
  //Soil Analysis
  const [showSoilAnalysis, setShowSoilAnalysis] = useState(null);
  const [soilAnalysisHasData, setSoilAnalysisHasData] = useState(null);
  const [soilAnalysisTable, setSoilAnalysisList] = useState(null);
  //Leaf Analysis
  const [showLeafAnalysis, setShowLeafAnalysis] = useState(null);
  const [leafAnalysisHasData, setLeafAnalysisHasData] = useState(null);
  const [leafAnalysisTable, setLeafAnalysisList] = useState(null);

  const [isFullscreenAg, setIsFullscreenAg] = useState(false);
  const [isFullscreenWeather, setIsFullscreenWeather] = useState(false);
  const [isFullscreenWind, setIsFullscreenWind] = useState(false);

  //Initialize page
  useEffect(() => {
    const fetchData = async () => {
      try {
        await initCultivations();
        if (hasCultivations) {
          await initFieldData();
          setIsFieldDataReady(true);
        }
      } catch (error) {
        setIsFieldDataReady(false);
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [hasCultivations]); // eslint-disable-line react-hooks/exhaustive-deps
  // }, [hasCultivations, selectedDataType]);

  useEffect(() => {
    if (meteoHelixData["hasData"] !== null && isFieldDataReady) {
      createLineChart("Meteo Helix-Wind");
      setIsLoading(false);
    }
  }, [meteoHelixData["hasData"], isFieldDataReady]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (meteoAgData["hasData"] !== null && isFieldDataReady) {
      createScatterChart("Meteo Helix-Wind");
      setIsLoading2(false);
    }
  }, [meteoWindData["hasData"], isFieldDataReady]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (meteoWindData["hasData"] !== null && isFieldDataReady) {
      createLineChart2("Meteo AG");
      setIsLoadingAg(false);
    }
  }, [meteoAgData, isFieldDataReady, // eslint-disable-line react-hooks/exhaustive-deps
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedDataType === "Meteo Helix-Wind") {
      setShowAgLineChart(false)
    }
  }, [selectedDataType]);

  useEffect(() => {
    if (!isLoading && !isLoading2) {
      setIsLoadingHelixWind(false)
    }

  }, [isLoading, isLoading2]);

  useEffect(() => {
    const showCurrentHour = () => {
      const now = new Date();
      setCurrentHour(now.toLocaleTimeString(t('data.timelocale'), { hour: '2-digit', minute: '2-digit', hour12: false }));
    }
    showCurrentHour();
    // Update the currentHour every minute
    const hourintervalId = setInterval(showCurrentHour, 60000);
    // Clear the interval on component unmount
    return () => clearInterval(hourintervalId);
  })

  //Track selected cultivation /Get Cultivation Data
  useEffect(() => {
    if (selectedCultivation !== '') {
      const getCultivationData = () => {
        const limit = 1;
        const cultivationID = selectedCultivation;
        $.ajax({
          url: serverUrl + "/meteohelix/",
          type: 'get',
          data: {
            cultivation: cultivationID,
            limit: limit,
          },
          headers: {
            "Accept-Language": i18n.language || 'el',
            "X-CSRFToken": cookies.csrftoken,
            "Authorization": cookies.auth
          },
          xhrFields: {
            withCredentials: true,
            xsrfCookieName: 'csrftoken',
            xsrfHeaderName: 'X-CSRFToken'
          },
          crossDomain: true,
          success: function (data) {
            if (data.length > 0) {
              const kelvinTemperature = data[0].temperature;
              let celsiusTemperature = kelvinTemperature - 273.15;
              if(celsiusTemperature < -50){
                celsiusTemperature = data[0].temperature;
              }
              setCurrentTemperature(
                cookies.sidebarWeather?.[0]?.tavg !== null && cookies.sidebarWeather?.[0]?.tavg !== undefined ? 
                  cookies.sidebarWeather[0].tavg : 
                  celsiusTemperature.toFixed(2)
              );
              setCurrentPrecipitation(cookies.sidebarWeather?.[0]?.prcp !== null && cookies.sidebarWeather?.[0]?.prcp !== undefined ? 
                cookies.sidebarWeather[0].prcp : 
                0.0);
            }
          },
          error: function (error) {
            toast.error(t('data.error.humidity'))
            console.error('Error fetching meteohelix:', error);
          }
        });

        $.ajax({
          url: serverUrl + "/meteowind/",
          type: 'get',
          data: {
            cultivation: cultivationID,
            limit: limit,
          },
          headers: {
            "Accept-Language": i18n.language || 'el',
            "X-CSRFToken": cookies.csrftoken,
            "Authorization": cookies.auth
          },
          xhrFields: {
            withCredentials: true,
            xsrfCookieName: 'csrftoken',
            xsrfHeaderName: 'X-CSRFToken'
          },
          crossDomain: true,
          success: function (data) {
            if (data.length > 0) {
              const windspeedValue = cookies.sidebarWeather?.[0]?.wspd !== null && cookies.sidebarWeather?.[0]?.wspd !== undefined ? 
                cookies.sidebarWeather[0].wspd : 
                data[0].wind_Avg10;
              const windspeedBeaufort = mpsToBeaufort(windspeedValue)
              const windDirection = cookies.sidebarWeather?.[0]?.wdir !== null && cookies.sidebarWeather?.[0]?.wdir !== undefined ? 
                cookies.sidebarWeather[0].wdir : 
                data[0].wdir_Avg10;
              const cardinalDirection = degreesToCardinal(windDirection)
              setCurrentWindSpeed(windspeedValue * 3.6) //transform to km/h
              setCurrentWindDirection(cardinalDirection)
              setCurrentWindSpeedBeaufort(windspeedBeaufort)
            }
          },
          error: function (error) {
            toast.error(t('data.error.air'))
            console.error('Error fetching meteowind:', error);
          }
        });
      };
      // Call fetchData immediately
      getCultivationData();
      // Update the cultivation data every 20 minutes
      const intervalId = setInterval(getCultivationData, 1200000); //20min --> 60000 milliseconds = 1 minute
      // Clear the interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [selectedCultivation]); // eslint-disable-line react-hooks/exhaustive-deps

  //find the fields of the cultivations
  useEffect(() => {
    const fetchFieldData = async () => {
      try {
        const promises = cultivations.map(async (cultivation) => {
          return await fetchCultivationFieldData(cultivation.id);
        });

        const allFields = await Promise.all(promises);
        const flattenedFields = allFields.flat(); // Flatten the array of arrays into a single array
        setFields(flattenedFields);
      } catch (error) {
        console.error('Error fetching field data:', error);
      }
    };

    if (cultivations.length > 0) {
      fetchFieldData();
    }
  }, [cultivations]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    fetchFieldDetails(0)
  }, [fields]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Reset date inputs when selectedCultivation changes
    setSelectedDates([null, null]);
  }, [selectedCultivation]);


  useEffect(() => {
    const fetchDataDateRange = async () => {
      if (selectedDates[0] !== null && selectedDates[0] !== undefined &&
        selectedDates[1] !== null && selectedDates[1] !== undefined) {
        try {
          await initFieldData({ FromToDates: true });
          setIsFieldDataReady(true);
        }
        catch (error) {
          setIsFieldDataReady(false);
          setIsLoadingAg(false);
          console.error('Error fetching data:', error);
        }
      }
    };
    setIsLoadingAg(true);
    fetchDataDateRange().then(() =>{setIsLoadingAg(false);});
  }, [selectedDates, selectedCultivation]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setPageTitle("data.title");
    setPageTitleIcon("/img/ICON_DATA_GREEN.png");
  }, [])

  // Delete icon hover handle
  const handleMouseEnterDelete = (index) => {
    const newHoverState = [...isHoveredDelete];
    newHoverState[index] = true;
    setIsHoveredDelete(newHoverState);
  };

  const handleMouseLeaveDelete = (index) => {
    const newHoverState = [...isHoveredDelete];
    newHoverState[index] = false;
    setIsHoveredDelete(newHoverState);
  };

  const handleDeleteSoilAnalysisClick = (soilAnalysis) => {
    setSoilAnalysisToDelete(soilAnalysis);
    setShowDeleteConfirmation(true);
  };

  const handleDeleteLeafAnalysisClick = (leafAnalysis) => {
    setLeafAnalysisToDelete(leafAnalysis);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete = () => {
    if (!soilAnalysisToDelete && !leafAnalysisToDelete) {
      return;
    }
    /*const fieldId = fieldToDelete.id;
    // Step 1: Send DELETE request to the server
    $.ajax({
      url: serverUrl+"/fields/"+fieldId+"/",
      type: 'delete',
      headers: {
          "Accept-Language": i18n.language || 'el',
          "X-CSRFToken": cookies.csrftoken,
          "Authorization": cookies.auth
      },
      xhrFields: {
          withCredentials: true,
          xsrfCookieName: 'csrftoken',
          xsrfHeaderName: 'X-CSRFToken'
      },
      crossDomain: true,
      dataType: 'json',
      success: function (data) {
          // Step 2: Remove the field from local state
          const updatedFields = fields.filter((field) => field.id !== fieldId);
          setFields(updatedFields);
  
          // Step 3: Remove the corresponding fieldDetails from local state
          const updatedFieldDetails = fieldDetails.filter((detail) => detail.id !== fieldId);
          setFieldDetails(updatedFieldDetails);
  
          // Step 4: Remove the field from the map
          setFieldIdForDelete(fieldId);
  
          // Step 5: clear the selection after deletion
          setSelectedRow(null);
          setSelectedFieldName(null);
          toast.success(
            t("fields.form.delete_field_success")
            .replace("\{\{field_name\}\}", fieldToDelete.name)
          );
      },
      error: function (error) {
        toast.error(
          t("fields.form.delete_field_fail")
          .replace("\{\{field_name\}\}", fieldToDelete.name)+` ${error.message}`
        );
      }
  });*/
    setShowDeleteConfirmation(false);
  };

  const DeleteConfirmationModal = ({ onCancel, onConfirm }) => {
    return (
      <div className='delete-confirmation-overlay'>
        <div className="deleteModal-background">
          <div className="delete-confirmation-modal">
            <p>
              {soilAnalysisToDelete ? 
                t("analysis.soil.delete.confirmation") : 
                t("analysis.leaf.delete.confirmation")}
              <span className="field-name">
                {soilAnalysisToDelete ? 
                  soilAnalysisToDelete.id : 
                  leafAnalysisToDelete?.id}
              </span>
              {t("common.punctuation.question")}
            </p>
            <button onClick={onConfirm}>{t("common.form.confirm")}</button>
            <button onClick={onCancel}>{t("common.form.cancel")}</button>
          </div>
        </div>
      </div>
    );
  };

  // Edit icon hover handle
  const handleMouseEnterEdit = (index) => {
    const newHoverState = [...isHoveredEdit];
    newHoverState[index] = true;
    setIsHoveredEdit(newHoverState);
  };
  const handleMouseLeaveEdit = (index) => {
    const newHoverState = [...isHoveredEdit];
    newHoverState[index] = false;
    setIsHoveredEdit(newHoverState);
  };

  // View icon hover handle
  const handleMouseEnterView = (index) => {
    const newHoverState = [...isHoveredView];
    newHoverState[index] = true;
    setIsHoveredView(newHoverState);
  };

  const handleMouseLeaveView = (index) => {
    const newHoverState = [...isHoveredView];
    newHoverState[index] = false;
    setIsHoveredView(newHoverState);
  };

  const degreesToCardinal = (degrees) => {
    // Ensure the degrees are positive and within the range [0, 360)
    degrees = (degrees % 360 + 360) % 360;
    const cardinalDirections = [
      t('data.cardinal.N'),
      t('data.cardinal.NE'),
      t('data.cardinal.E'),
      t('data.cardinal.SE'),
      t('data.cardinal.S'),
      t('data.cardinal.SW'),
      t('data.cardinal.W'),
      t('data.cardinal.NW')
    ];
    // Calculate the index of the cardinal direction
    const index = Math.floor((degrees + 22.5) / 45) % 8;
    return cardinalDirections[index];
  }

  const mpsToBeaufort = (windSpeed) => {
    const thresholds = [0.3, 1.6, 3.4, 5.5, 8.0, 10.8, 13.9, 17.2, 20.8, 24.5, 28.5, 32.7];
    const beaufortValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    for (let i = 0; i < thresholds.length; i++) {
      if (windSpeed < thresholds[i]) {
        return beaufortValues[i];
      }
    }
    // If wind speed is greater than the highest threshold, return the maximum Beaufort value
    return beaufortValues[thresholds.length];
  };

  const initCultivations = async () => {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: serverUrl + "/cultivations/",
        type: 'get',
        headers: {
          "Accept-Language": i18n.language || 'el',
          "X-CSRFToken": cookies.csrftoken,
          "Authorization": cookies.auth
        },
        xhrFields: {
          withCredentials: true,
          xsrfCookieName: 'csrftoken',
          xsrfHeaderName: 'X-CSRFToken'
        },
        crossDomain: true,
        success: function (data) {
          if (data.length > 0) {
            // Set the first cultivation as the selected option
            setSelectedCultivation(data[0].id);
            setCultivations(data);
            setHasCultivations(true);
            resolve();
          } else {
            setHasCultivations(false);
            resolve();
          }
        },
        error: function (error) {
          toast.error(t('data.error.cultivations'))
          console.error('Error fetching cultivations:', error);
          reject(error);
        },
      });
    });
  };

  const fetchCultivationFieldData = (cultivationID) => {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `${serverUrl}/cultivations/${cultivationID}/`,
        type: "get",
        headers: {
          "Accept-Language": i18n.language || 'el',
          "X-CSRFToken": cookies.csrftoken,
          "Authorization": cookies.auth,
        },
        xhrFields: {
          withCredentials: true,
          xsrfCookieName: "csrftoken",
          xsrfHeaderName: "X-CSRFToken",
        },
        crossDomain: true,
        success: function (data) {
          resolve(data.field);
        },
        error: function (error) {
          reject(error);
        },
      });
    });
  };

  const initFieldData = async (FromToDates = false) => {

    // const threeMonthsAgo = new Date();
    // threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
    // const fromValue = threeMonthsAgo.toISOString();
    // const toValue = new Date().toISOString();
    // const fromValue = '2023-12-12T13:40:00Z';
    // const toValue = '2023-11-12T13:40:00Z';
    setIsFieldDataReady(false)
    const fetchData = (url) => {
      // const [fromDate, toDate] = FromToDates || [];
      if (FromToDates) {
        const fromValue = `${selectedDates[0]}T00:00:00Z`;
        const toValue = `${selectedDates[1]}T23:59:59Z`;

        const cultivationID = selectedCultivation;
        return new Promise((resolve, reject) => {
          $.ajax({
            url: serverUrl + url,
            type: 'get',
            data: {
              from: fromValue,
              to: toValue,
              cultivation: cultivationID,
            },
            headers: {
              "Accept-Language": i18n.language || 'el',
              "X-CSRFToken": cookies.csrftoken,
              "Authorization": cookies.auth
            },
            xhrFields: {
              withCredentials: true,
              xsrfCookieName: 'csrftoken',
              xsrfHeaderName: 'X-CSRFToken'
            },
            crossDomain: true,
            success: (data) => resolve(data),
            error: (error) => reject(error),
          });
        });
      } else {
        const limit = 600;
        const cultivationID = selectedCultivation;
        return new Promise((resolve, reject) => {
          $.ajax({
            url: serverUrl + url,
            type: 'get',
            data: {
              limit: limit,
              cultivation: cultivationID,
            },
            headers: {
              "Accept-Language": i18n.language || 'el',
              "X-CSRFToken": cookies.csrftoken,
              "Authorization": cookies.auth
            },
            xhrFields: {
              withCredentials: true,
              xsrfCookieName: 'csrftoken',
              xsrfHeaderName: 'X-CSRFToken'
            },
            crossDomain: true,
            success: (data) => resolve(data),
            error: (error) => reject(error),
          });
        });
      }
    };

    const handleMeteoHelixData = (data) => {
      if (data.length > 0) {
        let temperatureList = [];
        let rainList = [];
        let humidityList = [];
        let createdList = [];

        data.sort(function(a,b){return new Date(a.time) - new Date(b.time)}).forEach((entry) => {
          let rawTemperature = entry.temperature;
          let celsiusTemperature = rawTemperature - 273.15;
          if(celsiusTemperature < -50){
            celsiusTemperature = entry.temperature;
          }
          temperatureList.push(celsiusTemperature.toFixed(2));
          humidityList.push(entry.humidity);
          rainList.push(entry.rain);
          createdList.push((new Date(entry.time)).toISOString());
        });
        setMeteoHelixData({
          "temp": temperatureList,
          "mois": humidityList,
          "rain": rainList,
          "time": createdList,
          "hasData": true
        });
      } else {
        setMeteoHelixData({
          "temp": [],
          "mois": [],
          "rain": [],
          "time": [],
          "hasData": false
        });
      }
    };

    const handleMeteoWindData = (data) => {
      if (data.length > 0) {
        let windSpeedList = [];
        let windDirList = [];
        let createdList = [];

        data.sort(function(a,b){return new Date(a.time) - new Date(b.time)}).forEach((entry) => {
          windSpeedList.push(mpsToBeaufort(entry.wind_Avg10));
          windDirList.push(entry.wdir_Avg10);
          createdList.push((new Date(entry.time)).toISOString());
        });
        setMeteoWindData({
          "speed": windSpeedList,
          "dir": windDirList,
          "time": createdList,
          "hasData": true
        });
      } else {
        setMeteoWindData({
          "speed": [],
          "dir": [],
          "time": [],
          "hasData": false
        });
      }
    };

    const handleMeteoAgData = (data) => {
      if (data.length > 0) {
        let solidTemp1List = [];
        let solidTemp2List = [];
        let solidTemp3List = [];
        let solidMoisture1List = [];
        let solidMoisture2List = [];
        let solidMoisture3List = [];
        let createdList = [];

        data.sort(function(a,b){return new Date(a.time) - new Date(b.time)}).forEach((entry) => {
          solidTemp1List.push(entry.soil_temp1_C?.toFixed(2) ?? 0.0);
          solidTemp2List.push(entry.soil_temp2_C?.toFixed(2) ?? 0.0);
          solidTemp3List.push(entry.soil_temp3_C?.toFixed(2) ?? 0.0);
          solidMoisture1List.push((entry.soil_moisture1 * 100 ?? 0.0).toFixed(2));
          solidMoisture2List.push((entry.soil_moisture2 * 100 ?? 0.0).toFixed(2));
          solidMoisture3List.push((entry.soil_moisture3 * 100 ?? 0.0).toFixed(2));
          createdList.push((new Date(entry.time)).toISOString());
        });
        // Set state variables with the obtained data
        setMeteoAgData({
          "temp1":solidTemp1List,
          "temp2":solidTemp2List,
          "temp3":solidTemp3List,
          "mois1":solidMoisture1List,
          "mois2":solidMoisture2List,
          "mois3":solidMoisture3List,
          "time":createdList,
          "hasData":true
        });
      } else {
        setMeteoAgData({
          "temp1":[],
          "temp2":[],
          "temp3":[],
          "mois1":[],
          "mois2":[],
          "mois3":[],
          "time":[],
          "hasData":true
        })
      }
    };

    const handleSoilAnalysisData = (data) => {
      if (data.length > 0) {
        setSoilAnalysisHasData(true);
        const soilAnalysisList = [];

        data.forEach((entry) => {
          const soilAnalysisID = entry.id;
          const soilAnalysisDateCreated = new Date(entry.created);
          const soilAnalysisDateModified = new Date(entry.modified);
          const formattedDateCreated = soilAnalysisDateCreated.toLocaleDateString(i18n.language || 'el', {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          });
          const formattedHourCreated = soilAnalysisDateCreated.toLocaleTimeString(i18n.language || 'el', {
            hour: "2-digit",
            minute: "numeric",
            hour12: false,
          });
          const formattedDateTimeCreated = `${formattedDateCreated} ${formattedHourCreated}`;

          const formattedDateModified = soilAnalysisDateModified.toLocaleDateString(i18n.language || 'el', {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          });
          const formattedHourModified = soilAnalysisDateModified.toLocaleTimeString(i18n.language || 'el', {
            hour: "2-digit",
            minute: "numeric",
            hour12: false,
          });
          const formattedDateTimeModified = `${formattedDateModified} ${formattedHourModified}`;

          soilAnalysisList.push({
            "id": soilAnalysisID,
            "created": formattedDateTimeCreated,
            "modified": formattedDateTimeModified
          });
        });
        setSoilAnalysisList(soilAnalysisList);
      } else {
        setSoilAnalysisHasData(false);
      }
    };

    const handleLeafAnalysisData = (data) => {
      if (data.length > 0) {
        setLeafAnalysisHasData(true);
        const LeafAnalysisList = [];

        data.forEach((entry) => {
          const LeafAnalysisID = entry.id;
          const LeafAnalysisDateCreated = new Date(entry.created);
          const LeafAnalysisDateModified = new Date(entry.modified);
          const formattedDateCreated = LeafAnalysisDateCreated.toLocaleDateString(i18n.language || 'el', {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          });
          const formattedHourCreated = LeafAnalysisDateCreated.toLocaleTimeString(i18n.language || 'el', {
            hour: "2-digit",
            minute: "numeric",
            hour12: false,
          });
          const formattedDateTimeCreated = `${formattedDateCreated} ${formattedHourCreated}`;

          const formattedDateModified = LeafAnalysisDateModified.toLocaleDateString(i18n.language || 'el', {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          });
          const formattedHourModified = LeafAnalysisDateModified.toLocaleTimeString(i18n.language || 'el', {
            hour: "2-digit",
            minute: "numeric",
            hour12: false,
          });
          const formattedDateTimeModified = `${formattedDateModified} ${formattedHourModified}`;

          LeafAnalysisList.push({
            "id": LeafAnalysisID,
            "created": formattedDateTimeCreated,
            "modified": formattedDateTimeModified
          });
        });
        setLeafAnalysisList(LeafAnalysisList);
      } else {
        setLeafAnalysisHasData(false);
      }
    };

    // Fetch data from different sources
    const fetchMeteoHelixData = fetchData('/meteohelix/');
    const fetchMeteoWindData = fetchData('/meteowind/');
    const fetchMeteoAgData = fetchData('/meteoag/');
    const fetchSoilAnalysisData = fetchData('/cultivations/' + selectedCultivation + '/soil-analysis/');
    const fetchLeafAnalysisData = fetchData('/cultivations/' + selectedCultivation + '/leaf-analysis/');

    return Promise.all([
      fetchMeteoHelixData,
      fetchMeteoWindData,
      fetchMeteoAgData,
      fetchSoilAnalysisData,
      fetchLeafAnalysisData
    ]).then(([meteoHelixData, meteoWindData, meteoAgDataRaw, soilanalysis, leafanalysis]) => {
      handleMeteoHelixData(meteoHelixData);
      handleMeteoWindData(meteoWindData);
      handleMeteoAgData(meteoAgDataRaw);
      handleSoilAnalysisData(soilanalysis);
      handleLeafAnalysisData(leafanalysis);
    })
      .catch((error) => {
        // Handle error
        console.error('Error fetching data:', error);
      });
  };

  //Charts
  const createLineChart2 = (dataType) => {
    if (dataType === 'Meteo AG') {
      if (meteoAgData["hasData"]) {
        let datasets = {
          "temp1":[],
          "temp2":[],
          "temp3":[],
          "mois1":[],
          "mois2":[],
          "mois3":[],
        }
        meteoAgData["time"].map((date, index) => {
          datasets["temp1"].push({"x":date,"y":meteoAgData["temp1"][index]});
          datasets["temp2"].push({"x":date,"y":meteoAgData["temp2"][index]});
          datasets["temp3"].push({"x":date,"y":meteoAgData["temp3"][index]});
          datasets["mois1"].push({"x":date,"y":meteoAgData["mois1"][index]});
          datasets["mois2"].push({"x":date,"y":meteoAgData["mois2"][index]});
          datasets["mois3"].push({"x":date,"y":meteoAgData["mois3"][index]});
        });

        if (selectedDataType === 'Meteo AG') {
          setShowAgLineChart(true);
        }
        setLineChartDataAg(
          {
            datasets: [
              createDataset(t('data.label.temperature1'), datasets["temp1"], 'line', 234,132,10, 'humidity'),
              createDataset(t('data.label.temperature2'), datasets["temp2"], 'line', 184,92,8, 'humidity',true),
              createDataset(t('data.label.temperature3'), datasets["temp3"], 'line', 194,52,6, 'humidity',true),
              createDataset(t('data.label.humidity1'), datasets["mois1"], 'line', 0,190,234, 'humidity'),
              createDataset(t('data.label.humidity2'), datasets["mois2"], 'line', 0,160,204, 'humidity',true),
              createDataset(t('data.label.humidity3'), datasets["mois3"], 'line', 0,130,174, 'humidity',true),
            ],
          }
        );

        const Ag_line_chart_options = {
          scales: {
            x: {
              type: 'time',
              time: { 
                unit: 'hour',
                displayFormats: {
                  hour: 'dd/MM/yy HH:mm',
                },
              },
              min: meteoAgData["hasData"] ? meteoAgData["time"][0] : undefined,
              max: meteoAgData["hasData"] ? meteoAgData["time"][meteoAgData["time"].length - 1] : undefined,
            },
            humidity: {
              type: 'linear',
              position: 'left',
              scaleLabel: {
                display: true,
                labelString: t('data.label.humidity'),
              },
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
              },
              drawBorder: false,
            },
          },
          plugins: {
            legend: {
              display: true,
              position: 'top',
            },
            tooltip: {
              mode: 'index',
              intersect: false,
            },
            zoom: {
              pan: {
                enabled: true,
                mode: 'x', // Enable horizontal panning only
              },
              zoom: {
                wheel: {
                  enabled: true,
                },
                pinch: {
                  enabled: true,
                },
                mode: 'x',
              },
            },
            annotation: {
              //drawTime: 'beforeDatasetsDraw',
              annotations: [
                {
                  type: 'line',
                  mode: 'horizontal',
                  scaleID: 'humidity',
                  value: 32.99,
                  borderColor: '#dc3545',
                  borderWidth: 2,
                  borderDash: [5, 5],
                  borderDashOffset: 0,
                  label: {
                    content: t('data.label.fullmoisture'),
                    enabled: true,
                    position: 'left',
                    font: {
                      size: 12,
                      weight: '600',
                      color: 'rgba(255, 245, 244, 0.5)',
                      family: "Manrope",
                      lineHeight: 1.5,
                    },
                    backgroundColor: 'rgba(220, 53, 69, 0.8)',
                  },
                },
                {
                  type: 'line',
                  mode: 'horizontal',
                  scaleID: 'humidity',
                  value: 16.13,
                  borderColor: '#128068',
                  borderWidth: 2,
                  borderDash: [5, 5],
                  borderDashOffset: 0,
                  label: {
                    content: t('data.label.pwp'),
                    enabled: true,
                    position: 'left',
                    font: {
                      size: 12,
                      weight: '600',
                      family: "Manrope",
                      color: 'rgba(18, 128, 104, 0.5)',
                      lineHeight: 1.5,
                    },
                    backgroundColor: 'rgba(18, 128, 104, 0.8)',
                  },
                },
              ],
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          barPercentage: 0.2,
          categoryPercentage: 0.8,
        };
        setLineChartOptionsAg(Ag_line_chart_options);
      } else {
        setShowAgLineChart(false);
      }
    }
  };


  //Scatter chart (MeteoWind)
  const createScatterChart = (dataType) => {
    if (dataType === 'Meteo Helix-Wind') {
      if (meteoWindData["hasData"]) {
        const windSpeedData = meteoWindData["time"].map((date, index) => {
          const formattedDate = parseDateWithFallback(date);
          if (isNaN(formattedDate.getTime())) {
            console.error(`Invalid date format for index ${index}: ${date}`);
            return null;
          }
  
          const windDirection = parseFloat(meteoWindData["dir"][index]);
  
          return {
            x: formattedDate,
            y: parseFloat(meteoWindData["speed"][index]),
            direction: windDirection, // Store direction for rotation
          };
        });
  
        if (selectedDataType === 'Meteo Helix-Wind') {
          setShowScatterChart(true);
        }
  
        const scatter_chart_data = {
          datasets: [
            {
              label: t('data.label.speed'),
              data: windSpeedData,
              borderColor: 'rgba(234, 132, 10, 0.7)',
              backgroundColor: 'rgba(234, 132, 10, 0.8)',
              pointRadius: 0, // Hide default round points
              yAxisID: 'y',
            },
          ],
        };
  
        setScatterChartData(scatter_chart_data);
  
        const scatter_chart_options = {
          scales: {
            x: {
              type: 'time',
              time: {
                unit: 'hour',
                displayFormats: {
                  hour: 'dd/MM/yyyy HH:mm',
                },
              },
              min: windSpeedData.length > 0 ? windSpeedData[0].x : undefined,
              max: windSpeedData.length > 0 ? windSpeedData[windSpeedData.length - 1].x : undefined,
            },
            y: { beginAtZero: true },
          },
          plugins: {
            zoom: {
              pan: { enabled: true, mode: 'x' },
              zoom: { wheel: { enabled: true }, pinch: { enabled: true }, mode: 'x' },
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  const dataPoint = context.raw;
                  const speed = dataPoint.y.toFixed(2); // Wind speed
                  const direction = degreesToCardinal(dataPoint.direction); // Convert degrees to text
                  const date = new Date(dataPoint.x).toLocaleString("el-GR", { 
                    day: "2-digit", month: "2-digit", year: "numeric", 
                    hour: "2-digit", minute: "2-digit", hour12: false 
                  }); // Greek date format
        
                  return `Ταχύτητα: ${speed} km/h\nΔιεύθυνση: ${direction}\nΗμερομηνία: ${date}`;
                },
              },
            },
          },
          animation: {
            onComplete: (animation) => {
              const { ctx } = animation.chart;
        
              animation.chart.data.datasets.forEach((dataset) => {
                const meta = animation.chart.getDatasetMeta(animation.chart.data.datasets.indexOf(dataset));
        
                meta.data.forEach((point, index) => {
                  const { x, y } = point.getCenterPoint();
                  const windDirection = dataset.data[index].direction;
        
                  ctx.save();
                  ctx.translate(x, y);
                  ctx.rotate((windDirection * Math.PI) / 180);
                  ctx.font = '20px Bootstrap Icons';
                  ctx.fillText("⮝", 0, 0); // Wind arrow (rotated)
                  ctx.restore();
                });
              });
            },
          },
          responsive: true,
          maintainAspectRatio: false,
        };
        
  
        setScatterChartOptions(scatter_chart_options);
      } else {
        setShowScatterChart(false);
      }
    }
  };  

  //Linchart stuff (MeteoHelix)
  const createLineChart = (dataType) => {
    if (dataType === 'Meteo Helix-Wind') {
      if (meteoHelixData["hasData"]) {
        let datasets = {
          "temp":[],
          "rain":[],
          "mois":[],
        }
        meteoHelixData["time"].map((date, index) => {
          datasets["temp"].push({"x":date,"y":meteoHelixData["temp"][index]});
          datasets["rain"].push({"x":date,"y":meteoHelixData["rain"][index]});
          datasets["mois"].push({"x":date,"y":meteoHelixData["mois"][index]});
        });
        if (selectedDataType === 'Meteo Helix-Wind') {
          setShowLineChart(true)
        }
        const line_chart_data = {
          datasets: [
            {
              label: t('data.label.temperature'),
              data: datasets["temp"],
              fill: 'origin',
              borderColor: 'rgba(234, 132, 10, 0.7)',
              backgroundColor: 'rgba(234, 132, 10, 0.2)',
              yAxisID: 'temperature',
              pointRadius: 0,
              pointHoverRadius: 0,
              cubicInterpolationMode: 'default',
              tension: 0.2,
            },
            {
              label: t('data.label.humidity'),
              data: datasets["mois"],
              fill: 'origin',
              borderColor: 'rgba(0, 234, 51, 0.7)',
              backgroundColor: 'rgba(0, 234, 98, 0.2)',
              yAxisID: 'humidity',
              pointRadius: 0,
              pointHoverRadius: 0,
              cubicInterpolationMode: 'default',
              tension: 0.2,
            },
            {
              label: t('data.label.rain'),
              data: datasets["rain"],
              fill: 'origin',
              borderColor: 'rgba(0, 190, 234, 0.7)',
              backgroundColor: 'rgba(0, 190, 234, 0.2)',
              yAxisID: 'rain',
              pointRadius: 0,
              pointHoverRadius: 0,
              cubicInterpolationMode: 'default',
              tension: 0.2,
            },
          ],
        };
        setLineChartData(line_chart_data)

        const line_chart_options = {
          scales: {
            x: {
              type: 'time',
              time: { 
                unit: 'hour',
                displayFormats: {
                  hour: 'dd/MM/yy HH:mm',
                },
              },
              labels: meteoHelixData["time"],
              min: meteoHelixData["time"].length > 0 ? meteoHelixData["time"][0] : undefined,
              max: meteoHelixData["time"].length > 0 ? meteoHelixData["time"][meteoHelixData["time"].length - 1] : undefined,
            },
            y: {
              id: 'temperature',
              type: 'linear',
              position: 'left',
              scaleLabel: {
                display: true,
                labelString: t('data.label.temperature'),
              },
              ticks: {
                display: false,
                beginAtZero: true,
              },
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
              },
              drawBorder: false,
            },
            y1: {
              id: 'humidity',
              type: 'linear',
              position: 'right',
              scaleLabel: {
                display: true,
                labelString: t('data.label.humidity'),
              },
              ticks: {
                display: false,
                beginAtZero: true,
              },
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
              },
              drawBorder: false,
            },
            y2: {
              id: 'rain',
              type: 'linear',
              position: 'right',
              scaleLabel: {
                display: true,
                labelString: t('data.label.rain'),
              },
              ticks: {
                display: false,
                beginAtZero: true,
              },
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
              },
              drawBorder: false,
            },
          },
          plugins: {
            legend: {
              display: true,
              position: 'top',
              labels: {
                boxWidth: 36,
                padding: 40,
              },
            },
            tooltip: {
              mode: 'index',
              intersect: false,
            },
            zoom: {
              pan: {
                enabled: true,
                mode: 'x', // Enable horizontal panning only
              },
              zoom: {
                wheel: {
                  enabled: true,
                },
                pinch: {
                  enabled: true,
                },
                mode: 'x',
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          // height: 200,
        };
        setLineChartOptions(line_chart_options)
      } else {
        setShowLineChart(false)
      }

    }
  }

  const handleDataTypeToggle = (dataType) => {
    setSelectedDataType(dataType);
    if (dataType === 'Meteo Helix-Wind') {
      // createLineChart('Meteo Helix')
      setShowAgLineChart(false)
      setShowSoilAnalysis(false);
      setShowLeafAnalysis(false);
      if (meteoHelixData["hasData"]) {
        setShowLineChart(true)
      }
      else {
        setShowLineChart(false)
      }
      if (meteoWindData["hasData"]) {
        setShowScatterChart(true)
      }
      else {
        setShowScatterChart(false)
      }
    } else if (dataType === 'Meteo AG') {
      // createLineChart('Meteo Ag')
      setShowLineChart(false)
      setShowScatterChart(false)
      setShowSoilAnalysis(false);
      setShowLeafAnalysis(false);
      if (meteoAgData["hasData"]) {
        setShowAgLineChart(true)
      }
      else {
        setShowAgLineChart(false)
      }
    } else if (dataType === 'Soil Analysis') {
      setShowLineChart(false)
      setShowScatterChart(false)
      setShowAgLineChart(false)
      setShowLeafAnalysis(false);
      setShowSoilAnalysis(true);
    } else if (dataType === 'Leaf Analysis') {
      setShowLineChart(false)
      setShowScatterChart(false)
      setShowAgLineChart(false)
      setShowSoilAnalysis(false);
      setShowLeafAnalysis(true);
    }
  };

  const fetchFieldDetails = async (cultivationIndex) => {
    try {
      const fieldKeys = Object.keys(fields);
      const fieldCount = fieldKeys.length;

      if (cultivationIndex !== -1 && cultivationIndex < fieldCount) {
        const fieldId = fields[cultivationIndex].id;
        try {
          const data = await $.ajax({
            url: `${serverUrl}/fields/${fieldId}`,
            type: "get",
            headers: {
              "Accept-Language": i18n.language || 'el',
              "X-CSRFToken": cookies.csrftoken,
              Authorization: cookies.auth,
            },
            xhrFields: {
              withCredentials: true,
              xsrfCookieName: "csrftoken",
              xsrfHeaderName: "X-CSRFToken",
            },
            crossDomain: true,
          });
          if (data) {
            setCookie('sidebarWeather', data.weather, { path: '/' });
          }
        } catch (error) {
          console.error("Error fetching field details:", error);
        }
      }
    } catch (error) {
      console.error("Error fetching field details:", error);
    }
  };

  const handleCultivationChange = async (cultivationID) => {
    let cultivationIndex = -1;

    // Find the index of the selected cultivation using a for loop
    for (let i = 0; i < cultivations.length; i++) {
      if (cultivations[i].id === selectedCultivation) {
        cultivationIndex = i;
        fetchFieldDetails(cultivationIndex)
        break;
      }
    }
    setSelectedCultivation(cultivationID);


    try {
      await initFieldData();
      setIsFieldDataReady(true);
      // Introduce a delay before creating the chart
      // Adjust the delay time as needed (e.g., 500 milliseconds)
      // setTimeout(async () => {
      //   await createLineChart(selectedDataType);
      // }, 500);
    } catch (error) {
      setIsFieldDataReady(false);
      console.error("Error handling cultivation change:", error);
    }
  };

  const handleDateChange = (index, date) => {
    setSelectedDates((prevDates) => {
      const newDates = [...prevDates];
      newDates[index] = date;
      return newDates;
    });
  };

  return (
    <div className="content data_content">
      <div className="first-row-container">
        {/* 1st column 
          <div>
            <img src={dataImage} alt="Δεδομένα αγρού" className="icon-data" />
            <span className='data-title'>Δεδομένα Αγρού</span>
          </div>*/}

        {/* 2nd column */}
        <div className="data-button-container">
          <button
            className={`data-button ${selectedDataType === 'Meteo AG' ? 'data-button-active' : ''}`}
            onClick={() => handleDataTypeToggle('Meteo AG')}
          >
            {t("data.tabs.ground_sensors")}
          </button>
          <button
            className={`data-button ${selectedDataType === 'Meteo Helix-Wind' ? 'data-button-active' : ''}`}
            onClick={() => handleDataTypeToggle('Meteo Helix-Wind')}
          >
            {t("data.tabs.meteo")}
          </button>
          <button
            className={`data-button ${selectedDataType === 'Soil Analysis' ? 'data-button-active' : ''}`}
            onClick={() => handleDataTypeToggle('Soil Analysis')}
          >
            {t("data.tabs.soil")}
          </button>
          <button
            className={`data-button ${selectedDataType === 'Leaf Analysis' ? 'data-button-active' : ''}`}
            onClick={() => handleDataTypeToggle('Leaf Analysis')}
          >
            {t("data.tabs.leaf")}
          </button>
          {/*<button
              className="data-button"
              disabled
            >
              {t("data.tabs.carbon")} 
            </button>*/}
        </div>

        {/* 3rd column */}
        <div>
          <img src={clockImage} alt="Clock" className="icon clock-icon" />
          <span className='current-hour'>{currentHour}</span>
        </div>

        {/* 4th column */}
        <div>
          <img src={thermometerImage} alt="Thermometer" className="icon thermometer-icon" />
          {currentTemperature !== '' ? (
            <span className='current-temp'>{currentTemperature}°C</span>
          ) : (
            <span className='current-temp'>{currentTemperature}</span>
          )}
        </div>

        {/* 5th column */}
        <div>
          <img src={dropImage} alt="Raindrop" className="icon raindrop-icon" />
          {currentPrecipitation !== '' ? (
            <span className='current-humidity'>{currentPrecipitation} mm</span>
          ) : (
            <span className='current-humidity'>{currentPrecipitation}</span>
          )}
        </div>

        {/* 6th column */}
        <div className="wind-info-container">
          <div>
            <img src={windImage} alt="Wind" className="icon wind-icon" />
          </div>
          <div>
            {currentWindSpeed !== '' ? (
              <>
                <span className='current-windspeed'>{currentWindSpeed} {t('data.label.kmh')} {currentWindDirection}</span>
                <span className='current-windspeed-beaufort'>{currentWindSpeedBeaufort} {t('data.label.beaufort')} </span>
              </>
            ) : (
              <>
                <span className='current-windspeed'>{currentWindSpeed}  {currentWindDirection}</span>
                <span className='current-windspeed-beaufort'>{currentWindSpeedBeaufort} </span>
              </>
            )}
          </div>
        </div>

        {/* 7th column */}
        <div>
          <img src={cloudSunImage} alt="Cloud" className="icon cloud-icon" />
        </div>

        {/* 8th column */}
        <div>
          <select
            className="cultivation-dropdown"
            value={selectedCultivation}
            onChange={(e) => handleCultivationChange(e.target.value)}
          >
            {cultivations.map((cultivation) => (
              <option key={cultivation.id} value={cultivation.id}>
                {cultivation.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="second-row-container">
        {selectedDataType === "Meteo Helix-Wind" ? (
          <div className="data-title">{t("data.tabs.meteo")}</div>
        ) : selectedDataType === "Meteo AG" ? (
          <div className="data-title">{t("data.tabs.ground_sensors")}</div>
        ) : selectedDataType === "Soil Analysis" ? (
          <div className="data-title">{t("data.tabs.soil")}</div>
        ) : selectedDataType === "Leaf Analysis" ? (
          <div className="data-title">{t("data.tabs.leaf")}</div>
        ) : <div className="data-title"></div>}

      </div>

      {/* Third Row */}
      <div className="third-row-container">
        <div className="custom-date-range-picker">
          {/* <label>Επιλογή εύρους ημερομηνίας:</label> */}
          <div className="date-picker-container">
            <input
              type="date"
              value={selectedDates[0] || ''}
              onChange={(e) => handleDateChange(0, e.target.value)}
            />
            <span className="span-range-name">{t("data.label.to")}</span>
            <input
              type="date"
              value={selectedDates[1] || ''}
              onChange={(e) => handleDateChange(1, e.target.value)}
            />
            {isLoadingAg && <div className="spinner"></div>}
          </div>
        </div>
      </div>

      {/* Fourth Row */}
      <div className="fourth-row-container">
        {hasCultivations === null ? (
          <div className="data-loading">
            <p className='data-loading-message'>{t("data.loading")}</p>
          </div>
        ) : cultivations.length === 0 ? (
          <div className="no-cultivations-container">
            <p className='no-cultivations-message'>
              {t('data.error.nodata')}
            </p>
          </div>
        ) : (
          <div>
            {showLineChart ? (
              <div className='line-chart-container'>
                <div className={`line-chart ${isFullscreenWeather ? 'fullscreen' : ''}`}>
                  <button 
                    className="expand-button" 
                    onClick={() => setIsFullscreenWeather(!isFullscreenWeather)}
                  >
                    <i className={`bi ${isFullscreenWeather ? "bi-fullscreen-exit" : "bi-fullscreen"}`}></i>
                  </button>
                  <Line data={lineChartData} options={lineChartOptions} />
                </div>
              </div>
            ) : isLoadingHelixWind && hasCultivations ? (
              <div className="data-loading">
                <p className='data-loading-message'>{t('data.loading')}</p>
              </div>
            ) : !meteoHelixData["hasData"] && selectedDataType === "Meteo Helix-Wind" ? (
              <div className="no-helix-data">
                <p className='no-helix-data-message'>
                  {t('data.error.no_weather')}
                </p>
              </div>
            ) : null}
            {showScatterChart ? (
              <div className='scatter-chart-container'>
                <div className={`scatter-chart ${isFullscreenWind ? 'fullscreen' : ''}`}>
                  <button 
                    className="expand-button" 
                    onClick={() => setIsFullscreenWind(!isFullscreenWind)}
                  >
                    <i className={`bi ${isFullscreenWind ? "bi-fullscreen-exit" : "bi-fullscreen"}`}></i>
                  </button>
                  <Scatter data={ScatterChartData} options={ScatterChartOptions} />
                </div>
              </div>
              // ) : isLoading2 ? (
              //   <div className="data-loading2">
              //     <p className='data-loading-message2'>Loading...</p>
              //   </div>
            ) : !meteoWindData["hasData"] && selectedDataType === "Meteo Helix-Wind" && !isLoadingHelixWind ? (
              <div className="no-wind-data">
                <p className='no-wind-data-message'>
                  {t('data.error.no_air')}
                </p>
              </div>
            ) : null}
            {showAgLineChart ? (
              <div className='Ag-line-chart-container'>
                <div className={`ag-line-chart ${isFullscreenAg ? 'fullscreen' : ''}`}>
                  <button 
                    className="expand-button" 
                    onClick={() => setIsFullscreenAg(!isFullscreenAg)}
                  >
                    <i className={`bi ${isFullscreenAg ? "bi-fullscreen-exit" : "bi-fullscreen"}`}></i>
                  </button>
                  <Line data={lineChartDataAg} options={lineChartOptionsAg} />
                </div>
              </div>
            ) : isLoadingAg && hasCultivations ? (
              <div className="data-loading-Ag">
                <p className='data-loading-message-Ag'>{t('data.loading')}</p>
              </div>
            ) : !meteoAgData["hasData"] && selectedDataType === "Meteo AG" ? (
              <div className="no-ag-data">
                <p className='no-ag-data-message'>
                  {t('data.error.no_sensors')}
                </p>
              </div>
            ) : null}
            {showSoilAnalysis && soilAnalysisHasData ? (
              <div className='soil-analysis-container'>
                <div className="table-headers-container"> {/* New div for table headers */}
                  <table className="table-headers">
                    <thead>
                      <tr>
                        <th>{t("analysis.soil.table.headers.id")}</th>
                        <th>{t("analysis.soil.table.headers.date_created")}</th>
                        <th>{t("analysis.soil.table.headers.date_modified")}</th>
                        <th>{t("analysis.soil.table.headers.actions")}</th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div className="table-container">
                  <table className="table-fields">
                    <tbody>
                      {soilAnalysisTable.map((soilAnalysis, index) => (
                        <tr
                          key={soilAnalysis.id}
                          id={`row-${soilAnalysis.id}`}
                        >
                          <td>
                            {soilAnalysis.id || 'N/A'}
                          </td>
                          <td>
                            {soilAnalysis.created || 'N/A'}
                          </td>
                          <td>
                            {soilAnalysis.modified || 'N/A'}
                          </td>
                          <td>
                            <Link style={{ textDecoration: "none" }} className='links' to={"/edit-soil-analysis?id=" + soilAnalysis.id}>
                              <img
                                onMouseEnter={() => handleMouseEnterEdit(index)}
                                onMouseLeave={() => handleMouseLeaveEdit(index)}
                                src={isHoveredEdit[index] ? hoverEdit : originalEdit}
                                alt="Edit"
                                className={`tiny-button ${isHoveredEdit[index] ? 'hovered' : ''}`}
                              />
                            </Link>
                            <Link style={{ textDecoration: "none" }} className='links' to={"/view-soil-analysis?id=" + soilAnalysis.id}>
                              <img
                                onMouseEnter={() => handleMouseEnterView(index)}
                                onMouseLeave={() => handleMouseLeaveView(index)}
                                src={isHoveredView[index] ? hoverView : originalView}
                                alt="View"
                                className={`tiny-button ${isHoveredView[index] ? 'hovered' : ''}`}
                              />
                            </Link>
                            <img
                              onMouseEnter={() => handleMouseEnterDelete(index)}
                              onMouseLeave={() => handleMouseLeaveDelete(index)}
                              src={isHoveredDelete[index] ? hoverDelete : originaLDelete}
                              alt="Delete"
                              className="tiny-button"
                              onClick={() => handleDeleteSoilAnalysisClick(soilAnalysis)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : !soilAnalysisHasData && selectedDataType === "Soil Analysis" ? (
              <div className="no-ag-data">
                <p className='no-ag-data-message'>
                  {t('data.error.no_soil_analysis')}
                </p>
              </div>
            ) : null}
            {showLeafAnalysis && leafAnalysisHasData ? (
              <div className='leaf-analysis-container'>
                <div className="table-headers-container"> {/* New div for table headers */}
                  <table className="table-headers">
                    <thead>
                      <tr>
                        <th>{t("analysis.leaf.table.headers.id")}</th>
                        <th>{t("analysis.leaf.table.headers.date_created")}</th>
                        <th>{t("analysis.leaf.table.headers.date_modified")}</th>
                        <th>{t("analysis.leaf.table.headers.actions")}</th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div className="table-container">
                  <table className="table-fields">
                    <tbody>
                      {leafAnalysisTable.map((leafAnalysis, index) => (
                        <tr
                          key={leafAnalysis.id}
                          id={`row-${leafAnalysis.id}`}
                        >
                          <td>
                            {leafAnalysis.id || 'N/A'}
                          </td>
                          <td>
                            {leafAnalysis.created || 'N/A'}
                          </td>
                          <td>
                            {leafAnalysis.modified || 'N/A'}
                          </td>
                          <td>
                            <Link style={{ textDecoration: "none" }} className='links' to={"/edit-leaf-analysis?id=" + leafAnalysis.id}>
                              <img
                                onMouseEnter={() => handleMouseEnterEdit(index)}
                                onMouseLeave={() => handleMouseLeaveEdit(index)}
                                src={isHoveredEdit[index] ? hoverEdit : originalEdit}
                                alt="Edit"
                                className={`tiny-button ${isHoveredEdit[index] ? 'hovered' : ''}`}
                              />
                            </Link>
                            <Link style={{ textDecoration: "none" }} className='links' to={"/view-leaf-analysis?id=" + leafAnalysis.id}>
                              <img
                                onMouseEnter={() => handleMouseEnterView(index)}
                                onMouseLeave={() => handleMouseLeaveView(index)}
                                src={isHoveredView[index] ? hoverView : originalView}
                                alt="View"
                                className={`tiny-button ${isHoveredView[index] ? 'hovered' : ''}`}
                              />
                            </Link>
                            <img
                              onMouseEnter={() => handleMouseEnterDelete(index)}
                              onMouseLeave={() => handleMouseLeaveDelete(index)}
                              src={isHoveredDelete[index] ? hoverDelete : originaLDelete}
                              alt="Delete"
                              className="tiny-button"
                              onClick={() => handleDeleteLeafAnalysisClick(leafAnalysis)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : !leafAnalysisHasData && selectedDataType === "Leaf Analysis" ? (
              <div className="no-ag-data">
                <p className='no-ag-data-message'>
                  {t('data.error.no_leaf_analysis')}
                </p>
              </div>
            ) : null}
          </div>
        )}
      </div>
      {/* ToastContainer for displaying notifications */}
      <ToastContainer
        position="bottom-right"
        autoClose={7000} // Close the notification after 7 seconds
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {showDeleteConfirmation && (
        <DeleteConfirmationModal
          onCancel={() => setShowDeleteConfirmation(false)}
          onConfirm={() => handleConfirmDelete()}
        />
      )}
    </div>

  );
};

export default FieldsData;
