import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Buffer } from "buffer";
import { useFormik } from 'formik';
import $ from "jquery";
import React, { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import './CSSCustom/SignUpPage.css';
import logoImageWhite from './img/logowhite.png';

import { toast, ToastContainer } from 'react-toastify';
import { usePageTitle } from './JS_Context/ContextTitle';
import 'react-toastify/dist/ReactToastify.css';
import getServerURL from "./Configuration.js";

import i18next from "i18next";
import { useTranslation } from 'react-i18next';

function SignUpPage({setUsername, username}) {
  const navigate = useNavigate();
  const setCookie = useCookies(['csrftoken', 'auth', 'username'])[1];
  const [password, setPassword] = useState('');
  const [showAdditionalForm, setShowAdditionalForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const serverUrl = getServerURL();
  const { t } = useTranslation();
  const { setPageTitle, setPageTitleIcon } = usePageTitle();

  useEffect(() => {
    if (localStorage.getItem("i18nextLng")?.length > 2) {
        i18next.changeLanguage("en");
    }
  }, []);

  useEffect(() =>{
    document.title = "SignUp"
  })

  const validationSchema = Yup.object({
    username: Yup.string()
      .required(t("page_signup.validation.username.required"))
      .max(150, t("page_signup.validation.username.length"))
      .matches(/^[a-zA-Z0-9@/./+/-/_]+$/, t("page_signup.validation.username.format")),
    password: Yup.string()
      .required(t("page_signup.validation.password.required"))
      .min(12, t("page_signup.validation.password.length"))
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/, t("page_signup.validation.password.format")),
    password2: Yup.string()
      .required(t("page_signup.validation.password.repeat"))
      .oneOf([Yup.ref('password'), null], t("page_signup.validation.password.match")),
    email: Yup.string()
      .required(t("page_signup.validation.email.required"))
      .email(t("page_signup.validation.email.format")),
    groups: Yup.string()
      .test('is-only-one-group', '', (value) => {
        return !value || (value.split(',').filter(Boolean).length === 1);
      }),
  
    // Additional form validations (applied when 'groups' has any value)
    afm: Yup.string().when(['groups', 'groups'], {
      is: (groups) => groups && groups.length > 0,
      then:()=> Yup.string()
        .required(t("page_signup.validation.tax_number.required"))
        .max(10, t("page_signup.validation.tax_number.length")),
    }),
    adt: Yup.string().when(['groups', 'groups'], {
      is: (groups) => groups && groups.length > 0,
      then:()=> Yup.string()
        .required(t("page_signup.validation.id_number.required"))
        .max(10, t("page_signup.validation.id_number.length")),
    }),
    first_name: Yup.string().when(['groups', 'groups'], {
      is: (groups) => groups && groups.length > 0,
      then:()=> Yup.string().required(t("page_signup.validation.first_name.required")),
    }),
    last_name: Yup.string().when(['groups', 'groups'], {
      is: (groups) => groups && groups.length > 0,
      then:()=> Yup.string().required(t("page_signup.validation.last_name.required")),
    }),
  });

  const formRef = useRef();

  // hook to listen for key events
  useEffect(() => {
    const handleKeyPress = (event) => {
      // Check if the pressed key is "Enter" (key code 13)
      if (event.key === 'Enter') {
        // Trigger the click event on the submit button
        formRef.current.querySelector('.button_real_register').click();
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    // Remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
    }, []); // Empty dependency array means this effect runs once when the component mounts


  const handleCheckboxChange2 = () => {
    // Toggle the selected group
    const newGroups = formik.values.groups === '2' ? '' : '2';
  
    // Reset additional form fields if hiding the form
    if (newGroups === '' && showAdditionalForm) {
      setShowAdditionalForm(false);
      formik.setValues({
        ...formik.values,
        afm: '',
        adt: '',
        first_name: '',
        last_name: '',
      });
    }else{
      setShowAdditionalForm(true);
    }
  
    formik.setFieldValue('groups', newGroups);
  };

  const handleCheckboxChange3 = () => {
    // Toggle the selected group
    const newGroups = formik.values.groups === '3' ? '' : '3';
  
    // Reset additional form fields if hiding the form
    if (newGroups === '' && showAdditionalForm) {
      setShowAdditionalForm(false);
      formik.setValues({
        ...formik.values,
        afm: '',
        adt: '',
        first_name: '',
        last_name: '',
      });
    }else{
      setShowAdditionalForm(true);
    }
  
    formik.setFieldValue('groups', newGroups);
  };

  const handleCheckboxChange4 = () => {
    // Toggle the selected group
    const newGroups = formik.values.groups === '4' ? '' : '4';
  
    // Reset additional form fields if hiding the form
    if (newGroups === '' && showAdditionalForm) {
      setShowAdditionalForm(false);
      formik.setValues({
        ...formik.values,
        afm: '',
        adt: '',
        first_name: '',
        last_name: '',
      });
    }else{
      setShowAdditionalForm(true);
    }
    
    formik.setFieldValue('groups', newGroups);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const togglePasswordVisibility2 = () => {
    setShowPassword2((prevShowPassword2) => !prevShowPassword2);
  };

  //Login logic after signup
  const handleLogin = () => {
    $.ajax({
        url: serverUrl+"/osiris/api_csrf/",
        type: 'get',
        xhrFields: {
          withCredentials: true,
          xsrfCookieName: 'csrftoken',
          xsrfHeaderName: 'X-CSRFToken'
        },
        crossDomain: true,
        success: function (data, textStatus, request) {
          setCookie('csrftoken',request.getResponseHeader('X-Csrftoken'))
          let csrf = request.getResponseHeader('X-Csrftoken');
          $.ajax({
              url: serverUrl+"/osiris/api_login/",
              type: 'post',
              headers: {"X-CSRFToken": csrf},
              xhrFields: {
                withCredentials: true,
                xsrfCookieName: 'csrftoken',
                xsrfHeaderName: 'X-CSRFToken'
              },
              crossDomain: true,
              contentType: "application/json; charset=utf-8",
              dataType: "json",
              data: JSON.stringify({
                username: username,
                password: password
              }),
              success: function (data, textStatus, request) {
                  setCookie('csrftoken',request.getResponseHeader('X-Csrftoken'))
                  const auth = Buffer.from(`${username}:${password}`).toString("base64");
                  setCookie('auth',`Basic ${auth}`)
                  setCookie('username', username, { path: '/' });
                  navigate('/fields');
              },
              error: function (request, textStatus, errorThrown) {
                  console.error(errorThrown);
                  if (request.status === 403) {
                    // Forbidden error (status code 403)
                    toast.error(
                      t("page_login.error.credentials")
                    );
                    navigate('/login');
                  } else {
                    // Other errors
                    toast.error(`${t("common.error.general")}: ${errorThrown}`);
                  }
              }
          });
        },
        error: function (request, textStatus, errorThrown) {
            console.error(errorThrown);
        }
    });
  };

  const onSubmit = async (values, { setSubmitting }) => {
    setLoading(true); // Set loading to true when signup button is clicked
    // Convert groups from string to an array of integers
    let groupsArray = values.groups.split(',').map(Number);
    if (groupsArray.includes(4)) {
      groupsArray = [2, 3];
    }

    // Now 'groupsArray' is an array of integers, you can send it to the server
    const dataToSend = {
      username: values.username,
      password: values.password,
      password2: values.password2,
      email: values.email,
    };
    setUsername(values.username)
    setPassword(values.password)

          // Additional form data
    if (showAdditionalForm) {
      const additionalFormData = {
        groups: groupsArray,
        afm: values.afm,
        adt: values.adt,
        first_name: values.first_name,
        last_name: values.last_name,
      };

      // Validate additional form fields
      const additionalFormSchema = Yup.object().shape({
        afm: validationSchema.fields.afm,
        adt: validationSchema.fields.adt,
        first_name: validationSchema.fields.first_name,
        last_name: validationSchema.fields.last_name,
      });

      try {
        additionalFormSchema.validateSync(additionalFormData, { abortEarly: false });

        // Merge additional form data into dataToSend
        Object.assign(dataToSend, additionalFormData);
      } catch (error) {
        // Additional form data is not valid, handle accordingly
        //console.error('Additional form data is not valid:', error.errors);
        setSubmitting(false); // Set submitting to false to allow resubmission
        return;
      }
    }

    // Server logic
    await new Promise(resolve => setTimeout(resolve, 7000));
      // Validate all form fields
    try {
      validationSchema.validateSync(values, { abortEarly: false });

      $.ajax({
        url: serverUrl+"/osiris/api_csrf/",
        type: 'GET',
        xhrFields: {
          withCredentials: true,
          xsrfCookieName: 'csrftoken',
          xsrfHeaderName: 'X-CSRFToken'
        },
        crossDomain: true,
        success: function (data, textStatus, request) {
          setCookie('csrftoken',request.getResponseHeader('X-Csrftoken'))
          let csrf = request.getResponseHeader('X-Csrftoken');
          $.ajax({
              url: `${serverUrl}/osiris/register/`,
              type: 'post',
              headers: {"X-CSRFToken": csrf},
              xhrFields: {
                withCredentials: true,
                xsrfCookieName: 'csrftoken',
                xsrfHeaderName: 'X-CSRFToken'
              },
              crossDomain: true,
              contentType: "application/json; charset=utf-8",
              dataType: "json",
              data: JSON.stringify(dataToSend),
              success: function (data, textStatus, request) {
                  setCookie('csrftoken',request.getResponseHeader('X-Csrftoken'))
                  toast.success(t("page_signup.form.success"));
                  handleLogin()

              },
              error: function (request, textStatus, errorThrown) {
                if (request.status === 400) {
                    // Bad Request error (status code 400)
                    try {
                        const errorResponse = JSON.parse(request.responseText);
                        if (errorResponse.username) {
                            const errorMessage = errorResponse.username[0];
                            toast.error(errorMessage);
                        } else {
                            // Handle other errors if needed
                            toast.error(`${t("common.error.general")}: ${errorThrown}`);
                        }
                    } catch (parseError) {
                        // Handle parsing error if needed
                        console.error(parseError);
                        toast.error(`${t("common.error.general")}: ${errorThrown}`);
                    }
                } else {
                    // Other errors
                    toast.error(`${t("common.error.general")}: ${errorThrown}`);
                }
            }
          });
          
      },
      error: function (request, textStatus, errorThrown) {
          toast.error(`${t("common.error.general")}: ${errorThrown}`);
      }
      });
    } catch (error) {
      setSubmitting(false); // Set submitting to false to allow resubmission
      return;
    }
    finally {
      // Set loading back to false after the timeout or completion of operations
      setLoading(false);
      setSubmitting(false); // Set submitting to false to allow resubmission
    }
  };
  
  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      password2: '',
      email: '',
      groups: '',
      afm: '', // Include additional form fields in the main form
      adt: '',
      first_name: '',
      last_name: '',
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  const handleAlreadyLogin = () => {
    navigate('/login');
  }

  return (
    <div className="signup-page">
       {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">
            <p>{t("page_signup.form.wait_message")}</p>
          </div>
        </div>
      )}
      <div className="right-background-signup"></div>
      <div className="signup-form" ref={formRef}>
        <div className="logo adjust-logo-top-signup">
          <img src={logoImageWhite} alt="LogoWhite" className="logo_signup" />
        </div>
        <h1 className="signup-title">{t("page_signup.title")}</h1>
        <div className="form-group-signup">
          <label>{t("page_signup.form.username")}<span className="required-field">*</span> </label>
          <input
            type="text"
            value={formik.values.username}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="username"
          />
          {formik.errors.username && formik.touched.username && (
            <div className="error-message">{formik.errors.username}</div>
          )}
        </div>
        <div className="form-group-signup">
          <label>{t("page_signup.form.password")} <span className="required-field">*</span> </label>
          <span className="password-input-container">
            <input
              type={showPassword ? "text" : "password"}
              value={formik.values.password}
              onChange={(e) => {
                formik.handleChange(e);
                setPassword(e.target.value);
              }}
              onBlur={formik.handleBlur}
              name="password"
            />
            <FontAwesomeIcon
              icon={showPassword ? faEye : faEyeSlash}
              className="toggle-password-icon"
              onClick={() => togglePasswordVisibility()}
            />
          </span>
          {formik.errors.password && formik.touched.password && (
            <div className="error-message">{formik.errors.password}</div>
          )}
        </div>
        <div className="form-group-signup">
          <label>{t("page_signup.form.password_repeat")}<span className="required-field">*</span> </label>
          <span className="password-input-container">
            <input
              type={showPassword2 ? "text" : "password"}
              value={formik.values.password2}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="password2"
            />
            <FontAwesomeIcon
              icon={showPassword2 ? faEye : faEyeSlash}
              className="toggle-password-icon"
              onClick={() => togglePasswordVisibility2()}
            />
          </span>
          {formik.errors.password2 && formik.touched.password2 && (
            <div className="error-message">{formik.errors.password2}</div>
          )}
        </div>
        <div className="form-group-signup">
          <label>{t("page_signup.form.email")} <span className="required-field">*</span> </label>
          <input
            type="text"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="email"
          />
          {formik.errors.email && formik.touched.email && (
            <div className="error-message">{formik.errors.email}</div>
          )}
        </div>
        <div className="form-group-signup">
        <label>{t("page_signup.form.roles")}</label>
        <div>
          <label className="checkbox-label">
            <input
              type="checkbox"
              name="groups"
              value="2"
              checked={formik.values.groups === '2'}
              onChange={() => {
                  handleCheckboxChange2();
                }
              }
            />
            <span className="checkbox-custom"></span>{t("page_signup.form.farmer")}
          </label>
          <label className="checkbox-label">
            <input
              type="checkbox"
              name="groups"
              value="3"
              checked={formik.values.groups === '3'}
              onChange={() => {
                handleCheckboxChange3();
              }}
            />
            <span className="checkbox-custom"></span>{t("page_signup.form.consultant")}
          </label>
          <label className="checkbox-label">
            <input
              type="checkbox"
              name="groups"
              value="2,3"
              checked={formik.values.groups === '4'}
              onChange={() => {
                handleCheckboxChange4();
              }}
            />
            <span className="checkbox-custom"></span>{t("page_signup.form.all_roles")}
          </label>
        </div>
        {formik.errors.groups && formik.touched.groups && (
          <div className="error-message">{formik.errors.groups}</div>
        )}
        </div>
        {showAdditionalForm && (
          <div className="additional-form">
            <div className='HelperDiv'> </div>
            <div className="additional-form-group-signup">
              <label>{t("page_signup.form.tax_number")} <span className="required-field">*</span> </label>
              <input
                type="text"
                value={formik.values.afm}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="afm"
              />
              {formik.errors.afm && formik.touched.afm && (
                <div className="error-message">{formik.errors.afm}</div>
              )}
            </div>
            <div className="additional-form-group-signup">
              <label>{t("page_signup.form.id_number")} <span className="required-field">*</span> </label>
              <input
                type="text"
                value={formik.values.adt}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="adt"
              />
              {formik.errors.adt && formik.touched.adt && (
                <div className="error-message">{formik.errors.adt}</div>
              )}
            </div>
            <div className="additional-form-group-signup">
              <label>{t("page_signup.form.first_name")} <span className="required-field">*</span> </label>
              <input
                type="text"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="first_name"
              />
              {formik.errors.first_name && formik.touched.first_name && (
                <div className="error-message">{formik.errors.first_name}</div>
              )}
            </div>
            <div className="additional-form-group-signup">
              <label>{t("page_signup.form.last_name")}  <span className="required-field">*</span> </label>
              <input
                type="text"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="last_name"
              />
              {formik.errors.last_name && formik.touched.last_name && (
                <div className="error-message">{formik.errors.last_name}</div>
              )}
            </div>
          </div>
        )}
        <button className="button_real_register" type="submit" onClick={formik.handleSubmit}>{t("page_signup.form.submit")}</button>
        <p className="already-profile" onClick={handleAlreadyLogin}>{t("page_signup.form.existing_profile")}</p>
        {/* <Link style={{ textDecoration: 'none' }} className="links" to="/create-fields">
          <button className="button_login_already">Login</button>
        </Link> */}
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={7000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default SignUpPage;