import $ from "jquery";
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import MapCreateComponent from './MapCreateComponent';
import * as yup from 'yup';

import 'leaflet/dist/leaflet.css';
import './CSSCustom/viewCultivations.css';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loadSwaggerEnum } from './Utils.js';
import { Link } from 'react-router-dom';

import { useTranslation } from "react-i18next";
import { usePageTitle } from './JS_Context/ContextTitle';
import getServerURL from "./Configuration.js";
import originalAccept from './img/ICON_ACCEPT_BLACK.png';
import hoverAccept from './img/ICON_ACCEPT_ORANGE.png';
import originalDecline from './img/ICON_DECLINE_BLACK.png';
import hoverDecline from './img/ICON_DECLINE_ORANGE.png';

const serverUrl = getServerURL();

function EditLeafAnalysis() {
  const [leafAnalysisID, setLeafAnalysisID] = useState();
  const [cookies] = useCookies(['csrftoken']);
  const { t, i18n } = useTranslation();
  const { setPageTitle, setPageTitleIcon } = usePageTitle();
  const [selectedCultivation,setSelectedCultivation] = useState();
  const [isHoveredAccept, setIsHoveredAccept] = useState(false);
  const [isHoveredDecline, setIsHoveredDecline] = useState(false);
  const [parameterOptions, setParameterOptions] = useState([]);
  const [parameterLabelOptions, setParameterLabelOptions] = useState([]);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});

  // Initialize form
  const initForm = () => {
    $.ajax({
      url: serverUrl + "/leaf-analysis/" + leafAnalysisID + "/",
      type: 'get',
      headers: {
        "Accept-Language": i18n.language || 'el',
        "X-CSRFToken": cookies.csrftoken,
        "Authorization": cookies.auth
      },
      xhrFields: {
        withCredentials: true,
        xsrfCookieName: 'csrftoken',
        xsrfHeaderName: 'X-CSRFToken'
      },
      crossDomain: true,
      success: function (data) {
        let newFormData = {};
        if (data) {
          setSelectedCultivation(data.cultivation);
          let input_created_field = document.querySelector('#creation');
          let parsedDate = new Date(data.created);
          let formattedDate = parsedDate.toLocaleDateString("en-US", {
            year: "2-digit",
            month: "numeric",
            day: "numeric",
          });
          let formattedHour = parsedDate.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "numeric",
            hour12: false,
          });
          let formattedDateTime = `${formattedDate} ${formattedHour}`;
          input_created_field.value = formattedDateTime;
          newFormData[input_created_field.name] = parsedDate; 
          let input_modified_field = document.querySelector('#modification');
          parsedDate = new Date(data.modified);
          formattedDate = parsedDate.toLocaleDateString("en-US", {
            year: "2-digit",
            month: "numeric",
            day: "numeric",
          });
          formattedHour = parsedDate.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "numeric",
            hour12: false,
          });
          formattedDateTime = `${formattedDate} ${formattedHour}`;
          input_modified_field.value = formattedDateTime;
          newFormData[input_modified_field.name] = parsedDate; 
          if (data.parameters && data.parameters.length > 0) {
            data.parameters.map((param) => {
              let input_param_field = $("[id='param_" + param.type + "']");
              if (input_param_field) {
                if (param.value_number != null) {
                  input_param_field.val(Math.round(param.value_number * 100) / 100);
                  newFormData["param_"+param.type] = Math.round(param.value_number * 100) / 100; 
                } else {
                  input_param_field.val(param.value_text);
                  newFormData["param_"+param.type] = param.value_text; 
                }
              }
              return null;
            });
          }
        }
        setFormData(newFormData);
      },
      error: function (error) {
        console.error('Error fetching cultivation details:', error);
      }
    });
  };

  function loadParameterTypeOptions() {
    return loadSwaggerEnum({ endpoint: '/leaf-analysis/', attribute: 'parameters.items.properties.type', cookies });
  };

  function loadParameterLabelOptions() {
    return loadSwaggerEnum({ endpoint: '/leaf-analysis/', attribute: 'parameters.items.properties.label', cookies });
  };

  useLayoutEffect(() => {
    loadParameterTypeOptions().then((result) => {
      setParameterOptions(result);
    });
    loadParameterLabelOptions().then((result) => {
      setParameterLabelOptions(result);
    });
    const params = new URLSearchParams(window.location.search);
    setLeafAnalysisID(params.get('id'));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //Initialize page
  useEffect(() => {
    if (leafAnalysisID && parameterOptions.length > 0 && parameterLabelOptions.length > 0) {
      initForm();
    }
  }, [leafAnalysisID, parameterOptions, parameterLabelOptions]);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setPageTitle("analysis.leaf.view.title");
    setPageTitleIcon("/img/ICON_DATA_GREEN.png");
  }, [])

  const validationSchema = yup.object().shape({
    value_number: yup.number().min(0, t('analysis.form.validation.value_number')),
    value_text: yup.string()
  });

  // Accept icon hover handle
  const handleMouseEnterAccept = () => {
    setIsHoveredAccept(true);
  };

  const handleMouseLeaveAccept = () => {
    setIsHoveredAccept(false);
  };

  // Decline icon hover handle
  const handleMouseEnterDecline = () => {
    setIsHoveredDecline(true);
  };

  const handleMouseLeaveDecline = () => {
    setIsHoveredDecline(false);
  };

  const handleAcceptClick = (event) => {
    handleSubmit(event);
  };

  const handleDeclineClick = () => {
    window.location.href = "/view-leaf-analysis?id="+leafAnalysisID;
  };

  const clearEmptyData = (data) => {
    Object.keys(data).forEach(key => {
      if (data[key] === '' || data[key] == null) {
        delete data[key];
      }
    });
    return data;
  }

  const handleChange = (event) => {
    let datum = event;
    if (event?.target) {
      datum = event.target;
      datum.name = datum.id;
      if (datum.getAttribute("validate_key")) {
        datum.name = datum.getAttribute("validate_key");
      } else {
        datum.name = datum.id;
      }
    }
    if (!datum.id) { datum.id = datum.name; }
    const { name, value, id } = datum;

    // Validate the field on each change
    validationSchema
      .validateAt(name, { [name]: value })
      .then(() => {
        // No validation errors for this field, clear the error for this field
        setErrors((prevErrors) => ({ ...prevErrors, [id]: undefined }));
      })
      .catch((error) => {
        // Validation error occurred, set the error for this field
        setErrors((prevErrors) => ({ ...prevErrors, [id]: error.message }));
      });

    let temp_data = formData;
    temp_data[id] = value;
    setFormData(temp_data);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Validate the entire form before submitting
      let tempFormData = formData;
      tempFormData['cultivation'] = selectedCultivation;
      tempFormData['id'] = parseInt(leafAnalysisID);
      for (let key in tempFormData) {
        if (tempFormData.hasOwnProperty(key)) {
          let datum_obj = $('#' + key);
          let datum_val = tempFormData[key];
          let datum_name = datum_obj.id;
          if (datum_obj.attr("validate_key")) {
            datum_name = datum_obj.attr("validate_key");
          }
          validationSchema
            .validateAt(datum_name, { [datum_name]: datum_val })
            .then(() => {
              // No validation errors for this field, clear the error for this field
              setErrors((prevErrors) => ({ ...prevErrors, [datum_obj.id]: undefined }));
            })
            .catch((error) => {
              // Validation error occurred, set the error for this field
              setErrors((prevErrors) => ({ ...prevErrors, [datum_obj.id]: error.message }));
            });
        }
      }
      setFormData(tempFormData);
      setFormData(clearEmptyData(formData));

      tempFormData["parameters"] = [];
      for (let key in tempFormData) {
        if (tempFormData.hasOwnProperty(key)) {
          if (key.startsWith("param_")) {
            let datum_obj = $("[id='" + key + "']");
            let datum_label = datum_obj.attr("django-label");
            if (isNaN(tempFormData[key])) {
              tempFormData["parameters"].push(
                {
                  "type": key.substring(6),
                  "label": datum_label,
                  "value_text": tempFormData[key]
                }
              );
            } else {
              tempFormData["parameters"].push(
                {
                  "type": key.substring(6),
                  "label": datum_label,
                  "value_number": tempFormData[key]
                }
              );
            }
            delete tempFormData[key];
          }
        }
      }
      setFormData(tempFormData);

      // If validation passes, proceed to the server request
      await $.ajax({
        url: serverUrl + "/leaf-analysis/" + leafAnalysisID +"/",
        type: 'put',
        headers: {
          "Accept-Language": i18n.language || 'el',
          'X-CSRFToken': cookies.csrftoken,
          'Content-Type': 'application/json',
          "Authorization": cookies.auth
        },
        xhrFields: {
          withCredentials: true,
          xsrfCookieName: 'csrftoken',
          xsrfHeaderName: 'X-CSRFToken'
        },
        crossDomain: true,
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        data: JSON.stringify(formData),
        success: function (data, textStatus, request) {
          // Handle the response, e.g. success message
          let input_modified_field = document.querySelector('#modification');
          let parsedDate = new Date();
          let formattedDate = parsedDate.toLocaleDateString("en-US", {
            year: "2-digit",
            month: "numeric",
            day: "numeric",
          });
          let formattedHour = parsedDate.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "numeric",
            hour12: false,
          });
          let formattedDateTime = `${formattedDate} ${formattedHour}`;
          input_modified_field.value = formattedDateTime;
          toast.success(t('analysis.leaf.edit.success'));
        },
        error: function (request, textStatus, errorThrown) {
          // Handle errors
          console.error('PUT request failed:', errorThrown);
          if (errorThrown.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Server responded with:', request.response.data);
            console.error('Status code:', textStatus);
            toast.error(`${t('analysis.leaf.edit.fail_2xx')} ${textStatus}.`);
          } else if (request) {
            // The request was made but no response was received
            console.error('No response received from the server');
            toast.error(t('analysis.leaf.edit.fail_unreachable'));
          } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error setting up the request:', errorThrown);
            toast.error(t('analysis.leaf.edit.fail_unknown'));
          }
          //toast.error(`An error occurred: ${errorThrown}`);
        }
      });
      return;
    } catch (validationError) {
      if (validationError.responseJSON) {
        for (const [key, value] of Object.entries(validationError.responseJSON)) {
          toast.error(`${key}: ${value}`);
        }
      }
      else if (validationError.responseText) {
        toast.error(`${t("common.error.general")}: ${validationError.responseText}`);
      } else {
        // Handle validation errors
        const validationErrors = {};
        if (validationError.inner) {
          validationError.inner.forEach((error) => {
            validationErrors[error.path] = error.message;
          });
          setErrors(validationErrors);
        }

        // Optionally, display an error message to the user
        console.error('Validation failed:', validationError);
        //toast.error('Validation failed:', validationError);
      }
    }
  };

  return (
    <div className="content page1_content">
      <div className="create-field-map" >
        <MapCreateComponent selectedCultivation={selectedCultivation} readOnly={true}/>
      </div>
      <div className="form-background">
        <form>
          <div className="form-container"
            style={{ height: "70vh", overflowY: "auto", overflowX: "hidden", paddingRight: "20px" }}>
            <div className="second-button-row" style={{ paddingLeft: "1.2vh", paddingTop: "15px" }}>
              <img
                onMouseEnter={() => handleMouseEnterAccept()}
                onMouseLeave={() => handleMouseLeaveAccept()}
                src={isHoveredAccept ? hoverAccept : originalAccept}
                alt={t("common.map.accept")}
                className={`accept-decline ${isHoveredAccept ? 'hovered' : ''}`}
                onClick={(event) => handleAcceptClick(event)}
              />
              <img 
                  onMouseEnter={() => handleMouseEnterDecline()}
                  onMouseLeave={() => handleMouseLeaveDecline()}
                  src={isHoveredDecline ? hoverDecline : originalDecline}
                  alt={t("common.map.cancel")}
                  className={`accept-decline ${isHoveredDecline ? 'hovered' : ''}`}
                  onClick={(event) => handleDeclineClick()}
                />
            </div>
            <div className="form-row">
              <label htmlFor="creation" className='label-create'>{t('analysis.leaf.form.create')}</label>
              <input type="text" id="creation" name="created" className='create-input'
                placeholder={t('analysis.leaf.form.placeholder.create')} readOnly />
            </div>
            <div className="form-row">
              <label htmlFor="modification" className='label-create'>{t('analysis.leaf.form.edit')}</label>
              <input type="text" id="modification" name="modified" className='create-input'
                placeholder={t('analysis.leaf.form.placeholder.edit')} readOnly />
            </div>
            {parameterOptions.map((option, index) => (
              <div className="form-row" style={{ flexDirection: "row", borderBottom: "1px dotted black", marginRight: "15px" }}>
                <label htmlFor={option.value} className='label-create'>{parameterLabelOptions[index]?.value}</label>
                <input type="number" id={"param_" + option.value} name={option.value} validate_key="value_number" django-label={parameterLabelOptions[index]?.value}
                  style={{ width: "49%", marginTop: "auto", marginBottom: "10px" }}
                  onChange={handleChange} className='create-input leaf-parameter-input'
                />
                {errors.name && <span className="error-message">{errors.name}</span>}
              </div>
            ))}
          </div>
        </form>
      </div>
      {/* ToastContainer for displaying notifications */}
      <ToastContainer
        position="bottom-right"
        autoClose={7000} // Close the notification after 7 seconds
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>

  );
};

export default EditLeafAnalysis;