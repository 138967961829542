import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import cautionIcon from './img/caution.png';
import checkedIcon from './img/checked.png';
import originaLDelete from './img/ICON_PINAKAS_DELETE_GREEN.png';
import hoverDelete from './img/ICON_PINAKAS_DELETE_ORANGE.png';
import originalEdit from './img/ICON_PINAKAS_EDIT_GREEN.png';
import hoverEdit from './img/ICON_PINAKAS_EDIT_ORANGE.png';
import originalAdd from './img/ICON_PINAKAS_PROSTHIKI_GREEN.png';
import hoverAdd from './img/ICON_PINAKAS_PROSTHIKI_ORANGE.png';
import originalView from './img/ICON_PINAKAS_VIEW_GREEN.png';
import hoverView from './img/ICON_PINAKAS_VIEW_ORANGE.png';
import loadingIcon from './img/loading_gif_small.gif';
import warningIcon from './img/warning.png';

import 'leaflet/dist/leaflet.css';
import './CSSCustom/fieldsTable.css';
import MapComponent from './MapComponent';
import i18n from 'i18next';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import $ from "jquery";
import { useCookies } from 'react-cookie';
import { usePageTitle } from './JS_Context/ContextTitle';
import { useTranslation } from 'react-i18next';
import getServerURL from "./Configuration.js";


const serverUrl = getServerURL();

function FieldsTable() {
  const [fields, setFields] = useState([]);
  const [fieldDetails, setFieldDetails] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedFieldName, setSelectedFieldName] = useState([]);
  const [clickedField, setClickedField] = useState([])
  const [isHoveredDelete, setIsHoveredDelete] = useState([]);
  const [isHoveredAdd, setIsHoveredAdd] = useState([]);
  const [isHoveredEdit, setIsHoveredEdit] = useState([]);
  const [isHoveredView, setIsHoveredView] = useState([]);
  const [cookies, setCookie] = useCookies(['csrftoken', 'sidebarWeather']);
  const [fieldIdForDelete, setFieldIdForDelete] = useState([])
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [fieldToDelete, setFieldToDelete] = useState();
  const [showSuggestionsModal, setShowSuggestionsModal] = useState(false);
  const [modalSuggestions, setModalSuggestions] = useState([]);
  const { t } = useTranslation();
  const { setPageTitle, setPageTitleIcon } = usePageTitle();
  

  // Delete icon hover handle
  const handleMouseEnterDelete = (index) => {
    const newHoverState = [...isHoveredDelete];
    newHoverState[index] = true;
    setIsHoveredDelete(newHoverState);
  };

  const handleMouseLeaveDelete = (index) => {
    const newHoverState = [...isHoveredDelete];
    newHoverState[index] = false;
    setIsHoveredDelete(newHoverState);
  };

  const handleDeleteClick = (field) => {
    setFieldToDelete(field);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete = () => {
    if (!fieldToDelete) {
      return;
    }
    const fieldId = fieldToDelete.id;
    // Step 1: Send DELETE request to the server
    $.ajax({
      url: serverUrl+"/fields/"+fieldId+"/",
      type: 'delete',
      headers: {
          "Accept-Language": i18n.language || 'el',
          "X-CSRFToken": cookies.csrftoken,
          "Authorization": cookies.auth
      },
      xhrFields: {
          withCredentials: true,
          xsrfCookieName: 'csrftoken',
          xsrfHeaderName: 'X-CSRFToken'
      },
      crossDomain: true,
      dataType: 'json',
      success: function (data) {
          // Step 2: Remove the field from local state
          const updatedFields = fields.filter((field) => field.id !== fieldId);
          setFields(updatedFields);
  
          // Step 3: Remove the corresponding fieldDetails from local state
          const updatedFieldDetails = fieldDetails.filter((detail) => detail.id !== fieldId);
          setFieldDetails(updatedFieldDetails);
  
          // Step 4: Remove the field from the map
          setFieldIdForDelete(fieldId);
  
          // Step 5: clear the selection after deletion
          setSelectedRow(null);
          setSelectedFieldName(null);
          toast.success(
            t("fields.form.delete_field_success")
            .replace("\{\{field_name\}\}", fieldToDelete.name)
          );
      },
      error: function (error) {
        toast.error(
          t("fields.form.delete_field_fail")
          .replace("\{\{field_name\}\}", fieldToDelete.name)+` ${error.message}`
        );
      }
  });
  setShowDeleteConfirmation(false);
  };

  const DeleteConfirmationModal = ({ field, onCancel, onConfirm }) => {
    return (
      <div className='delete-confirmation-overlay'>
        <div className="deleteModal-background">
          <div className="delete-confirmation-modal">
            <p>
              {t("fields.form.delete_field_confirmation")}
              <span className="field-name">{field.name}</span>
              {t("common.punctuation.question")}
            </p>
            <button onClick={onConfirm}>{t("common.form.confirm")}</button>
            <button onClick={onCancel}>{t("common.form.cancel")}</button>
          </div>
        </div>
      </div>
    );
  };

  // Add icon hover handle
  const handleMouseEnterAdd = (index) => {
    const newHoverState = [...isHoveredAdd];
    newHoverState[index] = true;
    setIsHoveredAdd(newHoverState);
  };

  const handleMouseLeaveAdd = (index) => {
    const newHoverState = [...isHoveredAdd];
    newHoverState[index] = false;
    setIsHoveredAdd(newHoverState);
  };

  // Edit icon hover handle
  const handleMouseEnterEdit = (index) => {
    const newHoverState = [...isHoveredEdit];
    newHoverState[index] = true;
    setIsHoveredEdit(newHoverState);
  };
  const handleMouseLeaveEdit = (index) => {
    const newHoverState = [...isHoveredEdit];
    newHoverState[index] = false;
    setIsHoveredEdit(newHoverState);
  };

  // View icon hover handle
  const handleMouseEnterView = (index) => {
    const newHoverState = [...isHoveredView];
    newHoverState[index] = true;
    setIsHoveredView(newHoverState);
  };

  const handleMouseLeaveView = (index) => {
    const newHoverState = [...isHoveredView];
    newHoverState[index] = false;
    setIsHoveredView(newHoverState);
  };

  const handleIconClick =  (suggestions) =>{
    setShowSuggestionsModal(true);
    setModalSuggestions(suggestions);
  };

  const SuggestionsModal = ({ suggestions, onClose }) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
  
    return (
      <div className='suggestion-confirmation-overlay'>
        <div className="suggestionModal-background">
          <div className="suggestions-modal">
            {/* <h2>Προτάσεις</h2> */}
            <h2 style={{textAlign: "center",color: "black"}}>
              {t("common.suggestion_modal.title")}
            </h2>
            <table>
              <thead>
                <tr>
                  {Object.keys(suggestions).map((suggestionType) => (
                    <th key={suggestionType}>
                      {t("common.suggestion_modal."+suggestionType.replace(" ","_"))}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {Object.keys(suggestions).map((suggestionType) => (
                    <td key={suggestionType}>
                      {suggestions[suggestionType].slice(-1).map((suggestionObject, index) => {
                        const suggestionDate = suggestionObject['date'];
                        let warningColor = '#EA840A';
                        let errorColor = '#dc3545';
                        let normalColor ='1E1E1E';
                        let suggestionUrl = '#';
                        if(suggestionType === "λίπανση"){
                          suggestionUrl = "/view-fertilization-suggestion/";
                        }else if(suggestionType === "φροντίδα καλλιέργειας"){
                          suggestionUrl = "/view-leaf-suggestion/";
                        }
                        if(suggestionDate){
                          const date = new Date(suggestionDate);
                          date.setHours(0, 0, 0, 0);
        
                          const daysDifference = Math.floor((date - currentDate) / (24 * 60 * 60 * 1000));
                          let textColor = '';
                          let text = '';
        
                          if (daysDifference < -1) {
                            textColor = errorColor;
                            text = t("common.suggestion_modal.days_past")
                                  .replace("\{\{days\}\}", Math.abs(daysDifference));
                          } else if (daysDifference === -1) {
                            textColor = errorColor;
                            text = t("common.suggestion_modal.day_past")
                                  .replace("\{\{days\}\}", Math.abs(daysDifference));
                          } else if (daysDifference === 0) {
                            textColor = errorColor;
                            text = t("common.suggestion_modal.today");
                          } else if (daysDifference === 1) {
                            textColor = errorColor;
                            text = t("common.suggestion_modal.day_future")
                                  .replace("\{\{days\}\}", Math.abs(daysDifference));
                          } else if (daysDifference <= 2) {
                            textColor = errorColor;
                            text = t("common.suggestion_modal.days_future")
                                  .replace("\{\{days\}\}", Math.abs(daysDifference));
                          } else if (daysDifference <= 4) {
                            textColor = warningColor;
                            text = t("common.suggestion_modal.days_future")
                                  .replace("\{\{days\}\}", Math.abs(daysDifference));
                          } else {
                            textColor = normalColor;
                            text = t("common.suggestion_modal.days_future")
                                  .replace("\{\{days\}\}", Math.abs(daysDifference));
                          }
        
                          return (
                            <div key={index} style={{ color: textColor }}>
                              <p>{suggestionDate}</p>
                              <p>{text}</p>
                            </div>
                          );
                        }else{
                          if(suggestionObject['rating'] <= 1){
                            return (
                              <div key={index}>
                                <p></p>
                              </div>
                            );
                          }else if(suggestionObject['rating'] === 2){
                            return (
                              <Link className='links' to={suggestionUrl+"?id="+suggestionObject['id']}>
                                <div key={index} style={{ color: warningColor }}>
                                  <p>{t("common.suggestion_modal.active")}</p>
                                </div>
                              </Link>
                            );
                          }else if(suggestionObject['rating'] === 3){
                            return(
                              <Link className='links' to={suggestionUrl+"?id="+suggestionObject['id']}>
                                <div key={index} style={{ color: errorColor }}>
                                  <p>{t("common.suggestion_modal.active")}</p>
                                </div>
                              </Link>
                            );
                          }else{
                            return (
                              <div key={index}>
                                <p></p>
                              </div>
                            );
                          }
                        }
                      })}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
            <button onClick={onClose}>{t("common.form.exit")}</button>
          </div>
        </div>
      </div>  
    );
  };

  useEffect(() =>{
    setPageTitle("fields.table.title");
    setPageTitleIcon("/img/ICON_AGRO_GREEN.png");
  },[])
    
  useLayoutEffect(() => {
    // Initialize the hover state array with all false values
    setIsHoveredAdd(new Array(fields.length).fill(false));
  }, [fields]);

  useLayoutEffect(() => {
    // Initialize the hover state array with all false values
    setIsHoveredEdit(new Array(fields.length).fill(false));
  }, [fields]);

  useLayoutEffect(() => {
    // Initialize the hover state array with all false values
    setIsHoveredView(new Array(fields.length).fill(false));
  }, [fields]);

  useLayoutEffect(() => {
    // Initialize the hover state array with all false values
    setIsHoveredDelete(new Array(fields.length).fill(false));
  }, [fields]);

  useLayoutEffect(() => {
    // Step 1: Fetch all fields
    $.ajax({
        url: serverUrl+"/fields/",
        type: 'get',
        headers: {
            "Accept-Language": i18n.language || 'el',
            "X-CSRFToken": cookies.csrftoken,
            "Authorization": cookies.auth
        },
        xhrFields: {
            withCredentials: true,
            xsrfCookieName: 'csrftoken',
            xsrfHeaderName: 'X-CSRFToken'
        },
        crossDomain: true,
        dataType: 'json',
        success: function (data) {
          setFields(data);
        },
        error: function (error) {
          console.error(t("fields.error.fields"), error);
          toast.error(`${t("fields.error.fields")} ${error}`);
        }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {
    // Step 2: Fetch details for each field
    let fieldDetailPromises = [];
    fields.map((field) => {
      fieldDetailPromises.push($.ajax({
          url: serverUrl+"/fields/"+field.id+"/",
          type: 'get',
          headers: {
              "Accept-Language": i18n.language || 'el',
              "X-CSRFToken": cookies.csrftoken,
              "Authorization": cookies.auth
          },
          xhrFields: {
              withCredentials: true,
              xsrfCookieName: 'csrftoken',
              xsrfHeaderName: 'X-CSRFToken'
          },
          crossDomain: true,
          dataType: 'json',
          success: function (data) {
            data.id = field.id;
            return data;
          },
          error: function (error) {
            console.error(
              t("fields.error.field_details_list")
              .replace("\{\{field_name\}\}", field.name), 
              error
            );
            toast.error(
              `${
                t("fields.error.field_details_list")
                .replace("\{\{field_name\}\}", field.name)
              } ${error}`
            );
          }
      }));
      return null;
    });

    Promise.all(fieldDetailPromises)
      .then((details) => setFieldDetails(details))
      .catch((error) => console.error(
        t("fields.error.field_details"), 
        error
      ))
  }, [fields]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRowClick = (index, field_name) => {
    setSelectedRow(index);
    setSelectedFieldName(field_name);
    setClickedField(null); // Reset ClickedField when a row is selected
        // Find the field details for the clicked field
    const clickedFieldId = fields[index].id;
    const clickedFieldDetails = fieldDetails.find((detail) => detail.id === clickedFieldId);
    if (clickedFieldDetails) {
      const weatherInfo = clickedFieldDetails.weather;
      setCookie('sidebarWeather', weatherInfo, { path: '/' });
    }
  };

  useEffect(() => {
      if (clickedField) {
        setSelectedRow(null); // Reset selectedRow when a field is clicked
      }
      const rowElement = document.getElementById(`row-${clickedField}`);
      if (rowElement) {
        rowElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        rowElement.focus();
      }
    }, [clickedField]);

  const getIconForRow = (suggestions) => {
    const today = new Date();
    let storedIcons = [];
    if(suggestions === undefined){
      return loadingIcon;
    }
    for (const suggestionType in suggestions) {
      const suggestionObjects = suggestions[suggestionType];
      for (const suggestionObject of suggestionObjects) {
        if (suggestionObject['status'] === 1)
          {
             const dateStr = suggestionObject['date'];
             if(dateStr != null){        
               const suggestionDate = new Date(dateStr);
               const timeDiff = suggestionDate.getTime() - today.getTime();
               const daysDiff = timeDiff / (1000 * 3600 * 24);
   
               if (daysDiff <= 2) {
                 storedIcons.push(warningIcon);
               } else if (daysDiff <= 4) {
                 storedIcons.push(cautionIcon);
               }
             }else{
               storedIcons.push(cautionIcon);
             }
          }
      }
    }
    if (storedIcons.length > 0) {
      if (storedIcons.includes(warningIcon)) {
        return warningIcon;
      }
      else if (storedIcons.includes(cautionIcon)) {
        return cautionIcon;
      }
    }

    return checkedIcon; 
  };


  return (
    <div className='content'>
      {/* <div className="page">  
        <div className="table-title">
          <img
            src={originalLand}
            alt="Tiny"
            className="tiny-image"
          />
          <span>{document.title}</span>
        </div>*/}
        <div className="aggro-map" >
          <MapComponent 
            selectedFieldName={selectedFieldName} 
            setClickedField={setClickedField} 
            fieldIdForDelete={fieldIdForDelete}
            fieldsData={fieldDetails}
          />
        </div>
        <div className="table-headers-container"> {/* New div for table headers */}
            <table className="table-headers">
              <thead>
                <tr>
                  <th>
                    <Link style={{textDecoration:"none"}} className='links' to="/create-fields">
                      <button className="AddButton" variant="success">
                        {t("fields.form.add")}
                      </button>
                    </Link>
                  </th>
                  <th>{t("fields.form.name")}</th>
                  <th>{t("fields.form.community")}</th>
                  <th>{t("fields.form.district")}</th>
                  <th className='table-perifereia'>{t("fields.form.municipality")}</th>
                  <th>{t("fields.form.status")}</th>
                  {/*<th>{t("fields.form.farmer")}</th>*/}
                </tr>
              </thead>
            </table>
        </div>
        <div className="table-container">
          <table className="table-fields">
            <tbody>
              {fields.map((field, index) => (
                <tr
                  key={field.id}
                  id={`row-${field.id}`}
                  className={    
                    (selectedRow === index && clickedField !== field.id) 
                    ||
                    (selectedRow !== index && clickedField === field.id) 
                    ? 'selected-row' : ''
                  }
                  // style={{ background: calculateRowColor(fieldDetails[index]?.suggestions) }}
                  onClick={() => handleRowClick(index, field.name)}
                >
                  <td>
                    <Link style={{textDecoration:"none"}} className='links' to={"/add-to-field/"+field.id}>
                      <img
                        onMouseEnter={() => handleMouseEnterAdd(index)}
                        onMouseLeave={() => handleMouseLeaveAdd(index)}
                        src={isHoveredAdd[index] ? hoverAdd : originalAdd}
                        alt="Add"
                        className={`tiny-button ${isHoveredAdd[index] ? 'hovered' : ''}`}
                      />
                    </Link>
                    <Link style={{textDecoration:"none"}} className='links' to={"/edit-field?id="+field.id}>
                      <img
                        onMouseEnter={() => handleMouseEnterEdit(index)}
                        onMouseLeave={() => handleMouseLeaveEdit(index)}
                        src={isHoveredEdit[index] ? hoverEdit : originalEdit}
                        alt="Edit"
                        className={`tiny-button ${isHoveredEdit[index] ? 'hovered' : ''}`}
                      />
                    </Link>
                    <Link style={{textDecoration:"none"}} className='links' to={"/view-field?id="+field.id}>
                    <img
                      onMouseEnter={() => handleMouseEnterView(index)}
                      onMouseLeave={() => handleMouseLeaveView(index)}
                      src={isHoveredView[index] ? hoverView : originalView}
                      alt="View"
                      className={`tiny-button ${isHoveredView[index] ? 'hovered' : ''}`}
                    />
                    </Link>  
                    <img
                      onMouseEnter={() => handleMouseEnterDelete(index)}
                      onMouseLeave={() => handleMouseLeaveDelete(index)}
                      src={isHoveredDelete[index] ? hoverDelete : originaLDelete}
                      alt="Delete"
                      className="tiny-button"
                      onClick={() => handleDeleteClick(field)} 
                    />
                  </td>
                  <td>
                    {field.name}
                  </td>
                  <td>
                    {field.community || 'N/A'}
                  </td>
                  <td>
                    {field.municipality || 'N/A'}
                  </td>
                  <td>
                    {field.prefecture || 'N/A'}
                  </td>
                  {/*<td>
                    {fieldDetails[index]?.owner?.user?.first_name + " " + fieldDetails[index]?.owner?.user?.last_name || 'N/A'}
                  </td>*/}
                  <td>
                  {getIconForRow(fieldDetails[index]?.suggestions) && (
                      getIconForRow(fieldDetails[index]?.suggestions) === checkedIcon ? (
                        <img
                          src={getIconForRow(fieldDetails[index]?.suggestions)}
                          alt="Icon"
                          className="status-icon-checked"
                        />
                      ) : (
                        <img
                          src={getIconForRow(fieldDetails[index]?.suggestions)}
                          alt="Icon"
                          className="status-icon"
                          onClick={() => handleIconClick(fieldDetails[index]?.suggestions)}
                        />
                      )
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      {/* </div> */}
      {/* ToastContainer for displaying notifications */}
      <ToastContainer
        position="bottom-right"
        autoClose={7000} // Close the notification after 7 seconds
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {showDeleteConfirmation && (
      <DeleteConfirmationModal
        field={fieldToDelete}
        onCancel={() => setShowDeleteConfirmation(false)}
        onConfirm={() => handleConfirmDelete()}
      />
      )}
      {showSuggestionsModal && (
      <SuggestionsModal
        suggestions={modalSuggestions}
        onClose={() => setShowSuggestionsModal(false)}
      />
      )}
    </div>
  );
}

export default FieldsTable;
