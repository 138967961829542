import React, { useEffect, useRef, useState } from 'react';
import Tooltip from 'react-tooltip';
import Wkt from 'wicket';
import * as yup from 'yup';

import MapCreateComponent from './MapCreateComponent';
import { usePageTitle } from './JS_Context/ContextTitle';

import $ from "jquery";
import { useCookies } from 'react-cookie';
import getServerURL from "./Configuration.js";
import originalAccept from './img/ICON_ACCEPT_BLACK.png';
import hoverAccept from './img/ICON_ACCEPT_ORANGE.png';
import originalDecline from './img/ICON_DECLINE_BLACK.png';
import hoverDecline from './img/ICON_DECLINE_ORANGE.png';
import originalEdit from './img/ICON_EDIT_LEAF_BLACK.png';
import hoverEdit from './img/ICON_EDIT_LEAF_ORANGE.png';
import originalFileImport from './img/ICON_IMPORT_BLACK.png';
import hoverFileImport from './img/ICON_IMPORT_ORANGE.png';
import originalPolygon from './img/ICON_POLUGON.png';
import hoverPolygon from './img/ICON_POLUGON_ORANGE.png';
import originalTrash from './img/ICON_TRASH_BLACK.png';
import hoverTrash from './img/ICON_TRASH_ORANGE.png';

import 'leaflet/dist/leaflet.css';
import './CSSCustom/fieldForms.css';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useTranslation } from 'react-i18next';

const serverUrl = getServerURL();

function CreateFields() {
  const [isHoveredPolygon, setIsHoveredPolygon] = useState(false);
  const [isHoveredEdit, setIsHoveredEdit] = useState(false);
  const [isHoveredTrash, setIsHoveredTrash] = useState(false);
  const [isHoveredFileImport, setIsHoveredFileImport] = useState(false);
  const [isHoveredAccept, setIsHoveredAccept] = useState(false);
  const [isHoveredDecline, setIsHoveredDecline] = useState(false);
  const [isClearPressed, setisClearPressed] = useState(false);
  const [cookies] = useCookies(['csrftoken']);
  const [formData, setFormData] = useState({
    name: '',
    geom: '',
    community: '',
    chartography_bg: '',
  });
  const [errors, setErrors] = useState({});
  const { t, i18n } = useTranslation();
  const { setPageTitle, setPageTitleIcon } = usePageTitle();
  const geoFieldRef = useRef();

  // Polygon icon hover handle
  const handleMouseEnterPolygon = () => {
    setIsHoveredPolygon(true);
  };

  const handleMouseLeavePolygon = () => {
    setIsHoveredPolygon(false);
    // Tooltip.hide() //to work need to remove ReactStrictMode from App.js
  };

  const handlePolygonClick = () => {
    let e = document.createEvent('Event');
    e.initEvent('click', true, true);
    let cb = document.getElementsByClassName('leaflet-draw-draw-polygon');
    return !cb[0].dispatchEvent(e);
  };

  // Edit icon hover handle
  const handleMouseEnterEdit = () => {
    setIsHoveredEdit(true);
  };

  const handleMouseLeaveEdit = () => {
    setIsHoveredEdit(false);
  };

  const handleEditClick = () => {
    let e = document.createEvent('Event');
    e.initEvent('click', true, true);
    let cb = document.getElementsByClassName('leaflet-draw-edit-edit');
    return !cb[0].dispatchEvent(e);
  };

  // File Import icon hover handle
  const handleMouseEnterFileImport = () => {
    setIsHoveredFileImport(true);
  };

  const handleMouseLeaveFileImport = () => {
    setIsHoveredFileImport(false);
  };

  const handleFileImportClick = () => {
    console.log("File Import here")
  };

  // Trash icon hover handle
  const handleMouseEnterTrash = () => {
    setIsHoveredTrash(true);
  };

  const handleMouseLeaveTrash = () => {
    setIsHoveredTrash(false);
  };

  const handleTrashClick = () => {
    let e = document.createEvent('Event');
    e.initEvent('click', true, true);
    let cb = document.getElementsByClassName('leaflet-draw-edit-remove');
    return !cb[0].dispatchEvent(e);
  };

  // Accept icon hover handle
  const handleMouseEnterAccept = () => {
    setIsHoveredAccept(true);
  };

  const handleMouseLeaveAccept = () => {
    setIsHoveredAccept(false);
  };
  
  // Decline icon hover handle
  const handleMouseEnterDecline = () => {
    setIsHoveredDecline(true);
  };

  const handleMouseLeaveDecline = () => {
    setIsHoveredDecline(false);
  };

  useEffect(() =>{
    setPageTitle("fields.create.title");
    setPageTitleIcon("/img/ICON_AGRO_GREEN.png");
  },[])

  //Set geo value of the Form based on current geo Reference
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
      if (geoFieldRef.current) {
        setFormData((prevFormData) => ({
            ...prevFormData,
            geom: geoFieldRef.current.value,
        }));
      }
      
  }, [geoFieldRef.current?.value]);

  // Validate geom field when geoFieldRef.current.value changes
  useEffect(() => {
    if (geoFieldRef.current.value !== undefined) {
      validationSchema
        .validateAt('geom', { geom: geoFieldRef.current.value })
        .then(() => {
          // No validation errors for geom field, clear the error for geom field
          setErrors((prevErrors) => ({ ...prevErrors, geom: undefined }));
        })
        .catch((error) => {
          // Validation error occurred, set the error for geom field
          setErrors((prevErrors) => ({ ...prevErrors, geom: error.message }));
        });
    }
  }, [geoFieldRef.current?.value]); // eslint-disable-line react-hooks/exhaustive-deps
  

  // Form Data
  const validateGeom = (value) => {
    const wkt = new Wkt.Wkt();

    try {
      wkt.read(value);
      const geometryType = wkt.type;

      if (!(geometryType === 'polygon' || geometryType === 'multipolygon')) {
        throw new Error(`${t('fields.form.validation.geometry_type')} ${geometryType}`);
      }

      return true; // Validation passed
    } catch (error) {
      console.error('WKT Validation Error:', error.message);
      return false; 
    }
  };

  const validateCB = (value) => {
    // Allow empty values
    if (!value) {
      return true;
    }
    // Check if the value is a number and exactly 13 digits long
    const valueStr = value.toString();
    if (!/^\d+$/.test(valueStr)) {
      return false;
    }
    if (valueStr.length !== 13) {
      return false;
    }
    return true;
  }

const validationSchema = yup.object().shape({
  name: yup.string().required(t('fields.form.validation.required'))
  .max(50, t('fields.form.validation.name_length')),
  geom: yup.string()
  .required(t('fields.form.validation.required'))
  .test('custom-validation', t('fields.form.validation.geometry'), 
  validateGeom),
  community: yup.string().max(80, t('fields.form.validation.community')),
  chartography_bg: yup.string().test('len', t('fields.form.validation.chartography'), 
  validateCB)
});

const clearEmptyData = (data) => {
  Object.keys(data).forEach(key => {
    if (data[key] === '' || data[key] == null) {
      delete data[key];
    }
  });
  return data;
}

const startSoilJob = (in_data) => {
    return $.ajax({
      url: serverUrl+"/soil-preload/"+in_data+"/",
      type: 'put',
      headers: {
        "Accept-Language": i18n.language || 'el',
        'X-CSRFToken': cookies.csrftoken,
        'Content-Type': 'application/json',
        "Authorization": cookies.auth
      },
      xhrFields: {
        withCredentials: true,
        xsrfCookieName: 'csrftoken',
        xsrfHeaderName: 'X-CSRFToken'
      },
      crossDomain: true,
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      data: JSON.stringify(formData),
      success: function (data, textStatus, request) {
        // Handle the response, e.g. success message
        toast.success(t('fields.form.soil_job'));
        handleReset();
        handleClear();
      },
      error: function (request, textStatus, errorThrown) {
          // Handle errors
          console.warn('PUT request failed:', errorThrown);
          if (errorThrown.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.warn('Server responded with:', request.response.data);
            console.warn('Status code:', textStatus);
          } else if (request) {
            // The request was made but no response was received
            console.warn('No response received from the server');
          } else {
            // Something happened in setting up the request that triggered an Error
            console.warn('Error setting up the request:', errorThrown);
          }
          toast.warning(`${t('fields.form.soil_job_fail')} ${errorThrown}`);
      }
  });
}

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Validate the entire form before submitting
      await validationSchema.validate(formData, { abortEarly: false });
      setFormData(clearEmptyData(formData));

      // If validation passes, proceed to the server request
      await $.ajax({
          url: serverUrl+"/fields/",
          type: 'post',
          headers: {
            "Accept-Language": i18n.language || 'el',
            'X-CSRFToken': cookies.csrftoken,
            'Content-Type': 'application/json',
            "Authorization": cookies.auth
          },
          xhrFields: {
            withCredentials: true,
            xsrfCookieName: 'csrftoken',
            xsrfHeaderName: 'X-CSRFToken'
          },
          crossDomain: true,
          contentType: "application/json; charset=utf-8",
          dataType: "json",
          data: JSON.stringify(formData),
          success: function (data, textStatus, request) {
            // Handle the response, e.g. success message
            toast.success(t('fields.create.success'));
            startSoilJob(data['id']);
            handleReset();
            handleClear();
          },
          error: function (request, textStatus, errorThrown) {
              // Handle errors
              console.error('POST request failed:', errorThrown);
              if (errorThrown.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('Server responded with:', request.response.data);
                console.error('Status code:', textStatus);
              } else if (request) {
                // The request was made but no response was received
                console.error('No response received from the server');
              } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error setting up the request:', errorThrown);
              }
              toast.error(`${t("common.error.general")}: ${errorThrown}`);
          }
      });
      return;
    } catch (validationError) {
      try{
        // Handle validation errors
        const validationErrors = {};
        console.log("Validation error:",validationError);
        validationError.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        setErrors(validationErrors);
    
        // Optionally, display an error message to the user
        console.error('Validation failed:', validationError);
      }catch(ex){
        toast.error(`${t("common.error.general")}: ${validationError}`);
        console.error('Error occured:',validationError);
      }
    }
  };

  const handleAcceptClick = (event) => {
    handleSubmit(event);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    // Validate the field on each change
    validationSchema
      .validateAt(name, { [name]: value })
      .then(() => {
        // No validation errors for this field, clear the error for this field
        setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
      })
      .catch((error) => {
        // Validation error occurred, set the error for this field
        setErrors((prevErrors) => ({ ...prevErrors, [name]: error.message }));
      });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleReset = () => {
    // Reset the form data state to empty values
    setFormData({
      name: '',
      geom: '',
      community: '',
      chartography_bg: '',
    });
  };

  const handleClear = () => {
    // Clear the input fields
    const inputFields = document.querySelectorAll('.create-input');
    inputFields.forEach((input) => {
      input.value = '';
    });
    geoFieldRef.current.dispatchEvent(new Event('input', { bubbles: true}));
  }

  const handleDeclineClick = () => {
    // Clear the form data
    handleReset();
    handleClear();
    setisClearPressed(true)
  };

  return (
    <div className="content page1_content">

      <div className="create-field-map" >
          <MapCreateComponent geoFieldRef={geoFieldRef} isClearPressed={isClearPressed} setisClearPressed={setisClearPressed}/>
      </div>
      <div className="form-background">
        <form onSubmit={handleSubmit}>
          <div className="form-container">
            <div className="first-button-row">
              <>
                <img
                    onMouseEnter={() => handleMouseEnterPolygon()}
                    onMouseLeave={() => handleMouseLeavePolygon()}
                    src={isHoveredPolygon ? hoverPolygon : originalPolygon}
                    alt="CreatePolygon"
                    data-tip={t("common.map.create")}
                    data-place="bottom"
                    className={`leaflet-button-create1 ${isHoveredPolygon ? 'hovered' : ''}`}
                    onClick={() => handlePolygonClick()} 
                />
              </>
              <>        
                <img
                    onMouseEnter={() => handleMouseEnterEdit()}
                    onMouseLeave={() => handleMouseLeaveEdit()}
                    src={isHoveredEdit ? hoverEdit : originalEdit}
                    alt="Edit"
                    data-tip={t("common.map.edit")}
                    data-place="bottom"
                    className={`leaflet-button-create3 ${isHoveredEdit ? 'hovered' : ''}`}
                    onClick={() => handleEditClick()} 
                />
                <Tooltip effect="solid"  hideEvent="mouseout"/>
              </>
              {/*<>  
                <img
                    onMouseEnter={() => handleMouseEnterFileImport()}
                    onMouseLeave={() => handleMouseLeaveFileImport()}
                    src={isHoveredFileImport ? hoverFileImport : originalFileImport}
                    alt="FileImport"
                    data-tip={t("common.map.file")}
                    data-place="bottom"
                    className={`leaflet-button-create4 ${isHoveredFileImport ? 'hovered' : ''}`}
                    onClick={() => handleFileImportClick()} 
                />
                <Tooltip effect="solid"  hideEvent="mouseout"/>
              </>*/}
              <>
                <img
                    onMouseEnter={() => handleMouseEnterTrash()}
                    onMouseLeave={() => handleMouseLeaveTrash()}
                    src={isHoveredTrash ? hoverTrash : originalTrash}
                    alt="Trash"
                    data-tip={t("common.map.delete")}                   
                    data-place="bottom"
                    className={`leaflet-button-create5 ${isHoveredTrash ? 'hovered' : ''}`}
                    onClick={() => handleTrashClick()} 
                />
                <Tooltip effect="solid"  hideEvent="mouseout"/>
            </>
            </div>
            <div className="second-button-row">
            <img
                  onMouseEnter={() => handleMouseEnterAccept()}
                  onMouseLeave={() => handleMouseLeaveAccept()}
                  src={isHoveredAccept ? hoverAccept : originalAccept}
                  alt={t("common.map.accept")}
                  className={`accept-decline ${isHoveredAccept ? 'hovered' : ''}`}
                  onClick={(event) => handleAcceptClick(event)} 
                />
                <img
                  onMouseEnter={() => handleMouseEnterDecline()}
                  onMouseLeave={() => handleMouseLeaveDecline()}
                  src={isHoveredDecline ? hoverDecline : originalDecline}
                  alt={t("common.map.cancel")}
                  className={`accept-decline ${isHoveredDecline ? 'hovered' : ''}`}
                  onClick={() => handleDeclineClick()} 
                />
            </div>
            
            <div className="form-row">
              <label htmlFor="name" className='label-create'>{t('fields.form.name')}</label>
              <input type="text" id="name" name="name" 
                onChange={handleChange} className='create-input'  placeholder={t('fields.form.placeholder.name')}
              />
              {errors.name && <span className="error-message">{errors.name}</span>}
            </div>
            <div className="form-row">
              <label htmlFor="geom" className='label-create'>{t('fields.form.geometry')}</label>
              <input type="text" id="geom" name="geom" 
                placeholder="MULTIPOLYGON (((22.167664 39.302247, 22.223969 39.307562, 
                  22.241821 39.222479, 22.101746 39.242695, 22.167664 39.302247)))"
                ref={geoFieldRef} 
                onChange={handleChange} 
                onKeyDown={handleChange}  
                className='create-input'
              />
              {errors.geom && <span className="error-message">{errors.geom}</span>}
            </div>
            <div className="form-row">
              <label htmlFor="community" className='label-create'>{t('fields.form.community')}</label>
              <input type="text" id="community" name="community" placeholder={t('fields.form.placeholder.community')}
                onChange={handleChange} className='create-input'
              />
              {errors.community && <span className="error-message">{errors.community}</span>}
            </div>
            <div className="form-row">
              <label htmlFor="chartography_bg" className='label-create'>{t('fields.form.cartography')}</label>
              <input type="text" id="chartography_bg" name="chartography_bg"  placeholder="1245780965321"
                onChange={handleChange} className='create-input'
              />
              {errors.chartography_bg && <span className="error-message">{errors.chartography_bg}</span>}
            </div>
          </div>
        </form>
      </div>
       {/* ToastContainer for displaying notifications */}
      <ToastContainer
        position="bottom-right"
        autoClose={7000} // Close the notification after 7 seconds
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
    
  );
};

export default CreateFields;
