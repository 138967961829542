import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import Tooltip from 'react-tooltip';
import * as yup from 'yup';

import MapCreateComponent from './MapCreateComponent';
import { usePageTitle } from './JS_Context/ContextTitle';
import { loadSwaggerEnum, loadModelEnum } from './Utils.js';
import Wkt from 'wicket';

import $ from "jquery";
import { useCookies } from 'react-cookie';
import getServerURL from "./Configuration.js";
import originalAccept from './img/ICON_ACCEPT_BLACK.png';
import hoverAccept from './img/ICON_ACCEPT_ORANGE.png';
import originalDecline from './img/ICON_DECLINE_BLACK.png';
import hoverDecline from './img/ICON_DECLINE_ORANGE.png';
import originalEdit from './img/ICON_EDIT_LEAF_BLACK.png';
import hoverEdit from './img/ICON_EDIT_LEAF_ORANGE.png';
import originalFileImport from './img/ICON_IMPORT_BLACK.png';
import hoverFileImport from './img/ICON_IMPORT_ORANGE.png';
import originalPolygon from './img/ICON_POLUGON.png';
import hoverPolygon from './img/ICON_POLUGON_ORANGE.png';
import originalTrash from './img/ICON_TRASH_BLACK.png';
import hoverTrash from './img/ICON_TRASH_ORANGE.png';

import 'leaflet/dist/leaflet.css';
import './CSSCustom/fieldForms.css';

import { toast, ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';

const serverUrl = getServerURL();

function CreateCultivationData() {
  const [isHoveredPolygon, setIsHoveredPolygon] = useState(false);
  const [isHoveredEdit, setIsHoveredEdit] = useState(false);
  const [isHoveredTrash, setIsHoveredTrash] = useState(false);
  const [isHoveredFileImport, setIsHoveredFileImport] = useState(false);
  const [isHoveredAccept, setIsHoveredAccept] = useState(false);
  const [isHoveredDecline, setIsHoveredDecline] = useState(false);
  const [isClearPressed, setisClearPressed] = useState(false);
  const [isRedrawPressed, setisRedrawPressed] = useState(false);
  const [cultDataForm, setCultDataForm] = useState();
  const { t, i18n } = useTranslation();
  const { setPageTitle, setPageTitleIcon } = usePageTitle();
  const [cookies] = useCookies(['csrftoken']);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [parameterOptions, setParameterOptions] = useState([]);
  const [parameterLabelOptions, setParameterLabelOptions] = useState([]);
  const {selectedCultivation} = useParams();
  const geoFieldRef = useRef();
  const [cultDataOptions, setCultDataOptions] = useState([
      {value:"irrigations", label:t('actions.irrigations.table.title')},
      {value:"fertilizations", label:t('actions.fertilizations.table.title')},
      {value:"harvests", label:t('actions.harvests.table.title')},
      {value:"plant_protections", label:t('actions.plant_protections.table.title')},
      {value:"visitations", label:t('actions.visitations.table.title')},
      {value:"general_actions", label:t('actions.general_actions.table.title')},
      {value:"leaf-analysis", label:t('analysis.leaf.table.title')}
    ]);
  const [cultDataSelected, setCultDataSelected] = useState(cultDataOptions[0]);

  // Polygon icon hover handle
  const handleMouseEnterPolygon = () => {
    setIsHoveredPolygon(true);
  };

  const handleMouseLeavePolygon = () => {
    setIsHoveredPolygon(false);
    // Tooltip.hide() //to work need to remove ReactStrictMode from App.js
  };

  const handlePolygonClick = () => {
    let e = document.createEvent('Event');
    e.initEvent('click', true, true);
    let cb = document.getElementsByClassName('leaflet-draw-draw-polygon');
    return !cb[0].dispatchEvent(e);
  };

  // Edit icon hover handle
  const handleMouseEnterEdit = () => {
    setIsHoveredEdit(true);
  };

  const handleMouseLeaveEdit = () => {
    setIsHoveredEdit(false);
  };

  const handleEditClick = () => {
    let e = document.createEvent('Event');
    e.initEvent('click', true, true);
    let cb = document.getElementsByClassName('leaflet-draw-edit-edit');
    return !cb[0].dispatchEvent(e);
  };

  // File Import icon hover handle
  const handleMouseEnterFileImport = () => {
    setIsHoveredFileImport(true);
  };

  const handleMouseLeaveFileImport = () => {
    setIsHoveredFileImport(false);
  };

  const handleFileImportClick = () => {
  };

  // Trash icon hover handle
  const handleMouseEnterTrash = () => {
    setIsHoveredTrash(true);
  };

  const handleMouseLeaveTrash = () => {
    setIsHoveredTrash(false);
  };

  const handleTrashClick = () => {
    let e = document.createEvent('Event');
    e.initEvent('click', true, true);
    let cb = document.getElementsByClassName('leaflet-draw-edit-remove');
    return !cb[0].dispatchEvent(e);
  };

  // Accept icon hover handle
  const handleMouseEnterAccept = () => {
    setIsHoveredAccept(true);
  };

  const handleMouseLeaveAccept = () => {
    setIsHoveredAccept(false);
  };
  
  // Decline icon hover handle
  const handleMouseEnterDecline = () => {
    setIsHoveredDecline(true);
  };

  const handleMouseLeaveDecline = () => {
    setIsHoveredDecline(false);
  };

  useEffect(() =>{
    if(cultDataSelected?.value){
      let trans_file = cultDataSelected.value;
      if(trans_file.includes("analysis")){
        let splits = trans_file.split("-");
        trans_file = splits[1]+"."+splits[0];
      }else{
        trans_file = "actions."+trans_file;
      }
      setPageTitle(trans_file+".create.title");
    }else{
      setPageTitle("cultivations.create.title_data");
    }
    
    setPageTitleIcon("/img/ICON_EDIT_LEAF_BLACK.png");
  },[cultDataSelected])

  useEffect(() => {
    const updatedOptions = [
      {value:"irrigations", label:t('actions.irrigations.table.title')},
      {value:"fertilizations", label:t('actions.fertilizations.table.title')},
      {value:"harvests", label:t('actions.harvests.table.title')},
      {value:"plant_protections", label:t('actions.plant_protections.table.title')},
      {value:"visitations", label:t('actions.visitations.table.title')},
      {value:"general_actions", label:t('actions.general_actions.table.title')},
      {value:"leaf-analysis", label:t('analysis.leaf.table.title')}
    ];
    setCultDataOptions(updatedOptions);
    const cultDataSelectedOption = updatedOptions.find(cultDataOption => cultDataOption.value === cultDataSelected.value);
    if (cultDataSelectedOption) {
      handleActionTypeChange(cultDataSelectedOption);
    }
  }, [i18n.language, cultDataSelected.value]);

  //Set geo value of the Form based on current geo Reference
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
      if (geoFieldRef.current) {
        setFormData((prevFormData) => ({
            ...prevFormData,
            geom: geoFieldRef.current.value,
        }));
    }
  }, [geoFieldRef.current?.value]);

  function loadHarvestUnitOptions(){
    return loadSwaggerEnum({endpoint:'/harvests/',attribute:'unit',cookies});
  };

  function loadHarvestMethodOptions(){
    return loadSwaggerEnum({endpoint:'/harvests/',attribute:'method',cookies});
  };

  function loadIrrigationMethodOptions(){
    return loadSwaggerEnum({endpoint:'/irrigations/',attribute:'method',cookies});
  }

  function loadFertilizationMethodOptions(){
    return loadSwaggerEnum({endpoint:'/fertilizations/',attribute:'method',cookies});
  }

  function loadFertilizationUnitOptions(){
    return loadSwaggerEnum({endpoint:'/fertilizations/',attribute:'unit',cookies});
  }

  function loadFertilizationWeatherOptions(){
    return loadSwaggerEnum({endpoint:'/fertilizations/',attribute:'weather',cookies});
  }

  function loadFertilizationCategoryOptions(){
    return loadSwaggerEnum({endpoint:'/fertilizations/',attribute:'category',cookies});
  }

  function loadPlantProtectionUnitOptions(){
    return loadSwaggerEnum({endpoint:'/plant_protections/',attribute:'unit',cookies});
  }

  function loadPlantProtectionWeatherOptions(){
    return loadSwaggerEnum({endpoint:'/plant_protections/',attribute:'weather',cookies});
  }

  function loadPlantProtectionSubstanceOptions(){
    return loadModelEnum({endpoint:'/substances/',value:'name',label:'name',attribute:'substance',cookies});
  };

  function loadPlantProtectionProductOptions(){
  return loadModelEnum({endpoint:'/products/',value:'name',label:'name',attribute:'product',cookies});
  };

  function loadParameterTypeOptions(){
    return loadSwaggerEnum({endpoint:'/leaf-analysis/',attribute:'parameters.items.properties.type',cookies});
  };

  function loadParameterLabelOptions(){
    return loadSwaggerEnum({endpoint:'/leaf-analysis/',attribute:'parameters.items.properties.label',cookies});
  };

  useLayoutEffect(() => {
    loadParameterTypeOptions().then((result) => {
      setParameterOptions(result);
    });
    loadParameterLabelOptions().then((result) => {
      setParameterLabelOptions(result);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Validate geom field when geoFieldRef.current.value changes
  useEffect(() => {
    if (geoFieldRef.current?.value !== undefined) {
      validationSchema
        .validateAt('geom', { geom: geoFieldRef.current?.value })
        .then(() => {
          // No validation errors for geom field, clear the error for geom field
          setErrors((prevErrors) => ({ ...prevErrors, geom: undefined }));
        })
        .catch((error) => {
          // Validation error occurred, set the error for geom field
          setErrors((prevErrors) => ({ ...prevErrors, geom: error.message }));
        });
        geoFieldRef.current.dispatchEvent(new Event('input'));
    }
  }, [geoFieldRef.current?.value]); // eslint-disable-line react-hooks/exhaustive-deps
  

  // Form Data
  const validateGeom = (value) => {
    const wkt = new Wkt.Wkt();

    try {
      wkt.read(value);
      const geometryType = wkt.type;

      if (!(geometryType === 'polygon' || geometryType === 'multipolygon')) {
        throw new Error(`${t('cultivations.form.validation.geometry_type')} ${geometryType}`);
      }

      return true; // Validation passed
    } catch (error) {
      console.error('WKT Validation Error:', error.message);
      return false; 
    }
  };

  const validationSchema = yup.object().shape({
    geom: yup.string()
    .required(t('cultivations.form.validation.required'))
    .test('custom-validation', t('cultivations.form.validation.geometry'), 
    validateGeom),
    date: yup.date(),
    estimation_date: yup.date(),
    estimated_quantity: yup.number(),
    quantity: yup.number(),
    unit: yup.string(),
    method: yup.string(),
    partial: yup.string(),
    duration: yup.number(),
    volume: yup.number(),
    workers: yup.number(),
    weather: yup.string(),
    category: yup.string(),
    phi: yup.string(),
    harvestability: yup.string(),
    comments: yup.string(),
    substance: yup.string(),
    product: yup.string(),
    reason: yup.string(),
    value_number: yup.number().min(0, t('analysis.form.validation.value_number')),
    value_text: yup.string()
});

const clearEmptyData = (data) => {
  Object.keys(data).forEach(key => {
    if (data[key] === '' || data[key] == null) {
      delete data[key];
    }
  });
  return data;
}

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Validate the entire form before submitting
      let tempFormData = formData;
      tempFormData['cultivation'] = selectedCultivation;
      if(geoFieldRef?.current?.value){
        tempFormData['geom'] = geoFieldRef.current.value;
      }
      for (let key in tempFormData) {
        if (tempFormData.hasOwnProperty(key)) {
            let datum_obj = $('#'+key);
            let datum_val = tempFormData[key];
            let datum_name = datum_obj.id;
            if(datum_obj.attr("validate_key")){
              datum_name = datum_obj.attr("validate_key");
            }
            validationSchema
              .validateAt(datum_name, { [datum_name]: datum_val })
              .then(() => {
                // No validation errors for this field, clear the error for this field
                setErrors((prevErrors) => ({ ...prevErrors, [datum_obj.id]: undefined }));
              })
              .catch((error) => {
                // Validation error occurred, set the error for this field
                setErrors((prevErrors) => ({ ...prevErrors, [datum_obj.id]: error.message }));
            });
        }
      }
      setFormData(tempFormData);
      setFormData(clearEmptyData(formData));

      if(cultDataSelected?.value === "leaf-analysis"){
        let tempFormData = formData;
        tempFormData["parameters"] = [];
        for (let key in tempFormData) {
          if (tempFormData.hasOwnProperty(key)) {
            if(key.startsWith("param_")){
              let datum_obj = $("[id='"+key+"']");
              let datum_label = datum_obj.attr("django-label");
              if(isNaN(tempFormData[key])){
                tempFormData["parameters"].push(
                  {
                    "type":key.substring(6),
                    "label":datum_label,
                    "value_text":tempFormData[key]
                  }
                );
              }else{
                tempFormData["parameters"].push(
                  {
                    "type":key.substring(6),
                    "label":datum_label,
                    "value_number":tempFormData[key]
                  }
                );
              }
              delete tempFormData[key];
            }
          }
        }
        setFormData(tempFormData);
      }

      // If validation passes, proceed to the server request
      await $.ajax({
          url: serverUrl+"/"+cultDataSelected.value+"/",
          type: 'post',
          headers: {
            "Accept-Language": i18n.language || 'el',
            'X-CSRFToken': cookies.csrftoken,
            'Content-Type': 'application/json',
            "Authorization": cookies.auth
          },
          xhrFields: {
            withCredentials: true,
            xsrfCookieName: 'csrftoken',
            xsrfHeaderName: 'X-CSRFToken'
          },
          crossDomain: true,
          contentType: "application/json; charset=utf-8",
          dataType: "json",
          data: JSON.stringify(formData),
          success: function (data, textStatus, request) {
            // Handle the response, e.g. success message
            let trans_file = cultDataSelected.value;
            if(trans_file.includes("analysis")){
              let splits = trans_file.split("-");
              trans_file = splits[1]+"."+splits[0];
            }else{
              trans_file = "actions."+trans_file;
            }
            toast.success(t(trans_file+'.create.success'));
            handleReset();
            handleClear();
            setisRedrawPressed(!isRedrawPressed);
          },
          error: function (request, textStatus, errorThrown) {
            let trans_file = cultDataSelected.value;
            if(trans_file.includes("analysis")){
              let splits = trans_file.split("-");
              trans_file = splits[1]+"."+splits[0];
            }else{
              trans_file = "actions."+trans_file;
            }
            // Handle errors
            console.error('POST request failed:', errorThrown);
            if (errorThrown.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.error('Server responded with:', request.response.data);
              console.error('Status code:', textStatus);
              toast.error(`${t(trans_file+'create.fail_2xx')} ${textStatus}.`);
            } else if (request) {
              // The request was made but no response was received
              console.error('No response received from the server');
              toast.error(t(trans_file+'create.fail_unreachable'));
            } else {
              // Something happened in setting up the request that triggered an Error
              console.error('Error setting up the request:', errorThrown);
              toast.error(t(trans_file+'create.fail_unknown'));
            }
            //toast.error(`An error occurred: ${errorThrown}`);
        }
      });
      return;
    } catch (validationError) {
      if(validationError.responseJSON){
        for (const [key, value] of Object.entries(validationError.responseJSON)) {
          toast.error(`${key}: ${value}`);
        }
      }
      else if(validationError.responseText){
        toast.error(`${t("common.error.general")}: ${validationError.responseText}`);
      }else{
        // Handle validation errors
        const validationErrors = {};
        if(validationError.inner){
          validationError.inner.forEach((error) => {
            validationErrors[error.path] = error.message;
          });
          setErrors(validationErrors);
        }
    
        // Optionally, display an error message to the user
        console.error('Validation failed:', validationError);
        //toast.error('Validation failed:', validationError);
      }
    }
  };

  const handleAcceptClick = (event) => {
    handleSubmit(event);
  };

  const handleChange = (event) => {
    let datum = event;
    if(event?.target){
      datum = event.target;
      datum.name = datum.id;
      if(datum.getAttribute("validate_key")){
        datum.name = datum.getAttribute("validate_key");
      }else{
        datum.name = datum.id;
      }
    }
    if(!datum.id){datum.id = datum.name;}
    const { name, value, id } = datum;
  
    // Validate the field on each change
    validationSchema
      .validateAt(name, { [name]: value })
      .then(() => {
        // No validation errors for this field, clear the error for this field
        setErrors((prevErrors) => ({ ...prevErrors, [id]: undefined }));
      })
      .catch((error) => {
        // Validation error occurred, set the error for this field
        setErrors((prevErrors) => ({ ...prevErrors, [id]: error.message }));
      });
  
    let temp_data = formData;
    temp_data[id] = value;
    setFormData(temp_data);
  };

  const handleReset = () => {
    // Reset the form data state to empty values
    setFormData({});
  };

  const handleClear = () => {
    // Clear the input fields
    const inputFields = document.querySelectorAll('.create-input');
    inputFields.forEach((input) => {
      input.value = '';
    });
    if(geoFieldRef?.current){
      geoFieldRef.current.dispatchEvent(new Event('input', { bubbles: true}));
    }
  }

  const handleDeclineClick = () => {
    // Clear the form data
    handleReset();
    handleClear();
    setisClearPressed(true)
  };

  const HarvestForm = () => {
    return (
      <span>
        <div className="form-row">
          <label htmlFor="geom" className='label-create'>{t("actions.harvests.form.geometry")}</label>
          <input type="text" id="geom" name="geom" 
            placeholder="MULTIPOLYGON (((22.167664 39.302247, 22.223969 39.307562, 
              22.241821 39.222479, 22.101746 39.242695, 22.167664 39.302247)))"
            ref={geoFieldRef} 
            onChange={handleChange} 
            onKeyDown={handleChange}  
            className='create-input'
          />
          {errors.geom && <span className="error-message">{errors.geom}</span>}
        </div>
        <div className="form-row">
          <label htmlFor="date" className='label-create'>{t("actions.harvests.form.date")}</label>
          <input type="datetime-local" id="date" name="name" 
            onChange={handleChange} className='create-input'
          />
          {errors.date && <span className="error-message">{errors.date}</span>}
        </div>
        <div className="form-row">
          <label htmlFor="estimation_date" className='label-create'>{t("actions.harvests.form.estimation_date")}</label>
          <input type="datetime-local" id="estimation_date" name="estimation_date" 
            onChange={handleChange} className='create-input'
          />
          {errors.estimation_date && <span className="error-message">{errors.estimation_date}</span>}
        </div>
        <div className="form-row">
          <label htmlFor="quantity" className='label-create'>{t("actions.harvests.form.quantity")}</label>
          <input type="number" id="quantity" name="quantity" 
            onChange={handleChange} className='create-input'  placeholder={t("actions.harvests.form.placeholder.quantity")}
          />
          {errors.number && <span className="error-message">{errors.number}</span>}
        </div>
        <div className="form-row">
          <label htmlFor="estimated_quantity" className='label-create'>{t("actions.harvests.form.estimated_quantity")}</label>
          <input type="number" id="estimated_quantity" name="estimated_quantity" 
            onChange={handleChange} className='create-input'  placeholder={t("actions.harvests.form.placeholder.estimated_quantity")}
          />
          {errors.number && <span className="error-message">{errors.number}</span>}
        </div>
        <div className="form-row">
          <label htmlFor="unit" className='label-create'>{t("actions.harvests.form.unit")}</label>
          <AsyncSelect  
            key={cultDataSelected.value}
            className='create-input' 
            id="unit" 
            onChange={handleChange}
            name="unit" 
            cacheOptions 
            defaultOptions 
            loadOptions={loadHarvestUnitOptions}>
              <option disabled selected value>{t("actions.harvests.form.placeholder.unit")}</option>
          </AsyncSelect>
          {errors.unit && <span className="error-message">{errors.unit}</span>}
        </div>
        <div className="form-row">
          <label htmlFor="method" className='label-create'>{t("actions.harvests.form.method")}</label>
          <AsyncSelect  
            key={cultDataSelected.value}
            className='create-input' 
            id="method" 
            onChange={handleChange}
            name="method" 
            cacheOptions 
            defaultOptions 
            loadOptions={loadHarvestMethodOptions}>
              <option disabled selected value>{t("actions.harvests.form.placeholder.method")}</option>
          </AsyncSelect>
          {errors.method && <span className="error-message">{errors.method}</span>}
        </div>
        <div className="form-row">
          <label htmlFor="partial" className='label-create'>{t("actions.harvests.form.partial")}</label>
          <input type="checkbox" id="partial" name="partial" 
            onChange={handleChange} className='create-input'  placeholder={t("actions.harvests.form.placeholder.partial")}
          />
          {errors.string && <span className="error-message">{errors.string}</span>}
        </div>
      </span>
    );
  };

  const IrrigationForm = () => {
    return (
      <span>
        <div className="form-row">
          <label htmlFor="geom" className='label-create'>{t("actions.irrigations.form.geometry")}</label>
          <input type="text" id="geom" name="geom" 
            placeholder="MULTIPOLYGON (((22.167664 39.302247, 22.223969 39.307562, 
              22.241821 39.222479, 22.101746 39.242695, 22.167664 39.302247)))"
            ref={geoFieldRef} 
            onChange={handleChange} 
            onKeyDown={handleChange}  
            className='create-input'
          />
          {errors.geom && <span className="error-message">{errors.geom}</span>}
        </div>
        <div className="form-row">
          <label htmlFor="date" className='label-create'>{t("actions.irrigations.form.date")}</label>
          <input type="datetime-local" id="date" name="name" 
            onChange={handleChange} className='create-input'
          />
          {errors.date && <span className="error-message">{errors.date}</span>}
        </div>
        <div className="form-row">
          <label htmlFor="duration" className='label-create'>{t("actions.irrigations.form.duration")}</label>
          <input type="number" id="duration" name="duration" 
            onChange={handleChange} className='create-input'
          />
          {errors.duration && <span className="error-message">{errors.duration}</span>}
        </div>
        <div className="form-row">
          <label htmlFor="volume" className='label-create'>{t("actions.irrigations.form.volume")}</label>
          <input type="number" id="volume" name="volume" 
            onChange={handleChange} className='create-input'
          />
          {errors.volume && <span className="error-message">{errors.volume}</span>}
        </div>
        <div className="form-row">
          <label htmlFor="workers" className='label-create'>{t("actions.irrigations.form.workers")}</label>
          <input type="number" id="workers" name="workers" 
            onChange={handleChange} className='create-input'
          />
          {errors.workers && <span className="error-message">{errors.workers}</span>}
        </div>
        <div className="form-row">
          <label htmlFor="method" className='label-create'>{t("actions.irrigations.form.method")}</label>
          <AsyncSelect  
            key={cultDataSelected.value}
            className='create-input' 
            id="method" 
            onChange={handleChange}
            name="method" 
            cacheOptions 
            defaultOptions 
            loadOptions={loadIrrigationMethodOptions}>
              <option disabled selected value>{t("actions.irrigations.form.placeholder.method")}</option>
          </AsyncSelect>
          {errors.method && <span className="error-message">{errors.method}</span>}
        </div>
      </span>
    );
  };

  const FertilizationForm = () => {
    return (
      <span>
        <div className="form-row">
          <label htmlFor="geom" className='label-create'>{t("actions.fertilizations.form.geometry")}</label>
          <input type="text" id="geom" name="geom" 
            placeholder="MULTIPOLYGON (((22.167664 39.302247, 22.223969 39.307562, 
              22.241821 39.222479, 22.101746 39.242695, 22.167664 39.302247)))"
            ref={geoFieldRef} 
            onChange={handleChange} 
            onKeyDown={handleChange}  
            className='create-input'
          />
          {errors.geom && <span className="error-message">{errors.geom}</span>}
        </div>
        <div className="form-row">
          <label htmlFor="date" className='label-create'>{t("actions.fertilizations.form.date")}</label>
          <input type="datetime-local" id="date" name="name" 
            onChange={handleChange} className='create-input'
          />
          {errors.date && <span className="error-message">{errors.date}</span>}
        </div>
        <div className="form-row">
          <label htmlFor="quantity" className='label-create'>{t("actions.fertilizations.form.quantity")}</label>
          <input type="number" id="quantity" name="quantity" 
            onChange={handleChange} className='create-input'
          />
          {errors.quantity && <span className="error-message">{errors.quantity}</span>}
        </div>
        <div className="form-row">
          <label htmlFor="unit" className='label-create'>{t("actions.fertilizations.form.unit")}</label>
          <AsyncSelect  
            key={cultDataSelected.value}
            className='create-input' 
            id="unit" 
            onChange={handleChange}
            name="unit" 
            cacheOptions 
            defaultOptions 
            loadOptions={loadFertilizationUnitOptions}>
              <option disabled selected value>{t("actions.fertilizations.form.placeholder.unit")}</option>
          </AsyncSelect>
          {errors.unit && <span className="error-message">{errors.unit}</span>}
        </div>
        <div className="form-row">
          <label htmlFor="category" className='label-create'>{t("actions.fertilizations.form.category")}</label>
          <AsyncSelect  
            key={cultDataSelected.value}
            className='create-input' 
            id="category" 
            onChange={handleChange}
            name="category" 
            cacheOptions 
            defaultOptions 
            loadOptions={loadFertilizationCategoryOptions}>
              <option disabled selected value>{t("actions.fertilizations.form.placeholder.category")}</option>
          </AsyncSelect>
          {errors.category && <span className="error-message">{errors.category}</span>}
        </div>
        <div className="form-row">
          <label htmlFor="method" className='label-create'>{t("actions.fertilizations.form.method")}</label>
          <AsyncSelect  
            key={cultDataSelected.value}
            className='create-input' 
            id="method" 
            onChange={handleChange}
            name="method" 
            cacheOptions 
            defaultOptions 
            loadOptions={loadFertilizationMethodOptions}>
              <option disabled selected value>{t("actions.fertilizations.form.placeholder.method")}</option>
          </AsyncSelect>
          {errors.method && <span className="error-message">{errors.method}</span>}
        </div>
        <div className="form-row">
          <label htmlFor="weather" className='label-create'>{t("actions.fertilizations.form.weather")}</label>
          <AsyncSelect  
            key={cultDataSelected.value}
            className='create-input' 
            id="weather" 
            onChange={handleChange}
            name="weather" 
            cacheOptions 
            defaultOptions 
            loadOptions={loadFertilizationWeatherOptions}>
              <option disabled selected value>{t("actions.fertilizations.form.placeholder.weather")}</option>
          </AsyncSelect>
          {errors.weather && <span className="error-message">{errors.weather}</span>}
        </div>
      </span>
    );
  };

  const PlantProtectionsForm = () => {
    return (
      <span>
        <div className="form-row">
          <label htmlFor="geom" className='label-create'>{t("actions.plant_protections.form.geometry")}</label>
          <input type="text" id="geom" name="geom" 
            placeholder="MULTIPOLYGON (((22.167664 39.302247, 22.223969 39.307562, 
              22.241821 39.222479, 22.101746 39.242695, 22.167664 39.302247)))"
            ref={geoFieldRef} 
            onChange={handleChange} 
            onKeyDown={handleChange}  
            className='create-input'
          />
          {errors.geom && <span className="error-message">{errors.geom}</span>}
        </div>
        <div className="form-row">
          <label htmlFor="date" className='label-create'>{t("actions.plant_protections.form.date")}</label>
          <input type="datetime-local" id="date" name="name" 
            onChange={handleChange} className='create-input'
          />
          {errors.date && <span className="error-message">{errors.date}</span>}
        </div>
        <div className="form-row">
          <label htmlFor="quantity" className='label-create'>{t("actions.plant_protections.form.quantity")}</label>
          <input type="number" id="quantity" name="quantity" 
            onChange={handleChange} className='create-input'
          />
          {errors.quantity && <span className="error-message">{errors.quantity}</span>}
        </div>
        <div className="form-row">
          <label htmlFor="unit" className='label-create'>{t("actions.plant_protections.form.unit")}</label>
          <AsyncSelect  
            key={cultDataSelected.value}
            className='create-input' 
            id="unit" 
            onChange={handleChange}
            name="unit" 
            cacheOptions 
            defaultOptions 
            loadOptions={loadPlantProtectionUnitOptions}>
              <option disabled selected value>{t("actions.plant_protections.form.placeholder.unit")}</option>
          </AsyncSelect>
          {errors.unit && <span className="error-message">{errors.unit}</span>}
        </div>
        <div className="form-row">
          <label htmlFor="weather" className='label-create'>{t("actions.plant_protections.form.weather")}</label>
          <AsyncSelect  
            key={cultDataSelected.value}
            className='create-input' 
            id="weather" 
            onChange={handleChange}
            name="weather" 
            cacheOptions 
            defaultOptions 
            loadOptions={loadPlantProtectionWeatherOptions}>
              <option disabled selected value>{t("actions.plant_protections.form.placeholder.weather")}</option>
          </AsyncSelect>
          {errors.weather && <span className="error-message">{errors.weather}</span>}
        </div>
        <div className="form-row">
          <label htmlFor="substance" className='label-create'>{t("actions.plant_protections.form.substance")}</label>
          <AsyncSelect  
            key={cultDataSelected.value}
            className='create-input' 
            id="substance" 
            onChange={handleChange}
            name="substance" 
            cacheOptions 
            defaultOptions 
            loadOptions={loadPlantProtectionSubstanceOptions}>
              <option disabled selected value>{t("actions.plant_protections.form.placeholder.substance")}</option>
          </AsyncSelect>
          {errors.substance && <span className="error-message">{errors.substance}</span>}
        </div>
        <div className="form-row">
          <label htmlFor="product" className='label-create'>{t("actions.plant_protections.form.product")}</label>
          <AsyncSelect  
            key={cultDataSelected.value}
            className='create-input' 
            id="product" 
            onChange={handleChange}
            name="product" 
            cacheOptions 
            defaultOptions 
            loadOptions={loadPlantProtectionProductOptions}>
              <option disabled selected value>{t("actions.plant_protections.form.placeholder.product")}</option>
          </AsyncSelect>
          {errors.product && <span className="error-message">{errors.product}</span>}
        </div>
        <div className="form-row">
          <label htmlFor="phi" className='label-create'>{t("actions.plant_protections.form.phi")}</label>
          <input type="text" id="phi" name="phi" 
            placeholder={t("actions.plant_protections.form.placeholder.phi")}
            onChange={handleChange} 
            onKeyDown={handleChange}  
            className='create-input'
          />
          {errors.phi && <span className="error-message">{errors.phi}</span>}
        </div>
        <div className="form-row">
          <label htmlFor="volume" className='label-create'>{t("actions.plant_protections.form.volume")}</label>
          <input type="number" id="volume" name="volume" 
            onChange={handleChange} className='create-input'
          />
          {errors.volume && <span className="error-message">{errors.volume}</span>}
        </div>
        <div className="form-row">
          <label htmlFor="harvestability" className='label-create'>{t("actions.plant_protections.form.harvestability")}</label>
          <input type="text" id="harvestability" name="harvestability" 
            placeholder={t("actions.plant_protections.form.placeholder.harvestability")}
            onChange={handleChange} 
            onKeyDown={handleChange}  
            className='create-input'
          />
          {errors.harvestability && <span className="error-message">{errors.harvestability}</span>}
        </div>
        <div className="form-row">
          <label htmlFor="comments" className='label-create'>{t("actions.plant_protections.form.comments")}</label>
          <input type="text" id="comments" name="comments" 
            placeholder={t("actions.plant_protections.form.placeholder.comments")}
            onChange={handleChange} 
            onKeyDown={handleChange}  
            className='create-input'
          />
          {errors.comments && <span className="error-message">{errors.comments}</span>}
        </div>
      </span>
    );
  };

  const VisitationForm = () => {
    return (
      <span>
        <div className="form-row">
          <label htmlFor="date" className='label-create'>{t("actions.visitations.form.date")}</label>
          <input type="datetime-local" id="date" name="name" 
            onChange={handleChange} className='create-input'
          />
          {errors.date && <span className="error-message">{errors.date}</span>}
        </div>
        <div className="form-row">
          <label htmlFor="reason" className='label-create'>{t("actions.visitations.form.reason")}</label>
          <input type="text" id="reason" name="reason" 
            placeholder={t("actions.visitations.form.placeholder.reason")}
            onChange={handleChange} 
            onKeyDown={handleChange}  
            className='create-input'
          />
          {errors.reason && <span className="error-message">{errors.reason}</span>}
        </div>
        <div className="form-row">
          <label htmlFor="comments" className='label-create'>{t("actions.visitations.form.comments")}</label>
          <input type="text" id="comments" name="comments" 
            placeholder={t("actions.visitations.form.placeholder.comments")}
            onChange={handleChange} 
            onKeyDown={handleChange}  
            className='create-input'
          />
          {errors.comments && <span className="error-message">{errors.comments}</span>}
        </div>
      </span>
    );
  };

  const GeneralActionForm = () => {
    return (
      <span>
        <div className="form-row">
          <label htmlFor="geom" className='label-create'>{t("actions.general_actions.form.geometry")}</label>
          <input type="text" id="geom" name="geom" 
            placeholder="MULTIPOLYGON (((22.167664 39.302247, 22.223969 39.307562, 
              22.241821 39.222479, 22.101746 39.242695, 22.167664 39.302247)))"
            ref={geoFieldRef} 
            onChange={handleChange} 
            onKeyDown={handleChange}  
            className='create-input'
          />
          {errors.geom && <span className="error-message">{errors.geom}</span>}
        </div>
        <div className="form-row">
          <label htmlFor="date" className='label-create'>{t("actions.general_actions.form.date")}</label>
          <input type="datetime-local" id="date" name="name" 
            onChange={handleChange} className='create-input'
          />
          {errors.date && <span className="error-message">{errors.date}</span>}
        </div>
        <div className="form-row">
          <label htmlFor="comments" className='label-create'>{t("actions.general_actions.form.comments")}</label>
          <input type="text" id="comments" name="comments" 
            placeholder={t("actions.general_actions.form.placeholder.comments")}
            onChange={handleChange} 
            onKeyDown={handleChange}  
            className='create-input'
          />
          {errors.comments && <span className="error-message">{errors.comments}</span>}
        </div>
      </span>
    );
  };

  const LeafAnalysisForm = () => {
    return (
      <span>
          {parameterOptions.map((option, index) => (
          <div className="form-row" style={{flexDirection:"row",borderBottom: "1px dotted black", marginRight: "15px"}}>
            <label htmlFor={option.value} className='label-create'>{parameterLabelOptions[index].value}</label>
            <input type="number" id={"param_"+option.value} name={option.value} validate_key="value_number" django-label={parameterLabelOptions[index].value}
              style={{width:"49%",marginTop:"auto",marginBottom:"10px"}}
              onChange={handleChange} className='create-input leaf-parameter-input'
            />
            {errors.name && <span className="error-message">{errors.name}</span>}
          </div>
        ))}
      </span>
    );
  };

  const handleActionTypeChange = (e) => {
    setCultDataSelected(e);
    setFormData({});
    if(e.value === "harvests"){
      setCultDataForm(HarvestForm);
    }else if(e.value === "irrigations"){
      setCultDataForm(IrrigationForm);
    }else if(e.value === "fertilizations"){
      setCultDataForm(FertilizationForm);
    }else if(e.value === "plant_protections"){
      setCultDataForm(PlantProtectionsForm);
    }else if(e.value === "visitations"){
      setCultDataForm(VisitationForm);
    }else if(e.value === "general_actions"){
      setCultDataForm(GeneralActionForm);
    }else if(e.value === "leaf-analysis"){
      setCultDataForm(LeafAnalysisForm);
    }else{
      setCultDataForm(null);
    }
  }

  return (
    <div className="content page1_content">

      <div className="create-field-map" >
          <MapCreateComponent 
            geoFieldRef={geoFieldRef} 
            isClearPressed={isClearPressed} 
            setisClearPressed={setisClearPressed} 
            isRedrawPressed={isRedrawPressed}
            selectedCultivation={selectedCultivation}/>
      </div>
      <div className="form-background">
        <form onSubmit={handleSubmit}>
          <div className="form-container">
            <div className="first-button-row">
            <>
                <img
                    onMouseEnter={() => handleMouseEnterPolygon()}
                    onMouseLeave={() => handleMouseLeavePolygon()}
                    src={isHoveredPolygon ? hoverPolygon : originalPolygon}
                    alt="CreatePolygon"
                    data-tip={t("common.map.create")}
                    data-place="top"
                    className={`leaflet-button-create1 ${isHoveredPolygon ? 'hovered' : ''}`}
                    onClick={() => handlePolygonClick()} 
                />
                <Tooltip effect="solid"  hideEvent="mouseout"/>
              </>
              <>        
                <img
                    onMouseEnter={() => handleMouseEnterEdit()}
                    onMouseLeave={() => handleMouseLeaveEdit()}
                    src={isHoveredEdit ? hoverEdit : originalEdit}
                    alt="Edit"
                    data-tip={t("common.map.edit")}
                    data-place="top"
                    className={`leaflet-button-create3 ${isHoveredEdit ? 'hovered' : ''}`}
                    onClick={() => handleEditClick()} 
                />
                <Tooltip effect="solid"  hideEvent="mouseout"/>
              </>
              {/*<>  
                <img
                    onMouseEnter={() => handleMouseEnterFileImport()}
                    onMouseLeave={() => handleMouseLeaveFileImport()}
                    src={isHoveredFileImport ? hoverFileImport : originalFileImport}
                    alt="FileImport"
                    data-tip={t("common.map.file")}
                    data-place="top"
                    className={`leaflet-button-create4 ${isHoveredFileImport ? 'hovered' : ''}`}
                    onClick={() => handleFileImportClick()} 
                />
                <Tooltip effect="solid"  hideEvent="mouseout"/>
              </>*/}
              <>
                <img
                    onMouseEnter={() => handleMouseEnterTrash()}
                    onMouseLeave={() => handleMouseLeaveTrash()}
                    src={isHoveredTrash ? hoverTrash : originalTrash}
                    alt="Trash"
                    data-tip={t("common.map.delete")} 
                    data-place="top"
                    className={`leaflet-button-create5 ${isHoveredTrash ? 'hovered' : ''}`}
                    onClick={() => handleTrashClick()} 
                />
                <Tooltip effect="solid"  hideEvent="mouseout"/>
            </>
            </div>
            <div className="second-button-row">
                <img
                  onMouseEnter={() => handleMouseEnterAccept()}
                  onMouseLeave={() => handleMouseLeaveAccept()}
                  src={isHoveredAccept ? hoverAccept : originalAccept}
                  alt={t("common.map.accept")}
                  className={`accept-decline ${isHoveredAccept ? 'hovered' : ''}`}
                  onClick={(event) => handleAcceptClick(event)} 
                />
                <img
                  onMouseEnter={() => handleMouseEnterDecline()}
                  onMouseLeave={() => handleMouseLeaveDecline()}
                  src={isHoveredDecline ? hoverDecline : originalDecline}
                  alt={t("common.map.cancel")}
                  className={`accept-decline ${isHoveredDecline ? 'hovered' : ''}`}
                  onClick={() => handleDeclineClick()} 
                />
                <Select 
                  styles={{
                    control: (baseStyles) => ({
                        ...baseStyles,
                        fontSize: '1.5vh',
                        width: '100%'
                    }),
                    container: (baseStyles) => ({
                      ...baseStyles,
                      width: '100%'
                    }),
                  }}
                  name="action_type" 
                  id="action_type_select" 
                  onChange={handleActionTypeChange}
                  value={cultDataSelected}
                  options={cultDataOptions}
                />
            </div>
            {cultDataForm}
          </div>
        </form>
      </div>
       {/* ToastContainer for displaying notifications */}
      <ToastContainer
        position="bottom-right"
        autoClose={7000} // Close the notification after 7 seconds
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
    
  );
};

export default CreateCultivationData;