import 'bootstrap/dist/css/bootstrap.min.css';
import $ from "jquery";
import 'leaflet/dist/leaflet.css';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import './CSSCustom/fieldsTable.css';
import { useTranslation } from 'react-i18next';

import getServerURL from "./Configuration.js";
import { usePageTitle } from './JS_Context/ContextTitle';
import { toast } from 'react-toastify';
import i18n from 'i18next';

const serverUrl = getServerURL();

function TableAgroCalendar() {
  const [actions, setActions] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [cookies] = useCookies(['csrftoken']);
  const { t } = useTranslation();
  const { setPageTitle, setPageTitleIcon } = usePageTitle();

  useEffect(() =>{
    setPageTitle("agro_calendar.title");
    setPageTitleIcon("/img/ICON_CALENDAR_GREEN.png");
  },[])

  useEffect(() => {
    // Fetch all actions
    $.ajax({
        url: serverUrl+"/actions/",
        type: 'get',
        headers: {
            "Accept-Language": i18n.language || 'el',
            "X-CSRFToken": cookies.csrftoken,
            "Authorization": cookies.auth
        },
        xhrFields: {
            withCredentials: true,
            xsrfCookieName: 'csrftoken',
            xsrfHeaderName: 'X-CSRFToken'
        },
        crossDomain: true,
        success: function (data) {
          for (let i = 0; i < data.length; i++) {
            if (data[i]["date"]) {
              const originalDate = new Date(data[i]["date"]);
              data[i]["date"] = originalDate.toLocaleString();
            }
          }
          setActions(data);
        },
        error: function (error) {
          toast.error(`${t("agro_calendar.error.calendar_list")} ${error.message}`);
          console.error(t("agro_calendar.error.calendar_list"), error);
        }
    });
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  const handleRowClick = (index) => {
    setSelectedRow(index);
  };

  return (
    <div className='content'>
      {/* <div className="page">  */}
        <div className="table-headers-container"> {/* New div for table headers */}
            <table className="table-headers">
              <thead>
                <tr>
                  <th>{t("agro_calendar.type")}</th>
                  <th>{t("agro_calendar.date")}</th>
                  <th>{t("agro_calendar.field")}</th>
                  <th>{t("agro_calendar.cultivation")}</th>
                </tr>
              </thead>
            </table>
        </div>
        <div className="table-container">
          <table className="table-fields">
            <tbody>
              {actions.map((action, index) => (
                <tr
                  key={action.id}
                  id={`row-${action.id}`}
                  className={    
                    (selectedRow === index) 
                    ||
                    (selectedRow !== index) 
                    ? 'selected-row' : ''
                  }
                  onClick={() => handleRowClick(index)}
                >
                  <td style={{textTransform:'capitalize'}}>
                    {action.type}
                  </td>
                  <td>
                    {action.date || 'N/A'}
                  </td>
                  <td>
                    {action.field?.name || 'N/A'}
                  </td>
                  <td>
                    {action.cultivation?.name || 'N/A'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      {/* </div> */}
    </div>
  );
}

export default TableAgroCalendar;
