import React, { useState, useEffect, useLayoutEffect} from 'react';
import MapCreateComponent from './MapCreateComponent';
import { useCookies } from 'react-cookie';
import $ from "jquery"; 

import 'leaflet/dist/leaflet.css';
import './CSSCustom/viewCultivations.css';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useTranslation } from "react-i18next";
import { usePageTitle } from './JS_Context/ContextTitle';
import getServerURL from "./Configuration.js";
import { loadSwaggerEnum } from './Utils.js';

const serverUrl = getServerURL();

function ViewLeafSuggestions() {
  const [leafSuggestionID, setLeafSuggestionID] = useState();
  const [cookies] = useCookies(['csrftoken']);
  const { t, i18n } = useTranslation();
  const { setPageTitle, setPageTitleIcon } = usePageTitle();
  const [selectedCultivation,setSelectedCultivation] = useState();
  const [suggestionParameters,setSuggestionParameters] = useState([]);
  const [parameterOptions, setParameterOptions] = useState([]);
  const [parameterLabelOptions, setParameterLabelOptions] = useState([]);

  // Initialize form
  const initForm = () => {
      $.ajax({
          url: serverUrl+"/suggestions/leaf_analysis/"+leafSuggestionID+"/",
          type: 'get',
          headers: {
              "Accept-Language": i18n.language || 'el',
              "X-CSRFToken": cookies.csrftoken,
              "Authorization": cookies.auth
          },
          xhrFields: {
              withCredentials: true,
              xsrfCookieName: 'csrftoken',
              xsrfHeaderName: 'X-CSRFToken'
          },
          crossDomain: true,
          success: function (data) {
            if(data){
              setSelectedCultivation(data.cultivation.id);
              //let parsedDate = new Date(data.created);
              /*let formattedDate = parsedDate.toLocaleDateString("en-US", {
                year: "2-digit",
                month: "numeric",
                day: "numeric",
              });
              let formattedHour = parsedDate.toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "numeric",
                hour12: false,
              });
              let formattedDateTime = `${formattedDate} ${formattedHour}`;*/
              //parsedDate = new Date(data.modified);
              /*formattedDate = parsedDate.toLocaleDateString("en-US", {
                year: "2-digit",
                month: "numeric",
                day: "numeric",
              });
              formattedHour = parsedDate.toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "numeric",
                hour12: false,
              });
              formattedDateTime = `${formattedDate} ${formattedHour}`;*/
              let temp_parameter_array = [];
              if(data.parameters && data.parameters.length > 0){
                data.parameters.slice(0,11).map((param) => {
                  let value = param.value_number;
                  if(!value || value == null){
                    value = param.value_text;
                  }else{
                    value = Math.round(100*value)/100
                  }
                  temp_parameter_array.push({
                    "id":param.id,
                    "label":param.label,
                    "value":value,
                    "classification":param.classification,
                    "evaluation":param.evaluation,
                    "rating":param.rating
                  });
                return null;
                });
                setSuggestionParameters(temp_parameter_array);
              }
            }
          },
          error: function (error) {
            console.error('Error fetching cultivation details:', error);
          }
      });
  };

  function loadParameterTypeOptions(){
    return loadSwaggerEnum({endpoint:'/leaf-analysis/',attribute:'parameters.items.properties.type',cookies});
  };

  function loadParameterLabelOptions(){
    return loadSwaggerEnum({endpoint:'/leaf-analysis/',attribute:'parameters.items.properties.label',cookies});
  };

  useLayoutEffect(() => {
    loadParameterTypeOptions().then((result) => {
      setParameterOptions(result);
    });
    loadParameterLabelOptions().then((result) => {
      setParameterLabelOptions(result);
    });
    const params = new URLSearchParams(window.location.search);
    setLeafSuggestionID(params.get('id'));
  }, [i18n.language]); // eslint-disable-line react-hooks/exhaustive-deps

  //Initialize page
  useEffect(() =>{
    if(leafSuggestionID && parameterOptions.length > 0 && parameterLabelOptions.length > 0){
      initForm();
    }
  },[leafSuggestionID,parameterOptions,parameterLabelOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() =>{
    setPageTitle("suggestion.leaf.view.title");
    setPageTitleIcon("/img/ICON_DATA_GREEN.png");
  },[])

  const rating_colors = ['','green','orange','red'];

  return (
    <div className="content page1_content">
      <div className="create-field-map" >
          <MapCreateComponent selectedCultivation={selectedCultivation}/>
      </div>
      <div className="form-background" style={{width: "35vw", padding: "20px 0px"}}>
        <div className="table-headers-container" style={{margin: "0px auto"}}>
            <table className="table-headers">
              <thead>
                <tr>
                  <th>{t('suggestion.form.parameter')}</th>
                  <th>{t('suggestion.form.value')}</th>
                  <th>{t('suggestion.form.status')}</th>
                  <th>{t('suggestion.form.evaluation')}</th>
                </tr>
              </thead>
            </table>
        </div>
        <div className="table-container" 
          style={{margin: "0px auto", heigh: "70vh", maxHeight: "70vh", overflowX:"hidden"}}>
          <table className="table-fields">
            <tbody>
              {parameterOptions.map((parameter, index) => (
                <tr
                  key={suggestionParameters[index]?.id}
                  id={`row-${suggestionParameters[index]?.id}`}
                  rating={suggestionParameters[index]?.rating}
                  style={{border: "1px dotted #000"}}
                >
                  <td style={{textTransform:'capitalize', border: "1px dotted #000", 
                    backgroundImage: "linear-gradient(90deg, "+rating_colors[suggestionParameters[index]?.rating]+" 0%, rgba(0,0,0,0) 7%, rgba(0,0,0,0) 100%)", 
                    backgroundRepeat: "no-repeat"}}>
                    {parameter.label}
                  </td>
                  <td style={{border: "1px dotted #000"}}>
                    {suggestionParameters[index]?.value|| 'N/A'}
                  </td>
                  <td style={{border: "1px dotted #000"}}>
                    {suggestionParameters[index]?.classification || 'N/A'}
                  </td>
                  <td style={{border: "1px dotted #000"}}>
                    {suggestionParameters[index]?.evaluation || 'N/A'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
       {/* ToastContainer for displaying notifications */}
      <ToastContainer
        position="bottom-right"
        autoClose={7000} // Close the notification after 7 seconds
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
    
  );
};

export default ViewLeafSuggestions;