import React, { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

import i18next from "i18next";
import { useTranslation } from "react-i18next";
import './CSSCustom/Header.css';
import arrowIcon from './img/ICON_ARROW_DOWN_PROFILE_GREEN.png';
import languageIcon from './img/ICON_LANGUAGE.png';
import userIcon from './img/ICON_USER.png';
import logoImage from './img/logogreen.png';
import { usePageTitle } from './JS_Context/ContextTitle';

const PageTitle = () => {
  // State to keep track of title, favicon, and whether favicon is valid
  const [favicon, setFavicon] = useState(
    document.getElementById('dynamic-favicon')?.href || ''
  );
  const [faviconValid, setFaviconValid] = useState(false);
  const { pageTitle, pageTitleIcon } = usePageTitle();
  const { t } = useTranslation();

  useEffect(() => {
    if(pageTitle){
      document.title = t(pageTitle);
    }
  }, [pageTitle]);

  useEffect(() => {
    if(pageTitleIcon){
      const img = new Image();
      img.src = pageTitleIcon;
      img.onload = () => setFaviconValid(true); // Set true if image loads
      img.onerror = () => setFaviconValid(false); // Set false if image fails to load
      setFavicon(pageTitleIcon);
    }
  }, [pageTitleIcon]);

  return (
    <div className="page-title">
      {faviconValid ? (
        <img src={favicon} alt="Tiny" className="tiny-image" />
      ) : null}
      <span>{t(pageTitle)}</span>
    </div>
  );
}

function Header({ setUsername }) {
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);
  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);
  const userDropdownRef = useRef(null);
  const languageDropdownRef = useRef(null);
  const [cookies] = useCookies(['username']);

  const username = cookies.username;
  const removeAuthCookie = useCookies(['auth'])[1];
  const removeUsernameCookie = useCookies(['username'])[1];
  const removeCsrfTokenCookie = useCookies(['csrftoken'])[1];
  const removeSidebarWeatherCookie = useCookies(['sidebarWeather'])[1];
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const lang = localStorage.getItem("i18nextLng");
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("i18nextLng") || "en"
  );

  useEffect(() => {
    if (localStorage.getItem("i18nextLng")?.length > 2) {
        i18next.changeLanguage("en");
    }
    if (selectedLanguage){
        i18next.changeLanguage(selectedLanguage);
    }
  }, [lang,selectedLanguage]);

  const handleLanguageChange = (language) => {
      i18n.changeLanguage(language);
      setSelectedLanguage(language);
      localStorage.setItem("i18nextLng", language);
  };
  
  const toggleUserDropdown = () => {
    setUserDropdownOpen(!userDropdownOpen);
  };

  const toggleLanguageDropdown = () => {
    setLanguageDropdownOpen(!languageDropdownOpen);
  };

  const handleLogout= () => {
    removeAuthCookie('auth');
    removeUsernameCookie('username');
    removeCsrfTokenCookie('csrftoken')
    removeSidebarWeatherCookie('sidebarWeather')
    document.cookie = "auth=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "sidebarWeather=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    setUsername("")
    navigate('/login');
  }

  useEffect(() => {
    const closeDropdownsOnOutsideClick = (e) => {
      if (userDropdownRef.current && !userDropdownRef.current.contains(e.target)) {
        setUserDropdownOpen(false);
      }
      if (languageDropdownRef.current && !languageDropdownRef.current.contains(e.target)) {
        setLanguageDropdownOpen(false);
      }
    };
    // Add an event listener to the document to detect clicks outside the dropdowns
    document.addEventListener('mousedown', closeDropdownsOnOutsideClick);
    return () => {
      // Cleanup: Remove the event listener when the component unmounts
      document.removeEventListener('mousedown', closeDropdownsOnOutsideClick);
    };
  }, []);

  return (
    <div className="header">
      {/* First Column: Logo */}
      <div className="column1">
        <div className='container-logo-header'>
          <img src={logoImage} alt="Logo" className='logoheader'/>
        </div>
      </div>

      {/* Second Column: Page Title */ }
      <div className="column2">
        <PageTitle/>
      </div>
      {/*<div className="column2">
        <div className="menu-button" onClick={toggleSidebarWithTransition}>
          <div className={`bar ${sidebarOpen ? 'open' : ''}`}></div>
          <div className={`bar ${sidebarOpen ? 'open' : ''}`}></div>
          <div className={`bar ${sidebarOpen ? 'open' : ''}`}></div>
        </div>
      </div>*/}

      {/* Third Column: User Profile and Language Button */}
      <div className="column3">
        <div className="user-profile">
          <img className="profile-image" src={userIcon} alt="User" />
          <span className="user-name">{username}</span>
          <div className="dropdown" ref={userDropdownRef}>
          <div className="arrow" onClick={(e) => {
              e.stopPropagation(); // Prevent the immediate closing of the dropdown
              toggleUserDropdown();
            }}>
              <img src={arrowIcon} alt="Arrow" />
            </div>
            {userDropdownOpen && (
              <div className="dropdown-content">
                  {/*<a href="#">Profile</a>
                  <a href="#">Settings</a>*/}
                  <a href="/" onClick={() =>handleLogout()}>{t("header.user.logout")}</a>
              </div>
            )}
          </div>
        </div>
        <div className="language-button" ref={languageDropdownRef}>
          <div className="language-image" onClick={(e) => {
            e.stopPropagation(); // Prevent the immediate closing of the dropdown
            toggleLanguageDropdown();
          }}>
            <img src={languageIcon} alt="Language" />
          </div>
          {languageDropdownOpen && (
            <div className="language-dropdown">
              <span onClick={() => handleLanguageChange("el")}>{t("header.languages.greek")}</span>
              <span onClick={() => handleLanguageChange("en")}>{t("header.languages.english")}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
